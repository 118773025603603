import React, { useEffect, useRef, useState } from "react";
import { formatarDecimal, formatarPorcentagem, calcularVariacao } from "../../core/utils/funcoes";
import { NumericFormat } from "react-number-format";
import { useToast } from "../../core/contexts/toast";
import Image from "react-bootstrap/Image";
import spinner from "../../assets/icons/spinner-black-sm.svg";
import { FaEdit, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import FatorDecisao from "../FatorDecisao";
import { ModeloEmpresa } from "../../core/models/empresa";
import { plantaIndustrialContext } from "../../core/contexts/plantaIndustrialContexto";
import { ModeloPlantaIndustrial, ModeloPlantaIndustrialGranularidade } from "../../core/models/plantaIndustrial";
import { FiltroPlantaIndustrial, RepositorioPlantaIndustrial } from "../../core/repositories/plantaIndustrialRepositorio";
import { ModeloPlantaIndustrialPlantas } from "../../core/models/plantaIndustrialPlantas";
import { useTranslation } from "react-i18next";

interface Props {
  linha: ModeloPlantaIndustrial;
  index: number;
  filtro: FiltroPlantaIndustrial;
  plantas: ModeloEmpresa[];
  salvouEdicao(): void;
}

// export default function TabelaTr(props: Props) {

export const TabelaTr: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { mostrarToast } = useToast();
  const [carregar, setCarregar] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [flgElasticidade, setFlgElasticidade] = useState<boolean>();
  const { carregandoFilho, adicionarLista, removerLista, atualizarInterface, atualizarUmaLinha, salvarLinhaEditada } = plantaIndustrialContext();

  useEffect(() => {

  })
  useEffect(() => {
    if (props.linha.flg_elasticidade != flgElasticidade) {
      setFlgElasticidade(props.linha.flg_elasticidade)
    }
  }, [props.linha.flg_elasticidade])

  async function carregarDados() {
    try {
      setCarregar(true);

      if (props.linha.flg_aberto) {
        removerLista(props.index);
      } else {
        await adicionarLista(props.filtro, props.index)
      }

      atualizarInterface()
      setCarregar(false);
    } catch (error) {
      mostrarToast({
        titulo: "Erro",
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });

      setCarregar(false);
    }
  }
  //fazer o calculo da elasticidade aqui
  async function calculoElasticidadeVolume() {
    try {
      const produto1 = { ...props.linha }
      const produto2 = { ...props.linha }
      produto1.editado_usuario = "preco"
      produto2.editado_usuario = "volume"

      if (props.linha.flg_elasticidade && props.linha.num_volume_mkt_novo) {
        props.linha.flg_carregando_preco = true;
        props.linha.num_preco_mkt_novo = undefined;
      }
      atualizarUmaLinha(props.linha, props.index)

      if (props.linha.flg_elasticidade == false && props.linha.num_nivel == 3) {
        produto2.num_preco_mkt_novo = produto2.num_preco
        await salvarLinhaEditada(produto1, -1, props.index, false)
        await salvarLinhaEditada(produto2, -1, props.index, true)
      } else {
        await salvarLinhaEditada(props.linha, -1, props.index, true)
      }

    } catch (error) {
      props.linha.flg_carregando_preco = false;
      props.linha.flg_carregando_volume = false;
      atualizarUmaLinha(props.linha, props.index)

      mostrarToast({
        titulo: "Erro",
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
    props.salvouEdicao()
    atualizarInterface();
  }

  function onMudarVolume(event: { floatValue: number | undefined; }) {
    props.linha.num_volume_mkt_novo = event.floatValue || 0;
    props.linha.editado_usuario = "volume";
    atualizarUmaLinha(props.linha, props.index)
    atualizarInterface();
    // Cancelar o timer anterior, se houver um
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      calculoElasticidadeVolume();
    }, 2000);
  }

  //fazer o calculo da elasticidade aqui
  async function calculoElasticidadePreco() {
    try {

      const produto1 = { ...props.linha }
      const produto2 = { ...props.linha }
      produto1.editado_usuario = "preco"
      produto2.editado_usuario = "volume"

      if (props.linha.flg_elasticidade && (props.linha.num_preco_mkt_novo != props.linha.num_preco_mkt_original)) {
        props.linha.flg_carregando_volume = true;
        props.linha.num_volume_mkt_novo = undefined;
      }
      atualizarUmaLinha(props.linha, props.index)

      if (props.linha.flg_elasticidade == false && props.linha.num_nivel == 3) {
        // produto2.num_volume_mkt_novo = produto2.num_volume
        await salvarLinhaEditada(produto2, -1, props.index, false)
        await salvarLinhaEditada(produto1, -1, props.index, true)
      } else {
        await salvarLinhaEditada(props.linha, -1, props.index, true)
      }

    } catch (error) {
      props.linha.flg_carregando_volume = false;
      props.linha.flg_carregando_preco = false;
      atualizarUmaLinha(props.linha, props.index)

      mostrarToast({
        titulo: "Erro",
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });

    }
    props.salvouEdicao()
    atualizarInterface();

  }

  function onMudarPreco(event: { floatValue: number | undefined; }) {
    props.linha.num_preco_mkt_novo = event.floatValue || 0;
    props.linha.editado_usuario = "preco";
    atualizarUmaLinha(props.linha, props.index)
    atualizarInterface();
    // Cancelar o timer anterior, se houver um
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      calculoElasticidadePreco();
    }, 2000);
  }


  function copiar() {
    var label = ""
    for (let index = 0; index <= props.linha.num_nivel; index++) {
      label += `${ModeloPlantaIndustrialGranularidade.INTERFACE[index]?.toString()}:(${(props.linha as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]]?.toString()}) - ${(props.linha as any)[ModeloPlantaIndustrialGranularidade.DESCRICAO[index]]?.toString()}\n`;
    }
    const inputTemporario = document.createElement("textarea");
    inputTemporario.value = label;
    document.body.appendChild(inputTemporario);
    inputTemporario.select();
    document.execCommand("copy");
    document.body.removeChild(inputTemporario);
  }

  function calculoImpostosFretes(planta?: ModeloPlantaIndustrialPlantas) {

    if (planta && planta.num_imposto_perc && props.linha.num_preco) {

      const num_preco_mkt = props.linha.num_preco

      const imposto_perc = planta.num_imposto_perc;
      const frete_quilo = planta.num_frete;
      const preco_planta = ((1 + imposto_perc) * num_preco_mkt) + frete_quilo
      return preco_planta
    }
    return 0
  }

  function carregandoValor(valor: number, money: boolean = false, atributo = "") {
    if (valor === undefined) {
      return <td className="text-center"> <Spinner size="sm" /> </td>
    }
    return <td>{formatarDecimal(valor, money)}
      {(props.linha.num_nivel == 5 && localStorage.getItem("editando") && atributo)
        && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}

    </td>
  }

  function desabilitarCarregamento() {
    const carregaPos = ["num_volume_ia", "num_preco_ia", "num_volume_mkt_novo", "num_preco_mkt_novo", "num_plano_producao_periodo_atual",  "num_saldo_estoque_inicial"]

    var plantasCarregando = props.plantas.filter(x => x.selecionada).map((item, index) => {
      return (props.linha.plantas == null || !props.linha.plantas.some(x => x.cod_empresa == item.cod_empresa) || props.linha.plantas.find(x => x.cod_empresa == item.cod_empresa)?.carregando)
    }).some(x => x == true)
    return carregandoFilho || carregaPos.map(x => (props.linha as any)[x] === undefined).some(x => x == true) || plantasCarregando
  }


  async function atualizarValor(atributo: string) {
    const valor = prompt("Por favor, insira um valor:");
    if (valor) {
      const _centro = new RepositorioPlantaIndustrial();
      _centro.atualizarValor(atributo, valor, props.linha).then((atualizadas) => {
        if (atualizadas) {
          (props.linha as any)[atributo] = valor
          atualizarUmaLinha(props.linha, props.index)
        }
        mostrarToast({
          titulo: "Sucesso",
          mensagem: `${atualizadas} linha(s) atualizada(s)`,
          tipo: "success",
        });
      }).catch(err => {
        mostrarToast({
          titulo: "Erro",
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
    }
  }

  function add_edit(atributo: string) {
    return (props.linha.num_nivel == 5 && localStorage.getItem("editando"))
      && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>
  }

  return (
    <>
      {/* Granularidade */}
      <td width={200} className={(props.linha.edit ? "is-edit " : "") + " bg-white text-start granularidade-nivel"} >
        <div className="drilldown " >
          <div
            className="text-end"
            style={{ minWidth: `${20 + ((props.linha.num_nivel == ModeloPlantaIndustrialGranularidade.DESCRICAO.length - 1 && props.linha.quantidade <= 1) ? (props.linha.num_nivel - 1) : (props.linha.num_nivel)) * 15}px` }}
          >
            {(props.linha.num_nivel < ModeloPlantaIndustrialGranularidade.DESCRICAO.length - 2 || (props.linha.num_nivel == ModeloPlantaIndustrialGranularidade.DESCRICAO.length - 2 && props.linha.quantidade > 0)) && (
              <Button variant="light" size="sm" onClick={carregarDados} disabled={desabilitarCarregamento()}>
                {carregar ?
                  <Image src={spinner} className="baixar-icon" /> :
                  props.linha.flg_aberto ?
                    <FaMinusCircle /> :
                    <FaPlusCircle />
                }
              </Button>
            )}
          </div>

          <span className="ms-1 w-100" onDoubleClick={copiar}>
            {(props.linha as any)[ModeloPlantaIndustrialGranularidade.DESCRICAO[props.linha.num_nivel]]?.toString()}
            {/* -{ props.linha.cod_familia_subgrupo_produto_capitao} */}
          </span>

          {/* {(!(props.linha.num_nivel == ModeloPlantaIndustrialGranularidade.DESCRICAO.length - 1 && props.linha.quantidade <= 1) && props.linha.num_volume_excessivo > 0) &&
            <OverlayTrigger
              placement="top"
              overlay={(props) => (
                <Tooltip  {...props}>
                  Há produtos com volume excessivo!
                </Tooltip>
              )}
            >
              <span className="position-absolute width-12 height-12 bg-primary-500 rounded-circle top-0 right-0" />
            </OverlayTrigger>
          } */}
        </div >
      </td >

      {/* Plano de Produção */}
      {carregandoValor(props.linha.num_plano_producao_periodo_atual, false, "num_plano_producao_periodo_atual")}
      {/* Plano de Produção M+1 */}
      {carregandoValor(props.linha.num_plano_producao_proximo_periodo, false, "num_plano_producao_proximo_periodo")}
      {/* Var */}
      <td>{formatarPorcentagem(calcularVariacao(props.linha.num_plano_producao_periodo_atual, props.linha.num_plano_producao_proximo_periodo))}</td >
      {/* Saldo Estoque Inicial */}
      {carregandoValor(props.linha.num_saldo_estoque_inicial, false, "num_saldo_estoque_inicial")}
      {/* Volume Vendido Mês Atual */}
      {carregandoValor(props.linha.num_volume_venda, false, "num_volume_venda")}
      {/* Preço Mês Atual */}
      {carregandoValor(props.linha.num_preco_venda, true, "num_preco_venda")}
      {/* Volume Carteira */}
      {carregandoValor(props.linha.num_volume_carteira, false, "num_volume_carteira")}
      {/* Preço Carteira */}
      {carregandoValor(props.linha.num_preco_carteira, true, "num_preco_carteira")}
      {/* Demanda Sugestão Demanda IA */}
      {carregandoValor(props.linha.num_volume_ia, false, "num_volume_ia")}
      {/* Preço Sugerido IA */}
      {carregandoValor(props.linha.num_preco_ia, true, "num_preco_ia")}
      {/* Fatores de Decisão */}

      <td className="width-55"><FatorDecisao minValue={-10} maxValue={10} valor={props.linha.num_fator_decisao_sazonalidade} />{add_edit("num_fator_decisao_sazonalidade")}</td>
      <td className="width-55"><FatorDecisao minValue={-10} maxValue={10} valor={props.linha.num_fator_decisao_tendencia} />   {add_edit("num_fator_decisao_tendencia")}</td>
      <td className="width-55"><FatorDecisao minValue={0} maxValue={500} valor={props.linha.num_fator_decisao_elasticidade} />   {add_edit("num_fator_decisao_elasticidade")}</td>
      <td className="width-55"><FatorDecisao minValue={-5} maxValue={5} valor={props.linha.num_fator_decisao_giro} />   {add_edit("num_fator_decisao_giro")}</td>


      {/* Ativar Elasticidade */}
      <td className="text-center">
        {props.linha.num_nivel == ModeloPlantaIndustrialGranularidade.DESCRICAO.length - 3 && (
          flgElasticidade === undefined ?
            <Spinner size="sm" /> :
            <Form.Check
              checked={!!flgElasticidade}
              onChange={(event) => {
                setFlgElasticidade(event.currentTarget.checked)
                props.linha.flg_elasticidade = event.currentTarget.checked;
                atualizarUmaLinha(props.linha, props.index)
              }}
            />
        )}
      </td>
      {/* Volume Sugerido Mkt */}
      {props.linha.flg_carregando_volume || props.linha.num_volume_mkt_novo === undefined ?
        <td className="text-center">
          <Spinner size="sm" /> </td> :
        <td>

          {props.linha.num_nivel > 2 ? (
            <div className="d-flex justify-content-between align-items-center">
              <div className="position-relative">
                <NumericFormat
                  type="tel"
                  className="form-control-sm"
                  tabIndex={props.index * 2 + 1}
                  decimalSeparator={t("components.,")}
                  thousandSeparator={t("components..")}
                  disabled={props.linha.flg_carregando_preco}
                  decimalScale={2}
                  placeholder={formatarDecimal(props.linha.num_volume_ia)}
                  id={"numberFormat-1" + props.linha.num_nivel}
                  value={props.linha.num_volume_ia != props.linha.num_volume_mkt_novo ? props.linha.num_volume_mkt_novo : undefined}
                  onValueChange={onMudarVolume}
                />
                {props.linha.num_volume_mkt_novo != props.linha.num_volume_mkt_original &&
                  <OverlayTrigger
                    overlay={(props) => (
                      <Tooltip  {...props}>
                        {t("pages.O conteúdo foi editado, mas ainda não foi salvo!")}
                      </Tooltip>
                    )}
                  >
                    <span className="position-absolute width-12 height-12 bg-warning  rounded-circle top-0 right-0" />
                  </OverlayTrigger>
                }
              </div>
            </div>
          ) : (
            formatarDecimal(props.linha.num_volume_mkt_novo)
          )}
        </td>
      }
      {/* Var.IA Vs Mkt */}
      <td>{formatarPorcentagem(calcularVariacao(props.linha.num_volume_ia, props.linha.num_volume_mkt_novo))}</td>
      {/* Preço Sugerido Mkt */}
      {props.linha.flg_carregando_preco || props.linha.num_preco_mkt_novo === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td> : (
          <td>
            {props.linha.num_nivel > 2 ? (

              <div className="d-flex justify-content-between align-items-center">
                <div className="position-relative">
                  <NumericFormat
                    type="tel"
                    className="form-control-sm"
                    tabIndex={props.index * 2 + 2}
                    decimalSeparator={t("components.,")}
                    thousandSeparator={t("components..")}
                    disabled={props.linha.flg_carregando_volume}
                    decimalScale={2}
                    prefix={t("components.R$")}
                    placeholder={formatarDecimal(props.linha.num_preco_ia, true)}
                    id={"numberFormat" + props.linha.num_nivel}
                    value={props.linha.num_preco_ia != props.linha.num_preco_mkt_novo ? props.linha.num_preco_mkt_novo : undefined}
                    onValueChange={onMudarPreco}
                  />
                  {props.linha.num_preco_mkt_novo != props.linha.num_preco_mkt_original &&
                    <OverlayTrigger
                      overlay={(props) => (
                        <Tooltip  {...props}>
                          {t("pages.O conteúdo foi editado, mas ainda não foi salvo!")}
                        </Tooltip>
                      )}
                    >
                      <span className="position-absolute width-12 height-12 bg-warning  rounded-circle top-0 right-0" />
                    </OverlayTrigger>
                  }
                </div>
              </div>

            ) : (
              formatarDecimal(props.linha.num_preco_mkt_novo || props.linha.num_preco_ia, true)
            )}
          </td>
        )
      }

      {/* Var.IA Vs Mkt */}
      <td>{formatarPorcentagem(calcularVariacao(props.linha.num_preco_ia, props.linha.num_preco_mkt_novo))}</td>
      {/* Plantas */}
      {
        props.plantas.filter(x => x.selecionada).map((item, index) => {
          return <td width={60} key={index} >
            {props.linha.plantas == null || !props.linha.plantas.some(x => x.cod_empresa == item.cod_empresa) || props.linha.plantas.find(x => x.cod_empresa == item.cod_empresa)?.carregando ?
              <Spinner size="sm" /> :
              formatarDecimal(calculoImpostosFretes(props.linha.plantas.find(x => x.cod_empresa == item.cod_empresa)), true)

            }
          </td>
        })
      }
    </>
  );
};
