import React from "react";
import Plot from "react-plotly.js";
import { PropsGraphicKpis } from "./1_precoBoiGordoDolar";
import { agruparPorAnoECalcularMedia } from "..";
import { ModeloKpis } from "../../../core/models/kpis";
import { useTranslation } from "react-i18next";

export default function CorrelacaoCortePrecoOvo(props: PropsGraphicKpis) {
  const [listaOvo, setListaOvo] = React.useState<ModeloKpis[]>([]);
  const [listaFamilia, setListaFamilia] = React.useState<ModeloKpis[]>([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (props.familia) {
      var lista = props.listaKpis.filter((kpi) => kpi.des_indicador === "PRECO_OVO" || kpi.des_indicador === props.familia?.des_familia_subgrupo);
      lista = lista.sort((a, b) => a.dat_referencia.localeCompare(b.dat_referencia));
      // lista.map(x => x.dat_referencia = moment(x.dat_referencia, 'YYYY-MM').format('MM-YYYY'));
      var listaOvo = lista.filter((kpi) => kpi.des_indicador === "PRECO_OVO" && kpi.flg_volume == false);
      var listaFamilia = lista.filter((kpi) => kpi.des_indicador === props.familia?.des_familia_subgrupo && kpi.flg_volume == false);

      if (props.lg) {
        setListaOvo(listaOvo);
        setListaFamilia(listaFamilia);
      } else {
        setListaOvo(agruparPorAnoECalcularMedia(listaOvo));
        setListaFamilia(agruparPorAnoECalcularMedia(listaFamilia));
      }
    }
  }, [props.listaKpis, props.familia]);

  const data: Partial<Plotly.Data>[] = [
    {
      x: listaFamilia.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaFamilia.map((x) => x.num_indicador),
      type: "scatter",
      mode: "lines+markers",
      name: t("pages.Preço") + ": " + props.familia?.des_familia_subgrupo,
      line: { shape: "spline" },
      marker: { color: "#D32F2F" },
    },
    {
      x: listaOvo.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaOvo.map((x) => x.num_indicador),
      type: "scatter",
      mode: "lines+markers",
      name: t("pages.Preço") + ": " + t("pages.Ovo"),
      line: { shape: "spline" },
      marker: { color: "#FFC0CB " },
      yaxis: "y2", //
    },
  ];

  const layout: Partial<Plotly.Layout> = {
    title: {
      text: t("pages.Correlação Preço do Corte vs Ovo"),
      font: {
        size: 12,
      },
    },
    paper_bgcolor: "rgba(0,0,0,0)", // Transparente
    plot_bgcolor: "rgba(0,0,0,0)", // Transparente,
    xaxis: {
      title: props.lg ? t("pages.Data") : "", // Oculta ou exibe o título do eixo X dependendo de lg
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },

    yaxis: {
      title: props.lg ? t("pages.Preço") + `: ${props.familia?.des_familia_subgrupo} ` + t("pages.(R$)") : "",
      tickformat: ".2f",
      tickprefix: " " + t("components.R$"),
      tickfont: { size: props.lg ? 12 : 9 },
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },

    yaxis2: {
      title: props.lg ? t("pages.Ovo") + " " + t("pages.(R$)") : "",
      tickformat: ".2f",
      tickprefix: " " + t("components.R$"),
      tickfont: { size: props.lg ? 12 : 9 },
      overlaying: "y",
      side: "right",
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },

    margin: {
      t: props.lg ? 40 : 30,
      b: props.lg ? 40 : 20,
      l: props.lg ? 90 : 50,
      r: props.lg ? 90 : 40,
    },
    showlegend: props.lg,
    legend: {
      x: 0.5,
      xanchor: "center", // Centraliza horizontalmente
      y: -0.2,
      orientation: "h", // Legenda horizontal
      font: {
        size: 14,
      },
    },
    autosize: true,
  };

  return (
    <div className="text-center div-chart">
      <Plot data={data} layout={layout} useResizeHandler style={{ width: "100%", height: "100%" }} config={{ displayModeBar: props.lg }} />
      {/* {props.lg &&
      <label>
        Fonte: <a href="https://www.cepea.esalq.usp.br/br/indicador/boi-gordo.aspx" target="_blank" rel="noreferrer">CEPEA</a>
      </label>
    } */}
    </div>
  );
}
