
import { createContext, useState, useContext } from 'react';
import { ModeloCenario } from '../models/cenario';
import { ModeloOtimizacaoReceita, ModeloOtimizacaoReceitaGranularidade } from '../models/otimizacaoReceita';
import { FiltroOtimizacaoReceita, RepositorioOtimizacaoReceita } from '../repositories/otimizacaoReceitaRepositorio';
import React from 'react';
import { RepositorioOtimizacaoKpiReceita } from '../repositories/otimizacaoReceitaKpiRepositorio';
import { ModeloOtimizacaoReceitaKpi } from '../models/otimizacaoReceitaKpi';
import { useToast } from './toast';
import { useAutenticação } from './auth';

// O que o contexto irá passar para o outros componentes
interface OtimizacaoReceitaContextoInterface {
	lista: ModeloOtimizacaoReceita[] | [];
	carregando: boolean;
	carregandoFilho: boolean;
	filtroNoContexto: FiltroOtimizacaoReceita;
	indexsKPI: ModeloOtimizacaoReceitaKpi[];
	carregarLista(filtro: FiltroOtimizacaoReceita): Promise<ModeloOtimizacaoReceita[]>;
	adicionarLista(filtro: FiltroOtimizacaoReceita, index: number): Promise<number>;
	removerLista(index: number): void;
	limparLista(): void;
	atualizarInterface(): void;
	atualizarFiltroContexto(filtro: FiltroOtimizacaoReceita): void;
	setCarregando(carregando: boolean): void;
}

// Como o objeto de contexto vai iniciar
const OtimizacaoReceitaContexto = createContext<OtimizacaoReceitaContextoInterface>({} as OtimizacaoReceitaContextoInterface);

type Props = {
	children?: JSX.Element;
};

// Componente de contexto que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const OtimizacaoReceitaProvider: React.FC<Props> = ({ children }) => {
	const [carregando, setCarregando] = useState(false);
	const [carregandoFilho, setCarregandoFilho] = useState(false);
	const { mostrarToast } = useToast();
	var [lista, setLista] = useState<ModeloOtimizacaoReceita[]>([]);
	const [filtroNoContexto, setFiltroContexto] = useState(new FiltroOtimizacaoReceita());
	const [contador, setContador] = useState(0);
	const [indexsKPI, setIndexsKPI] = useState<ModeloOtimizacaoReceitaKpi[]>([])

	const { usuario } = useAutenticação();

	const _otimizacaoReceita = new RepositorioOtimizacaoReceita();


	React.useEffect(() => {
		// console.log("selecionados", selecionados)




	}, [])

	React.useEffect(() => {
		// console.log("selecionados", selecionados)
		setIndexsKPI([])
		new RepositorioOtimizacaoKpiReceita().getListaKpi(filtroNoContexto.flg_ano_mes).then((data) => {
			console.log(data)
			setIndexsKPI(data)
		}).catch((err) => {
			// mostrarToast({
			// 	mensagem: TipoMensagem.ERRO_TENTE_NOVAMENTE,
			// 	tipo: "error",
			// });
		})



	}, [filtroNoContexto.des_periodo_analisado])
	async function carregarLista(filtro: FiltroOtimizacaoReceita) {
		const _contador = contador + 1;
		setContador(_contador)
		setCarregando(true);
		const otimizacaoReceitaRetorno = await _otimizacaoReceita.getFilter(filtro, "_post")

		if (contador === _contador - 1) {
			//para abrir quando carrega trocar aberto para true
			if (filtro.num_nivel == 0) {
				otimizacaoReceitaRetorno.map(x => { x.flg_aberto = false; x.num_nivel = 0; return x; });
			}

			const lista = [...otimizacaoReceitaRetorno]
			if (lista.length) {
				filtro.num_total = lista[0].num_total
			} else {
				filtro.num_total = 0
			}
			setFiltroContexto(filtro)
			atualizaLista(lista);
			setCarregando(false);
			await carregarDados(filtro)
			return [...otimizacaoReceitaRetorno]
		} else {
			return [...lista]
		}
	}

	function carregarDados(filtro: FiltroOtimizacaoReceita) {
		//se nao tiver nenhuma selecionada, não carrega
		if (lista.length) {
			// carregarModelo(filtro)
			return Promise.all([
				// carregarModeloSugeridoFriboi(filtro),
				// carregarEstoque(filtro),
				// carregarPlanoProducao(filtro),
				// carregarPlanoProducaoProximoPeriodo(filtro)
			]);
		}
	}


	function removerLista(index: number) {
		lista[index].flg_aberto = false;
		const nivel = lista[index].num_nivel;
		var indexFinal = index + 1

		while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
			const element = lista[indexFinal];
			element.oculta = true
			element.flg_aberto = false
			indexFinal++
		}

		atualizaLista(lista)
	}

	async function adicionarLista(filtroGeral: FiltroOtimizacaoReceita, index: number) {
		const linha = lista[index];
		setCarregandoFilho(true)
		linha.flg_aberto = true;
		try {
			if (linha.filhos > 0) {
				const nivel = lista[index].num_nivel;
				var indexFinal = index + 1

				while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
					const element = lista[indexFinal];
					// para mostrar somente os que estão um nível abaixo
					element.oculta = (nivel + 1) != lista[indexFinal].num_nivel
					indexFinal++
				}
				atualizaLista(lista)

			} else {
				const filtro = { ...filtroGeral }
				filtro.num_nivel = linha.num_nivel + 1
				for (let index = 0; index < filtro.num_nivel; index++) {
					(filtro as any)[ModeloOtimizacaoReceitaGranularidade.CODIGO[index]] = (linha as any)[ModeloOtimizacaoReceitaGranularidade.CODIGO[index]]
				}
				const response = await _otimizacaoReceita.getFilter(filtro, "_post")
				response.map((item) => {
					item.num_nivel = linha.num_nivel + 1;
				});
				linha.filhos = response.length;
				lista.splice(index + 1, 0, ...response);
				await carregarDados(filtro)
				atualizaLista(lista)
			}
			setCarregandoFilho(false)
			return linha.filhos;
		} catch (error) {
			setCarregandoFilho(false)
			throw error
		}
	}


	function limparLista() {
		atualizaLista([])
	}

	function atualizarInterface() {
		setLista([...lista])
	}

	function atualizaLista(nova: ModeloOtimizacaoReceita[]) {
		setLista(nova)
		lista = nova;
	}



	function atualizarFiltroContexto(filtro: FiltroOtimizacaoReceita) {
		setFiltroContexto({ ...filtro })
	}

	return (
		// eslint-disable-next-line
		<OtimizacaoReceitaContexto.Provider
			value={{
				lista,
				carregando,
				carregandoFilho,
				filtroNoContexto,
				indexsKPI,
				carregarLista,
				adicionarLista,
				removerLista,
				limparLista,
				atualizarInterface,
				atualizarFiltroContexto,
				setCarregando
			}}
		>
			{children}
		</OtimizacaoReceitaContexto.Provider>
	);
};

export function otimizacaoReceitaContext() {
	const contexto = useContext(OtimizacaoReceitaContexto);

	return contexto;
}
