// @flow
import * as React from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { useState, useEffect } from "react";
import { CatalogoPrecoBaseEditRepositorio } from "../../core/repositories/CatalagoPrecoBaseEdicaoRepositorio";
import { t } from "i18next";
import { FilterCatalogoPrecoBase, CatalogoPrecoBaseRepositorio } from "../../core/repositories/CatalagoPrecoBaseRepositorio";
import { BaseModel } from "../../core/models/base-model";
import { BranchModel } from "../../core/models/branch";
import { useAutenticação } from "../../core/contexts/auth";
import { ProductModel } from "../../core/models/product";
import { ProductCategoryModel } from "../../core/models/product_category";
import { ProductDepartmentModel } from "../../core/models/product_department";
import { ProductSubcategoryModel } from "../../core/models/product_subcategory";
import { BranchRepository } from "../../core/repositories/branch.repository";
import { ProductDepartmentRepository } from "../../core/repositories/product_department.repository";
import { MesorregionRepositorio } from "../../core/repositories/mesorregion.repository";
import { useTranslation } from "react-i18next";
import { ProductCategoryRepository } from "../../core/repositories/product_category.repository";
import { ProductSubcategoryRepositorio } from "../../core/repositories/product_subcategory.repository";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import SelecionarBusca from "../../components/SelecionarBusca";
import { ProductRepository } from "../../core/repositories/product.repository";
import SelectSearch from "../../components/SelectSearch";
import { TipoTela } from "../../core/config/tipoTela";

interface Props {
  filtro: FilterCatalogoPrecoBase;
  atualizarFiltro: (filtro: FilterCatalogoPrecoBase) => void;
  filtrarDados: (filtro: FilterCatalogoPrecoBase) => void;
}

export const Filter: React.FC<Props> = (props) => {
  const { mostrarToast } = useToast();
  const { t } = useTranslation();

  const [branchList, setBranchList] = useState<BranchModel[]>([]);
  const [departmentList, setDepartmentList] = useState<ProductDepartmentModel[]>([]);
  const [categoryList, setCategoryList] = useState<ProductCategoryModel[]>([]);
  const [subcategoryList, setSubcategoryList] = useState<ProductSubcategoryModel[]>([]);
  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [yearWeekList, setYearWeekList] = useState<BaseModel[]>([]);
  const [mesorregionList, setMesorregionList] = useState<BaseModel[]>([]);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [carregarBtnExportar, setCarregarBtnExportar] = useState(false);

  const { loading } = useAutenticação();

  useEffect(() => {
    console.log("useEffect Filter");
    if (!loading) {
      if (!hasFetchedData) {
        try {
          new BranchRepository().getFilter(props.filtro, "_post").then((res) => {
            setBranchList(res);
          });
          new ProductDepartmentRepository().getFilter(props.filtro, "_post").then((res) => {
            setDepartmentList(res);
          });
          getCategoryList(props.filtro);
          getSubcategoryList(props.filtro);
          new MesorregionRepositorio().getAll().then((res) => {
            setMesorregionList(res);
          });
          getProductList(props.filtro);
          new CatalogoPrecoBaseRepositorio().getYearWeekList().then((res) => {
            setYearWeekList(res);
            if (res.length > 0 && !props.filtro.year_week) {
              props.filtro.year_week = res[0].id.toString();
              props.filtrarDados(props.filtro);
            }
          });
        } catch (error) {
          mostrarToast({
            titulo: t("pages.Erro!"),
            mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
            tipo: "error",
          });
        }
      }
      setHasFetchedData(true);
    }
  }, [loading]);

  function getCategoryList(filter: FilterCatalogoPrecoBase) {
    new ProductCategoryRepository()
      .getFilter(filter, "_post")
      .then((res) => {
        setCategoryList(res);
      })
      .catch((error) => {
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }

  function getSubcategoryList(filter: FilterCatalogoPrecoBase) {
    new ProductSubcategoryRepositorio()
      .getFilter(filter, "_post")
      .then((res) => {
        setSubcategoryList(res);
      })
      .catch((error) => {
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }

  function getProductList(filter: FilterCatalogoPrecoBase) {
    new ProductRepository()
      .getFilter(filter, "_post")
      .then((res) => {
        setProductList(res);
      })
      .catch(() => {
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }
  function apagarTudo() {
    new CatalogoPrecoBaseEditRepositorio()
      .deleteAll()
      .then((req) => {

        mostrarToast({
          titulo: t("pages.Sucesso!"),
          mensagem: "Dados apagados com sucesso!",
          tipo: "success",
        });

      })
      .catch(() => {
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }
  function exportarDados() {
    setCarregarBtnExportar(true);
    new CatalogoPrecoBaseRepositorio()
      .baixar(props.filtro)
      .then((req) => {
        var blob = new Blob([req.data], {
          type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `preco_base_${new Date().toISOString()}.xlsx`;
        link.click();

        setCarregarBtnExportar(false);
      })
      .catch(() => {
        setCarregarBtnExportar(false);
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }
  return (
    <div className="stick">
      <FiltroCabecalho
        titulo="Catálogo de Preço Base"
        filtro={props.filtro}
        atualizarFiltro={props.atualizarFiltro} tipoTela={TipoTela.MERCADO_EXTERNO}
      // clearFilter={() => {
      //   var filtro = new FilterCatalogoPrecoBase();
      //   if (yearWeekList.length > 0) {
      //     filtro.year_week = yearWeekList[0].id.toString();
      //   }
      //   getCategoryList(filtro);
      //   getSubcategoryList(filtro);
      //   getProductList(filtro);
      //   props.atualizarFiltro(filtro);
      // }}
      // reload={() => {
      //   props.filtrarDados(props.filtro);
      // }}
      >
            <>
          <Row>
            <Col>
              <SelectSearch
                name="Período Analisado"
                selectId="id"
                selectName="description"
                multiple={false}
                required={true}
                value={props.filtro.year_week}
                options={yearWeekList}
                onChange={(value) => {
                  props.filtro.year_week = value.toString();
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Filial"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.branch_code}
                options={branchList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.branch_code = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Departamento"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.department_code}
                options={departmentList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.department_code = value;
                  // props.filtro.category_code = "";
                  props.filtro.product_code = "";
                  props.atualizarFiltro(props.filtro);
                  // getCategoryList(props.filtro);
                  getProductList(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Categoria"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.category_code}
                options={categoryList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.category_code = value;
                  props.filtro.subcategory_code = "";
                  props.filtro.product_code = "";
                  props.atualizarFiltro(props.filtro);
                  getSubcategoryList(props.filtro);
                  getProductList(props.filtro);
                }}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <SelectSearch
                name="Subcategoria"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.subcategory_code}
                options={subcategoryList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.subcategory_code = value;
                  props.filtro.product_code = "";
                  props.atualizarFiltro(props.filtro);
                  getProductList(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Mesorregião"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.mesorregion_code}
                options={mesorregionList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.mesorregion_code = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Produto"
                selectId="id"
                selectName="description_show"
                multiple={true}
                value={props.filtro.product_code}
                options={productList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.product_code = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col></Col>

            <Col>
              <div className="d-flex justify-content-end margin-top-32">
                {process.env.REACT_APP_BASE_BACK && (
                  <div>
                    <Button className="ms-1 text-white" variant="danger" onClick={apagarTudo}>
                      {carregarBtnExportar && <Spinner size="sm" />}
                      Apagar Dados
                    </Button>
                  </div>
                )}
                <div>
                  <Button className="ms-1 text-white" variant="secondary" onClick={exportarDados} disabled={carregarBtnExportar || !props.filtro.year_week}>
                    {carregarBtnExportar && <Spinner size="sm" />}
                    Relatório
                  </Button>
                </div>
                <Button
                  className="ms-1"
                  variant="primary"
                  disabled={!props.filtro.year_week}
                  onClick={() => {
                    props.filtrarDados(props.filtro);
                  }}
                >
                  Buscar
                </Button>
              </div>
            </Col>
          </Row>
        </>
      </FiltroCabecalho>
    </div>
  );
};
