import * as React from "react";
import { useEffect } from "react";
// import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import "./index.scss";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiInfoCircle, BiXCircle } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";

interface Props {
  ordem?: any;
  ordenarLinha?: (value: string) => void;
  checarLinha?: (value: string) => void;
  fecharPlantas?: () => void;
  className?: string;
  classNameTh?: string;
  width?: any;
  children?: any;
  colSpan?: number;
  rowSpan?: number;
  tooltip?: string;
  info?: string;
}

const TableRow: React.FC<Props> = (props) => {
  const [ordem, setOrdem] = React.useState<string>();

  useEffect(() => {
    setOrdem(props.ordem ? props.ordem : undefined);
  }, [props.ordem]);

  function ordenarLinha() {
    let aux = null;

    if (props.ordenarLinha) {
      if (ordem === "desc") {
        aux = undefined;
      } else {
        aux = ordem === undefined ? "asc" : "desc";
      }

      setOrdem(aux);
      props.ordenarLinha(aux ? aux : "");
    }
  }

  const renderTooltip = (propsT: any) => (
    props.tooltip ?
    <Tooltip id="button-tooltip" {...propsT}>
      {props.tooltip}
    </Tooltip>:
    <></>
  );

  const renderInfo = (propsT: any) => (
    props.info ?
    <Tooltip id="button-tooltip" {...propsT}>
      {props.info}
    </Tooltip>:
    <></>
  );

  return (    
    <th
      rowSpan={props.rowSpan}
      colSpan={props.colSpan}
      style={{width: props.width}}
      onClick={ordenarLinha}
      
      className={
        (props.ordenarLinha !== undefined && "pointer") +
        " table-row " +
        (props.classNameTh || "")       
      }
    >
      <div className={(props?.className || "") + " vertical position-relative " +  (props.checarLinha ? " pe-2":"")}>
        <OverlayTrigger
          placement="top"
          overlay={renderTooltip}
        >
          <div className="w-100">{props.children}</div>
        </OverlayTrigger>

        {
          props.info &&
          <OverlayTrigger
            placement="top"
            overlay={renderInfo}
          >
            {/* <Button className="position-absolute top-minus-1 right-1 p-0" size="sm" variant="primary-outline">
              <FaInfoCircle />
            </Button> */}
            <></>
          </OverlayTrigger>
        }

        {
          props.fecharPlantas &&
          <Button className="position-absolute top-minus-1 right-1 p-0" size="sm" variant="primary-outline" onClick={() => props.fecharPlantas && props.fecharPlantas()}>
            <BiXCircle />
          </Button>
        }

        {props.checarLinha !== undefined && (
          <div className="sort-absolute ">
            <Form.Check/>
            {/* {ordem === undefined ? (
              <FaSort></FaSort>
            ) : ordem !== "asc" ? (
              <FaSortUp className="text-primary"></FaSortUp>
            ) : (
              <FaSortDown className="text-primary"></FaSortDown>
            )} */}
          </div>
        )}
      </div>
    </th>
  );
};

export default TableRow;
