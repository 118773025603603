// @flow
import * as React from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiltroPrecoBase, RepositorioPrecoBase } from "../../core/repositories/precoBaseRepositorio";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import SelecionarBusca from "../../components/SelecionarBusca";
import { ModeloBasico } from "../../core/models/basico";
import { precoBaseContexto } from "../../core/contexts/precoBaseContexto";
import { TipoTela } from "../../core/config/tipoTela";


interface Props {
  filtro: FiltroPrecoBase;
  atualizarFiltro: (filtro: FiltroPrecoBase) => void;
  filtrarDados: (filtro: FiltroPrecoBase) => void;
}

export const Filter: React.FC<Props> = (props) => {

  const { filtroNoContexto } = precoBaseContexto();
  
  const { mostrarToast } = useToast();
  const { t } = useTranslation();
  const [carregarBtnExportar, setCarregarBtnExportar] = useState(false);
  const [branchList, setBranchList] = useState<ModeloBasico[]>([]);
  const [departmentList, setDepartmentList] = useState<ModeloBasico[]>([]);
  const [categoryList, setCategoryList] = useState<ModeloBasico[]>([]);
  const [subcategoryList, setSubcategoryList] = useState<ModeloBasico[]>([]);
  const [productList, setProductList] = useState<ModeloBasico[]>([]);
  const [yearWeekList, setYearWeekList] = useState<ModeloBasico[]>([]);
  const [mesorregionList, setMesorregionList] = useState<ModeloBasico[]>([]);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const repositorio = new RepositorioPrecoBase();
  useEffect(() => {
    if (!hasFetchedData) {
      try {
        repositorio.getLista("periodo_analisado").then((res) => {
          setYearWeekList(res);
          if (res.length > 0) {
            props.filtro.num_ano_semana = res[0].codigo.toString();
            props.atualizarFiltro(props.filtro);
            props.filtrarDados(props.filtro);
          }
        });
        repositorio.getLista("agencia").then((res) => {
          setBranchList(res);
        });
        repositorio.getLista("departamento").then((res) => {
          setDepartmentList(res);
        });
        repositorio.getLista("categoria").then((res) => {
          setCategoryList(res);
        });
        repositorio.getLista("subcategoria").then((res) => {
          setSubcategoryList(res);
        });
        repositorio.getLista("produto").then((res) => {
          setProductList(res);
        });
        repositorio.getLista("mesorregiao").then((res) => {
          setMesorregionList(res);
        });

      } catch (error) {
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      }
    }
    setHasFetchedData(true);
  }, []);

  function exportarDados() {
    setCarregarBtnExportar(true);

    repositorio.baixar(filtroNoContexto).then((req) => {
      var blob = new Blob([req.data], {
        type: req.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `preco_base_${new Date().toISOString()}.xlsx`;
      link.click();

      setCarregarBtnExportar(false);
    }).catch(() => {
      setCarregarBtnExportar(false);
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    });
  }



  return (
    <div className="stick">
      <FiltroCabecalho
        titulo="Módulo de Preço Base"
        filtro={props.filtro}
        atualizarFiltro={props.atualizarFiltro} 
        tipoTela={TipoTela.PRECO_BASE}
      >
        <>
          <Row>

            <Col>
              <SelecionarBusca
                titulo="Período Analisado"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={false}
                value={props.filtro.num_ano_semana}
                options={yearWeekList}
                onChange={(value) => {
                  props.filtro.num_ano_semana = value.toString();
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo="Filial"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={true}
                value={props.filtro.des_agencia}
                options={branchList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.des_agencia = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo="Departamento"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={true}
                value={props.filtro.des_departamento}
                options={departmentList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.des_departamento = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo="Categoria"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={true}
                value={props.filtro.des_categoria}
                options={categoryList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.des_categoria = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <SelecionarBusca
                titulo="Subcategoria"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={true}
                value={props.filtro.des_subcategoria}
                options={subcategoryList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.des_subcategoria = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>
            <Col>
              <SelecionarBusca
                titulo="Produto"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={true}
                value={props.filtro.des_produto}
                options={productList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.des_produto = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>


            <Col>
              <SelecionarBusca
                titulo="Mesorregião"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={true}
                value={props.filtro.des_mesorregiao}
                options={mesorregionList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.des_mesorregiao = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>



          
            <Col>
            </Col>

            <Col>
              <div className="d-flex justify-content-end margin-top-32">
                <div>
                  <Button className="ms-1 text-white" variant="secondary" onClick={exportarDados} disabled={carregarBtnExportar}>
                  {carregarBtnExportar && <Spinner size="sm"/>}
                    Relatório
                  </Button>
                </div>
                <Button className="ms-1" variant="primary" onClick={() => {
                  props.filtrarDados(props.filtro);
                }}>
                  Buscar
                </Button>

              </div>
            </Col>


          </Row>

          {/* <Row>
            <Col md={2}>
              <Button
                className="ms-1"
                variant="primary"
                size="sm"
                onClick={() => {
                  // setFiltroAberto
                  props.filtrarDados(props.filtro);
                }}
              >
                Buscar
              </Button>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={12}>
              <div className="d-flex justify-content-between">
                <div>
             
                <Button className="ms-1" variant="secondary" size="sm" onClick={() => { props.filtrarDados(new FiltroPrecoBase()); }}            >
                  Relatório
                </Button>
                </div>
                <Button className="ms-1" variant="primary" size="sm" onClick={() => {
                  props.filtrarDados(props.filtro);
                }}>
                  Buscar
                </Button>

              </div>
            </Col>
          </Row> */}
        </>
      </FiltroCabecalho>
    </div>
  );
};
