import React, { useRef, useState } from "react";
import { Button, Col, Collapse, Row, Spinner } from "react-bootstrap";

import { useToast } from "../../core/contexts/toast";
import PlantaIndustrialPainelResultadoIA from "./painelResultadoIA";
import PlantaIndustrialPainelFatores from "./painelFatores";
import PlantaIndustrialPainelDefinicao from "./painelDefinicao";
import TabelaPlantaIndustrial from "../../components/TabelaPlantaIndustrial";
import { plantaIndustrialContext } from "../../core/contexts/plantaIndustrialContexto";
import { ModeloPlantaIndustrialListaTabela, ModeloPlantaIndustrialResultado } from "../../core/models/plantaIndustrial";
import { RepositorioPlantaIndustrial, FiltroPlantaIndustrial } from "../../core/repositories/plantaIndustrialRepositorio";
import { BiCloudDownload, BiCheck, BiSave } from "react-icons/bi";
import { ModeloCenario, atualizarAtributosCenario } from "../../core/models/cenario";
import { BotaoPlantaIndustrial } from "../../components/BotaoPlantaIndustrial";
import FiltroPI from "./filtro";
import { useAutenticação } from "../../core/contexts/auth";
import { ModalCenarioTabela } from "../../components/ModalCenario/modalCenarioTabela";
import { ModalCenarioSalvar } from "../../components/ModalCenario/modalCenarioSalvar";
import { ModalCenarioNome } from "../../components/ModalCenario/modalCenarioNome";
import { RepositorioCenario } from "../../core/repositories/cenarioRepositorio";
import { atualizaTamanhoTabela, formatarPeriodoData, gerarTituloCenario, saoCenariosIguais } from "../../core/utils/funcoes";
import { useModal } from "../../core/contexts/modalContexto";
import { RepositorioPlantaIndustrialEdicao } from "../../core/repositories/plantaIndustrialEdicaoRepositorio";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { ModeloBasico } from "../../core/models/basico";
import moment from "moment";
import "./index.scss";
import { useTranslation } from "react-i18next";


var contador: number = 0;

export default function PlantaIndustrial() {
  const { t } = useTranslation();
  const bodyRef = useRef(null);

  const { lista, carregarLista, atualizarInterface, salvarEditados, plantas, atualizarPlantas, cenario, atualizarCenario, atualizarFiltroContexto, filtroNoContexto, setCarregando } = plantaIndustrialContext();
  const { periodoCarregado, listaPeriodoMes, listaPeriodoSemana } = filtroContexto()

  const { mostrarToast } = useToast();
  const { mostrarModal } = useModal();
  const { usuario } = useAutenticação()

  const [cenarioOriginal, setCenarioOriginal] = useState(new ModeloCenario())

  const [filtro, setFiltro] = useState(new FiltroPlantaIndustrial());
  const [listaResultadoIA, setListaResultadoIA] = useState<ModeloPlantaIndustrialResultado[]>([]);
  const [listaFatorDefinicao, setListaFatorDefinicao] = useState<ModeloPlantaIndustrialResultado[]>([]);
  const [listaTabela, setListaTabela] = useState(new ModeloPlantaIndustrialListaTabela());

  const [mostrarCenarioNome, setMostrarCenarioNome] = useState(false);
  const [mostrarCenarioTabela, setMostrarCenarioTabela] = useState(false);
  const [mostrarCenarioSalvar, setMostrarCenarioSalvar] = useState(false);
  const [mostrarCenarioSalvarAposNome, setMostrarCenarioSalvarAposNome] = useState(false);
  const [carregarBtnExportar, setCarregarBtnExportar] = useState(false);
  const [carregarBtnSalvar, setCarregarBtnSalvar] = useState(false);
  const [carregarBtnFinalizar, setCarregarBtnFinalizar] = useState(false);
  const [carregarTabelaResultadoIA, setCarregarTabelaResultadoIA] = useState(false);
  const [carregarTabelaFatorDefinicao, setCarregarTabelaFatorDefinicao] = useState(false);

  const [listaPeriodo, setListaPeriodo] = useState<ModeloBasico[]>([]);

  const _plantaIndustrial = new RepositorioPlantaIndustrial();

  React.useEffect(() => {

    window.onbeforeunload = function () {

      if (cenario.flg_alterado && window.location.hostname !== 'localhost') {
        return t("pages.Deseja mesmo sair sem salvar o cenário?")
      } else {
        return null
      }
    };
    // Seu código a ser executado quando a altura da página mudar para atualizar o tamanho da tabela
    const observer = new MutationObserver(atualizaTamanhoTabela);
    if (bodyRef.current) {
      observer.observe(bodyRef.current, { attributes: true, childList: true, subtree: true });
    }
    return () => {
      observer.disconnect();
    };
  });

  // para toda vez que o usuario atualizar algum atributo da lista atualizar as qts no cenario
  React.useEffect(() => {
    verificaCenarioModificou(filtro)
  }, [lista])
  React.useEffect(() => {
    carregarPeriodo()
  }, [periodoCarregado, listaPeriodoMes, listaPeriodoSemana])

  React.useEffect(() => {
    carregarPainel(filtro)
  }, [filtro.flg_mostrar_resultado])

  function carregarPeriodo() {
    if (periodoCarregado && listaPeriodoMes && listaPeriodoSemana) {
      var aux = filtro.flg_ano_mes ? listaPeriodoMes : listaPeriodoSemana
      setListaPeriodo(aux);
      atualizarPeriodoPadrao(aux);
    }
  }

  async function atualizarPeriodoPadrao(lista: ModeloBasico[], filtroAux = filtro) {
    const aux = { ...filtroAux }
    if (lista.length > 0) {
      var t = new Date().toISOString();
      var atual = moment(t).add(aux.flg_ano_mes ? 0 : 1, "days").format(aux.flg_ano_mes ? "YYYYM" : "YYYYWW");
      aux.des_periodo_analisado = lista.find(x => x.codigo == atual)?.codigo || lista[0].codigo;
      const elemento = (document.getElementById("Período Analisado") as any)
      elemento.value = aux.des_periodo_analisado
    } else {
      aux.des_periodo_analisado = ""
    }

    setTimeout(() => {
      atualizarFiltro(aux)
    }, 500);
  }

  function carregarPainel(filtroAplicado: FiltroPlantaIndustrial) {
    if (filtro.flg_mostrar_resultado && filtro.flg_carregar_painel) {
      if (!filtroAplicado.des_periodo_analisado) {
        filtroAplicado = filtro
      }

      const _contador = contador++;
      setCarregarTabelaResultadoIA(true);
      setCarregarTabelaFatorDefinicao(true);

      if (_contador === contador - 1) {
        _plantaIndustrial.getResultado(filtroAplicado).then(listaResultadoIA => {

          listaResultadoIA = listaResultadoIA.map((x: any) => {
            x.num_ia_faturamento = x.num_preco_ia * x.num_volume_ia
            return x
          });
          setListaResultadoIA(listaResultadoIA.sort((a, b) => a.des_canal.localeCompare(b.des_canal)));


          listaResultadoIA = listaResultadoIA.map((x: any) => {
            x.num_mkt_faturamento = x.num_preco_mkt_novo * x.num_volume_mkt_novo
            return x
          });

          setListaFatorDefinicao(listaResultadoIA.sort((a, b) => a.des_canal.localeCompare(b.des_canal)));

          setCarregarTabelaResultadoIA(false);
          setCarregarTabelaFatorDefinicao(false);
          // carregarFatorDefinicao(filtroAplicado)
        }).catch(() => {
          mostrarToast({
            titulo: t("pages.Erro!"),
            mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
            tipo: "error",
          });
          setCarregarTabelaResultadoIA(false);
          setCarregarTabelaFatorDefinicao(false);
        });
      }

      filtro.flg_carregar_painel = false
      setFiltro({ ...filtro })
    }
  }

  function carregarFatorDefinicao(filtroAplicado: FiltroPlantaIndustrial) {
    if (filtro.flg_mostrar_resultado) {
      const _contador = contador++;
      setCarregarTabelaFatorDefinicao(true);
      if (_contador === contador - 1) {
        _plantaIndustrial.getResultado(filtroAplicado).then(listaFatorDefinicao => {
          listaFatorDefinicao = listaFatorDefinicao.map((x: any) => {
            x.num_mkt_faturamento = x.num_preco_mkt_novo * x.num_volume_mkt_novo
            return x
          });

          setListaFatorDefinicao(listaFatorDefinicao.sort((a, b) => a.des_canal.localeCompare(b.des_canal)));
          setCarregarTabelaFatorDefinicao(false);
        }).catch((error) => {
          console.error(error)
          mostrarToast({
            titulo: t("pages.Erro!"),
            mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
            tipo: "error",
          });
          setCarregarTabelaFatorDefinicao(false);
        });
      }
    }
  }

  async function verificaMudança() {
    if (cenario.num_de_produtos_alterado_capitao || cenario.num_de_produtos_alterado_relativo) {
      const modalResultado = await mostrarModal({
        titulo: t("pages.Aviso Importante"),
        subTitulo: t("pages.Caso você continue, perderá a simulação dos volumes/preços. Esta ação é irreversível!"),
        acao: t("pages.Continuar")
      })
      return modalResultado
    }
    return Promise.resolve(true)
  }

  async function carregarDados(filtro: FiltroPlantaIndustrial) {
    try {
      if (usuario) {
        await new RepositorioPlantaIndustrialEdicao().delete(usuario.cod_usuario)
      }
      const _contador = contador++;
      if (_contador === contador - 1) {
        await carregarLista(filtro);
        atualizarInterface();
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }

  function carregarCenario(cenario: ModeloCenario) {

    setMostrarCenarioTabela(false)
    verificaMudança().then(res => {
      if (res) {
        // limparLista();
        cenario.flg_alterado = false;
        atualizarCenario(cenario);
        setCenarioOriginal({ ...cenario })

        var filtroAux = new FiltroPlantaIndustrial();
        filtroAux.flg_mostrar_resultado = cenario.flg_mostrar_resultado;
        filtroAux.cod_acabamento = cenario.des_filtro_acabamento;
        filtroAux.cod_canal = cenario.des_filtro_canal;
        filtroAux.cod_cluster = cenario.des_filtro_cluster;
        filtroAux.cod_diretoria = cenario.des_filtro_diretoria;
        filtroAux.cod_familia_subgrupo_produto_capitao = cenario.des_filtro_familia_subgrupo_produto_capitao;
        filtroAux.cod_linha_produto_capitao = cenario.des_filtro_linha_produto_capitao;
        filtroAux.des_periodo_analisado = cenario.num_semana_ano ? cenario.num_semana_ano.toString() : cenario.des_ano_mes;
        filtroAux.cod_produto = cenario.des_filtro_produto;
        filtroAux.cod_gerencia = cenario.des_filtro_gerencia;
        filtroAux.cod_produto_capitao = cenario.des_filtro_produto_capitao;
        filtroAux.cod_temperatura = cenario.des_filtro_temperatura;
        filtroAux.cod_tipo_produto = cenario.des_filtro_tipo_produto;
        filtroAux.cod_mercado = cenario.des_filtro_mercado;
        filtroAux.cod_divisao_comercial = cenario.des_filtro_divisao_comercial;
        filtroAux.cod_bandeira = cenario.des_filtro_bandeira;
        filtroAux.ini_uf_destino = cenario.des_filtro_uf_destino;
        filtroAux.flg_ano_mes = !cenario.num_semana_ano;
        filtroAux.cod_cenario = cenario.cod_cenario

        plantas.map(x => x.selecionada = false)
        if (cenario.des_filtro_planta) {
          cenario.des_filtro_planta.split(",").map(x => {
            const aux = plantas.find(y => y.cod_empresa.toString() == x)
            if (aux) {
              aux.selecionada = true
            }
          });
        }

        atualizarPlantas([...plantas])
        filtrarDados(filtroAux)
        carregarPainel(filtroAux)
      }
    })
  }

  function verificaCenarioModificou(data: FiltroPlantaIndustrial) {
    var cenarioAux = atualizarAtributosCenario(cenario, data)

    cenario.num_de_produtos_alterado_capitao = lista.filter(produto =>
      produto.num_nivel == 3 &&
      ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original))).length

    cenario.num_de_produtos_alterado_relativo = lista.filter(produto =>
      produto.num_nivel > 3 &&
      ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original))).length

    cenario.flg_alterado = !saoCenariosIguais(cenarioOriginal, cenarioAux)

    if (!cenario.flg_alterado && (cenario.num_de_produtos_alterado_capitao > 0 || cenario.num_de_produtos_alterado_relativo > 0)) {
      cenario.flg_alterado = true
    }
    atualizarCenario(cenario)
  }

  function atualizarFiltro(novoFiltro: FiltroPlantaIndustrial) {
    setFiltro({ ...novoFiltro });
    cenario.des_filtro_planta = plantas.filter(x => x.selecionada).map(x => x.cod_empresa).join(',');
    verificaCenarioModificou(novoFiltro);
  }

  async function filtrarDados(novoFiltro: FiltroPlantaIndustrial) {
    verificaMudança().then(res => {
      if (res) {
        setCarregando(true)
        novoFiltro.cod_usuario = usuario?.cod_usuario
        novoFiltro.flg_carregar_painel = true
        novoFiltro.flg_aberto = false

        setFiltro({ ...novoFiltro });
        atualizarFiltroContexto({ ...novoFiltro })

        carregarDados(novoFiltro);
        carregarPainel(novoFiltro);
      }
    })
  }

  async function salvarCenario() {
    try {
      setCarregarBtnSalvar(true);
      setMostrarCenarioSalvar(false);

      //pegar o dados do usuario
      if (cenario.flg_editar) {
        cenario.cod_usuario = usuario?.cod_usuario;
        cenario.des_filtro_planta = plantas.filter(x => x.selecionada).map(x => x.cod_empresa).join(',');

        var cenarioAux = atualizarAtributosCenario(cenario, filtro)

        cenarioAux = await new RepositorioCenario().salvar(cenarioAux)
        cenarioAux.flg_editar = true;
        cenarioAux.flg_alterado = false;
        cenario.flg_alterado = false;
        cenario.cod_cenario = cenarioAux.cod_cenario;
        filtro.cod_cenario = cenarioAux.cod_cenario;
        filtroNoContexto.cod_cenario = cenarioAux.cod_cenario
        // atualizarCenario(cenarioAux);
        const totalSalvos = await salvarEditados();
        atualizarFiltro(filtro)
        atualizarFiltroContexto(filtroNoContexto)

        atualizarInterface()
        setCarregarBtnSalvar(false);
        //Tem que Chamar novamente para atualizar o nome do cenario original
        atualizarCenario(cenarioAux);
        setCenarioOriginal({ ...cenarioAux });

        if (usuario) {
          await new RepositorioPlantaIndustrialEdicao().delete(usuario.cod_usuario)
        }

        mostrarToast({
          titulo: t("pages.Sucesso!"),
          mensagem: `${t("pages.Cenário foi salvo e")} ${totalSalvos} ${totalSalvos > 0 ? t("pages.produtos foram atualizados") : t("pages.produto foi atualizado")} ! `,
          tipo: "success",
        });
      }

    } catch (error) {
      setCarregarBtnSalvar(false);
      cenario.flg_alterado = true;
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }

  function exportarDados() {
    setCarregarBtnExportar(true);

    _plantaIndustrial.baixar(filtroNoContexto).then((req) => {
      var blob = new Blob([req.data], {
        type: req.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `planta_industrial_${new Date().toISOString()}.xlsx`;
      link.click();

      setCarregarBtnExportar(false);
    }).catch(() => {
      setCarregarBtnExportar(false);
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    });
  }
  function limparFiltro() {

    const _filtro = new FiltroPlantaIndustrial();
    _filtro.flg_ano_mes = filtro.flg_ano_mes;
    _filtro.des_periodo_analisado = filtro.des_periodo_analisado;
    _filtro.flg_aberto = filtro.flg_aberto;
    _filtro.flg_mostrar_resultado = filtro.flg_mostrar_resultado;
    plantas.map(x => x.selecionada = false);
    atualizarPlantas([...plantas])

    atualizarFiltro(_filtro)
    atualizarPeriodoPadrao(listaPeriodo, _filtro);

  }
  async function finalizarCenario() {
    try {
      const modalResultadoConfirmacao = await mostrarModal({
        titulo: t("pages.Aviso Importante"),
        subTitulo: t("pages.Caso você continue, o cenário será finalizado. Esta ação é irreversível!"),
        acao: t("pages.Continuar")
      })

      if (modalResultadoConfirmacao) {
        var resultadoVerificacao = await new RepositorioCenario().verificarCenarioPorPeriodo(cenario);
        var modalResultadoVerificao = true

        if (!resultadoVerificacao) {
          modalResultadoVerificao = await mostrarModal({
            titulo: t("pages.Já existe um cenário finalizado nesse período"),
            subTitulo: t("pages.Caso você continue, outro cenário com mesmo período será finalizado, substituindo o anterior. Esta ação é irreversível!"),
            acao: t("pages.Substituir")
          })
        }

        if (modalResultadoVerificao) {
          setCarregarBtnFinalizar(true)
          await new RepositorioCenario().finalizarCenario(cenario)
          cenario.flg_finalizado = true;
          setCenarioOriginal({ ...cenario })
          atualizarCenario(cenario)
          mostrarToast({
            titulo: t("pages.Finalizado!"),
            mensagem: t("pages.Cenário finalizado com sucesso!"),
            tipo: "success",
          });
          setCarregarBtnFinalizar(false);
        }
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
      setCarregarBtnFinalizar(false);
    }

  }

  return (
    <div className="planta-industrial" ref={bodyRef}>
      <FiltroPI
        titulo={t("pages.Vendas Diretas")}
        filtro={filtro}
        atualizarFiltro={atualizarFiltro}
        listaPeriodo={listaPeriodo}
        cenario={cenario}
        filtrarDados={filtrarDados}
        listaTabela={listaTabela}
        setListaTabela={setListaTabela}
        carregarPeriodo={carregarPeriodo}
        mostrarCenarioNome={() => setMostrarCenarioNome(true)}
      />

      <Collapse in={filtro.flg_mostrar_resultado} >
        <div id="paineis">
          <Row className="mx-0">
            <Col md={4} className="ps-0">
              <PlantaIndustrialPainelResultadoIA carregar={carregarTabelaResultadoIA} listaResultadoIA={listaResultadoIA} />
            </Col>
            <Col md={4}>
              <PlantaIndustrialPainelFatores carregar={carregarTabelaFatorDefinicao} listaFatorDecisaoIA={listaFatorDefinicao} />
            </Col>
            <Col md={4} className="pe-0">
              <PlantaIndustrialPainelDefinicao carregar={carregarTabelaFatorDefinicao} listaDefinicaoMkt={listaFatorDefinicao} />
            </Col>
          </Row>
        </div>
      </Collapse>

      <div className="mesma-linha my-1">
        <div>
          <Button className="me-1" variant="outline-primary" size="sm" onClick={limparFiltro}              >
            {t("pages.Limpar")}
          </Button>
          <Button disabled={lista.length == 0 || carregarBtnExportar} className="me-1"
            onClick={exportarDados} size='sm' variant="outline-dark">
            {carregarBtnExportar ?
              <Spinner animation="border" size="sm" className="me-1" /> :
              <BiCloudDownload className="me-1" />
            }
            {t("pages.Exportar")}
          </Button>

          <Button disabled={!cenario.cod_cenario || carregarBtnFinalizar || cenario.flg_alterado || !cenario.flg_editar || cenario.flg_finalizado} className="me-1"
            onClick={finalizarCenario} size='sm' variant="dark">
            {carregarBtnFinalizar ?
              <Spinner animation="border" size="sm" className="me-1" /> :
              <BiCheck className="me-1" />
            }
            {t("pages.Finalizar")}
          </Button>
        </div>

        <div >
          <Button disabled={carregarBtnSalvar} onClick={() => setMostrarCenarioTabela(true)} size='sm' variant="primary" className="me-1">
            {t("pages.Cenários")}
          </Button>


          <Button disabled={!cenario.flg_editar || !cenario.flg_alterado || cenario.flg_finalizado}
            onClick={() => {
              if (cenario.flg_editar) {
                if (cenario.des_cenario) {
                  setMostrarCenarioSalvar(true)
                } else {
                  setMostrarCenarioSalvarAposNome(true)
                  setMostrarCenarioNome(true)
                }
              } else {
                filtro.cod_cenario = -1;
                filtroNoContexto.cod_cenario = -1;
                atualizarCenario(new ModeloCenario());
              }

            }} size='sm' variant="dark" className="me-1">
            {carregarBtnSalvar ?
              <Spinner animation="border" size="sm" className="me-1" /> :
              <BiSave className="me-1" />
            }
            {cenario.cod_cenario > 0 ? t("pages.Atualizar") : t("pages.Salvar")}
          </Button>

          <Button disabled={!cenario.cod_cenario && !cenario.flg_editar} title={t("pages.Criar novo cenário")}
            onClick={() => {
              filtro.cod_cenario = -1;
              filtroNoContexto.cod_cenario = -1;
              atualizarCenario(new ModeloCenario());
            }} size='sm' variant="outline-dark" className="me-1">
            {t("pages.Novo")}
          </Button>


          <BotaoPlantaIndustrial filtro={filtro} atualizarFiltro={() => { atualizarFiltro(filtro) }} />
          <Button className="ms-1" variant="primary" size="sm" disabled={listaPeriodo.length == 0}
            onClick={() => {
              filtrarDados(filtro)
            }}>
            {t("pages.Buscar")}
          </Button>
        </div>
      </div>

      <div className="table-100" >
        <TabelaPlantaIndustrial
          filtro={filtroNoContexto}
          aberto={filtro.flg_aberto}
          salvouEdicao={() => {
            carregarFatorDefinicao(filtroNoContexto)
          }}
        />
      </div>

      <ModalCenarioNome
        show={mostrarCenarioNome}
        nomeCenario={cenario.des_cenario || gerarTituloCenario(filtro, t("pages.Vendas") )}
        onHide={() => {
          if (mostrarCenarioSalvarAposNome && cenario.des_cenario) {
            setMostrarCenarioSalvar(true);
          }
          setMostrarCenarioNome(false)
        }}
        salvar={(nome) => {
          if (cenario.des_cenario != nome) {
            cenario.flg_alterado = true
          }
          cenario.des_cenario = nome;
          atualizarCenario(cenario)
          if (mostrarCenarioSalvarAposNome && nome) {
            setMostrarCenarioSalvar(true);
            setMostrarCenarioSalvarAposNome(false);
          }
        }}
        verificarNome={async (nome) => {
          try {
            const cenarioAux = { ...cenario }
            cenarioAux.des_cenario = nome;
            cenarioAux.flg_tela_planta_cd = true;
            const existe = await new RepositorioCenario().verificarCenarioPorNome(cenarioAux)
            return existe
          } catch (error) {
            mostrarToast({
              titulo: t("pages.Erro!"),
              mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
              tipo: "error",
            });
            return true
          }

        }}
      />

      <ModalCenarioTabela
        show={mostrarCenarioTabela}
        flg_ano_mes={filtro.flg_ano_mes}
        telaCenario={"Planta"}
        abrirCenario={carregarCenario}
        onHide={() => setMostrarCenarioTabela(false)}
      />

      <ModalCenarioSalvar
        cenario={atualizarAtributosCenario(cenario, filtro)}
        show={mostrarCenarioSalvar}
        onHide={() => setMostrarCenarioSalvar(false)}
        des_periodo_analisado={formatarPeriodoData(filtro.des_periodo_analisado || "", filtro.flg_ano_mes)}
        editarNome={() => {
          setMostrarCenarioSalvarAposNome(true);
          setMostrarCenarioNome(true);
          setMostrarCenarioSalvar(false)
        }}
        salvarCenario={salvarCenario}
      />
    </div>
  );
}
