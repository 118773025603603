import React, { useEffect, useRef, useState } from "react";
import { formatLargeNumber, formatarDecimal, formatarPorcentagem } from "../../core/utils/funcoes";
import { useToast } from "../../core/contexts/toast";
import Image from "react-bootstrap/Image";
import spinner from "../../assets/icons/spinner-black-sm.svg";
import { FaMinusCircle, FaPlusCircle, FaTable } from "react-icons/fa";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

import { ModeloOtimizacaoReceita, ModeloOtimizacaoReceitaFatoresDecisao, ModeloOtimizacaoReceitaGranularidade } from "../../core/models/otimizacaoReceita";
import { FiltroOtimizacaoReceita } from "../../core/repositories/otimizacaoReceitaRepositorio";
import { otimizacaoReceitaContext } from "../../core/contexts/otimizacaoReceitaContexto";
import FatorDecisao from "../FatorDecisao";
import FatorDecisaoOtimizacao from "../FatorDecisaoOtimizacao";
import { useAutenticação } from "../../core/contexts/auth";
import { useTranslation } from "react-i18next";

interface Props {
  linha: ModeloOtimizacaoReceita;
  index: number;
  filtro: FiltroOtimizacaoReceita;
  fatores: ModeloOtimizacaoReceitaFatoresDecisao;
  mostrarBandeira: (data: ModeloOtimizacaoReceita) => void;
  mostrarGrafico: () => void;
}

// export default function TabelaTr(props: Props) {

export const TabelaTr: React.FC<Props> = (props) => {
  const { mostrarToast } = useToast();
  const [carregar, setCarregar] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [flgElasticidade, setFlgElasticidade] = useState<boolean>();
  const { usuario } = useAutenticação();
  const { carregandoFilho, adicionarLista, removerLista, atualizarInterface } = otimizacaoReceitaContext();
  const { t } = useTranslation();



  useEffect(() => {
    if (props.linha.flg_elasticidade != flgElasticidade) {
      setFlgElasticidade(props.linha.flg_elasticidade)
    }
  }, [props.linha.flg_elasticidade])

  async function carregarDados() {
    try {
      if (props.linha.num_nivel == 1) {
        props.mostrarBandeira(props.linha)
      } else {
        setCarregar(true);
        if (props.linha.flg_aberto) {
          removerLista(props.index);
        } else {
          await adicionarLista(props.filtro, props.index)
        }
        atualizarInterface()
      }
    } catch (error) {
      console.error(error)
      console.log("b")
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    } finally {
      setCarregar(false);
    }
  }
  //fazer o calculo da elasticidade aqui


  function copiar() {
    var label = ""
    for (let index = 0; index <= props.linha.num_nivel; index++) {
      label += `${ModeloOtimizacaoReceitaGranularidade.INTERFACE[index]?.toString()}:(${(props.linha as any)[ModeloOtimizacaoReceitaGranularidade.CODIGO[index]]?.toString()}) - ${(props.linha as any)[ModeloOtimizacaoReceitaGranularidade.DESCRICAO[index]]?.toString()}\n`;
    }
    for (let i = 0; i <= props.linha.num_nivel; i++) {
      label += `AND ${ModeloOtimizacaoReceitaGranularidade.CODIGO[i]?.toString()} = ${(props.linha as any)[ModeloOtimizacaoReceitaGranularidade.CODIGO[i]]?.toString()}\n`;
    }
    const inputTemporario = document.createElement("textarea");
    inputTemporario.value = label;
    document.body.appendChild(inputTemporario);
    inputTemporario.select();
    document.execCommand("copy");
    document.body.removeChild(inputTemporario);
  }


  function carregandoValor(valor: number, money: boolean = false, className = "", maximumFractionDigits = 2) {
    if (valor === undefined) {
      return <td className={"text-center position-relative " + className}> <Spinner size="sm" /> </td>
    }
    return <td className={className + " position-relative text-end"} title={formatarDecimal(valor, money, "BRL", maximumFractionDigits)}>{formatLargeNumber(valor, money ? t("components.R$"): "")}</td>
  }

  function carregandoValorPreco(valor: number, money: boolean = false, className = "", cd = false) {

    if (valor === undefined) {
      return <td className={"text-center position-relative " + className}> <Spinner size="sm" /> </td>
    }
    if (valor) {

      return <OverlayTrigger

        overlay={
          <Tooltip id="button-tooltip" >
            <div className="text-center width-200">
              {cd && <> {(props.linha as any).flg_cd_ajustado ? "O cliente geralmente não opta pela modalidade de entrega via Entrega Direta. O preço é estimado com base em análises estatísticas." : ''}  <br />  </>}
              {!cd && <> {(props.linha as any).flg_loja_ajustado ? "O cliente geralmente não opta pela modalidade de entrega via Entrega CDGC. O preço é estimado com base em análises estatísticas." : ''} <br />  </>}
            </div>

            {(usuario?.cod_usuario === "erielcioimamura-mtz" || usuario?.cod_usuario === "rafaeljunior-mtz") &&
              <>
                Price Regressor: {(props.linha as any).ia_modelo_tipo1} | {formatarPorcentagem((props.linha as any).ia_modelo_tipo1 / props.linha.num_qts_ia_modelo_tipo, 1)}<br />
                Random Effect: {(props.linha as any).ia_modelo_tipo2} | {formatarPorcentagem((props.linha as any).ia_modelo_tipo2 / props.linha.num_qts_ia_modelo_tipo, 1)}
              </>
            }
          </Tooltip>
        }
      >
        <td className={className + " position-relative text-end"} title={formatarDecimal(valor, money, "BRL", 2)}>{formatLargeNumber(valor, money ? t("components.R$") : "")}</td>
      </OverlayTrigger>
    }
    return <td className={className + " position-relative text-end"} title={formatarDecimal(valor, money, "BRL", 2)}>{formatLargeNumber(valor, money ? t("components.R$") : "")}</td>

  }

  return (
    <>
      {/* Granularidade */}
      <td className={(props.linha.edit ? "is-edit " : "") + " bg-white text-start fixed-column "} >
        <div className="drilldown " >
          <div
            className="text-end"
            style={{ minWidth: `${20 + ((props.linha.num_nivel == ModeloOtimizacaoReceitaGranularidade.DESCRICAO.length - 1) ? (props.linha.num_nivel - 1) : (props.linha.num_nivel)) * 15}px` }}
          >
            {(props.linha.num_nivel < ModeloOtimizacaoReceitaGranularidade.DESCRICAO.length - 2 || (props.linha.num_nivel == ModeloOtimizacaoReceitaGranularidade.DESCRICAO.length - 2 && props.linha.num_quantidade > 0)) && (
              <Button variant="light" size="sm" onClick={carregarDados} disabled={carregandoFilho}>
                {carregar ?
                  <Image src={spinner} className="baixar-icon" /> :
                  props.linha.flg_aberto ?
                    <FaMinusCircle /> :
                    props.linha.num_nivel == 1 ? <FaTable /> : <FaPlusCircle />
                }
              </Button>
            )}
          </div>

          <span className="ms-1 w-100" onDoubleClick={copiar}>
            {(props.linha as any)[ModeloOtimizacaoReceitaGranularidade.DESCRICAO[props.linha.num_nivel]]?.toString()}
          </span>
        </div >
      </td >

      {/* Preço de Lista */}
      {carregandoValor(props.linha.num_preco_lista, true, "grupo pequena")}
      {/* volume Vendido */}
      {carregandoValor(props.linha.num_volume_venda_lag1, false)}
      {/* Preço Praticado */}
      {carregandoValor(props.linha.num_preco_venda_lag1, true)}
      {/* Venda S-1 Faturamento */}
      {carregandoValor((Number(props.linha.num_volume_venda_lag1?.toFixed(2)) * Number(props.linha.num_preco_venda_lag1?.toFixed(2))), true, "grupo")}

      {/* Volume v1 */}
      {carregandoValor(props.linha.num_volume_ia_v1, false)}
      {/* Preço v1 */}
      {carregandoValor(props.linha.num_preco_ia_v1, true)}
      {/*Potencial Faturamento v1*/}
      {carregandoValor((Number(props.linha.num_volume_ia_v1?.toFixed(2)) * Number(props.linha.num_preco_ia_v1?.toFixed(2))), true, "grupo")}

      {/*Preco Partida v1*/}

      {carregandoValor(props.linha.num_preco_partida, true, "grupo")}


      {/* Volume v2 Direta */}
      {carregandoValor(props.linha.num_volume_ia_cd, false)}
      {/* Preço v2 Direta */}
      {carregandoValorPreco(props.linha.num_preco_ia_cd, true, "", true)}
      {/*Potencial Faturamento v2 Direta*/}
      {carregandoValor((Number(props.linha.num_volume_ia_cd?.toFixed(2)) * Number(props.linha.num_preco_ia_cd?.toFixed(2))), true, "grupo")}


      {/* Volume v2 CDGC */}
      {carregandoValor(props.linha.num_volume_ia_loja, false)}
      {/* Preço v2 CDGC */}
      {carregandoValorPreco(props.linha.num_preco_ia_loja, true, "", false)}
      {/*Potencial Faturamento v2 CDGC */}
      {carregandoValor((Number(props.linha.num_volume_ia_loja?.toFixed(2)) * Number(props.linha.num_preco_ia_loja?.toFixed(2))), true, "grupo")}

      {props.filtro.flg_fatores && <>

        <td className="pequena">
          <FatorDecisaoOtimizacao
            minValueN={props.fatores.minn_num_fator_arroba}
            maxValueN={props.fatores.maxn_num_fator_arroba}
            minValueP={props.fatores.minp_num_fator_arroba}
            maxValueP={props.fatores.maxp_num_fator_arroba}
            valor={props.linha.num_fator_arroba} />
        </td>

        <td className="pequena">
          <FatorDecisaoOtimizacao
            minValueN={props.fatores.minn_num_fator_dolar}
            maxValueN={props.fatores.maxn_num_fator_dolar}
            minValueP={props.fatores.minp_num_fator_dolar}
            maxValueP={props.fatores.maxp_num_fator_dolar}
            valor={props.linha.num_fator_dolar} />
        </td>

        <td className="pequena">
          <FatorDecisaoOtimizacao
            minValueN={props.fatores.minn_num_fator_frango}
            maxValueN={props.fatores.maxn_num_fator_frango}
            minValueP={props.fatores.minp_num_fator_frango}
            maxValueP={props.fatores.maxp_num_fator_frango}
            valor={props.linha.num_fator_frango} />
        </td>

        <td className="pequena">
          <FatorDecisaoOtimizacao
            minValueN={props.fatores.minn_num_fator_milho}
            maxValueN={props.fatores.maxn_num_fator_milho}
            minValueP={props.fatores.minp_num_fator_milho}
            maxValueP={props.fatores.maxp_num_fator_milho}
            valor={props.linha.num_fator_milho} />
        </td>

        <td className="pequena">
          <FatorDecisaoOtimizacao
            minValueN={props.fatores.minn_num_fator_ovo}
            maxValueN={props.fatores.maxn_num_fator_ovo}
            minValueP={props.fatores.minp_num_fator_ovo}
            maxValueP={props.fatores.maxp_num_fator_ovo}
            valor={props.linha.num_fator_ovo} />
        </td>

        <td className="pequena">
          <FatorDecisaoOtimizacao
            minValueN={props.fatores.minn_num_fator_soja}
            maxValueN={props.fatores.maxn_num_fator_soja}
            minValueP={props.fatores.minp_num_fator_soja}
            maxValueP={props.fatores.maxp_num_fator_soja}
            valor={props.linha.num_fator_soja} />
        </td>

        <td className="pequena">
          <FatorDecisaoOtimizacao
            minValueN={props.fatores.minn_num_fator_suino}
            maxValueN={props.fatores.maxn_num_fator_suino}
            minValueP={props.fatores.minp_num_fator_suino}
            maxValueP={props.fatores.maxp_num_fator_suino}
            valor={props.linha.num_fator_suino} />
        </td>

      </>}

    </>
  );
};
