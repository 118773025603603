import React, { useState } from "react";
import { Table } from "react-bootstrap";
import TableRow from "../TableRow";
import { useToast } from "../../core/contexts/toast";
import { ModeloOtimizacaoReceita, ModeloOtimizacaoReceitaFatoresDecisao, ModeloOtimizacaoReceitaGranularidade } from "../../core/models/otimizacaoReceita";
import { FiltroOtimizacaoReceita, RepositorioOtimizacaoReceita } from "../../core/repositories/otimizacaoReceitaRepositorio";
import Skeleton from "react-loading-skeleton";
import { TabelaTr } from "./tabelaTr";
import './index.scss';
import { ModalBandeira } from "./modalBandeira";
import { useTranslation } from "react-i18next";
import { calculoPonderadoAux, calculMediaAux, transformaNumero2Casa, formatarDecimal, formatLargeNumber } from "../../core/utils/funcoes";
import { TipoInfo } from "../../core/config/tipoInfo";
import FatorDecisaoOtimizacao from "../FatorDecisaoOtimizacao";
import { otimizacaoReceitaContext } from "../../core/contexts/otimizacaoReceitaContexto";

interface Props {
  filtro: FiltroOtimizacaoReceita;
  filtrarDados: (filtro: FiltroOtimizacaoReceita) => void;
  exportarDados: (filtro: FiltroOtimizacaoReceita) => void;

}

export default function TabelaOtimizacaoReceita(props: Props) {
  const { mostrarToast } = useToast();
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = useState(true);
  const { lista, carregando, filtroNoContexto } = otimizacaoReceitaContext();
  const [ultimoHorario, setUltimoHorario] = useState<string>();
  const [showModalBandeira, setModalBandeira] = useState(false);
  const [showModalGrafico, setModalGrafico] = useState(false);
  const [fatores, setFatores] = useState<ModeloOtimizacaoReceitaFatoresDecisao>(new ModeloOtimizacaoReceitaFatoresDecisao());
  const [showLinha, setShowLinha] = useState<ModeloOtimizacaoReceita>();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (carregando && carregandoPrimeiraVez) {
      setCarregandoPrimeiraVez(false)
    }
  }, [carregando]);

  React.useEffect(() => {
    console.log("Mudou")
    setUltimoHorario(undefined)
    new RepositorioOtimizacaoReceita().getModeloUltimoHorario(props.filtro.flg_ano_mes).then(res => {
      if (res) {
        setUltimoHorario(res)
      } else {
        setUltimoHorario("")
      }
    }).catch(() => {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    })
  }, [filtroNoContexto.flg_ano_mes]);



  React.useEffect(() => {
    if (props.filtro.des_periodo_analisado) {
      new RepositorioOtimizacaoReceita().getModeloFatores(filtroNoContexto.flg_ano_mes, props.filtro.des_periodo_analisado || "").then(res => {
        if (res) {
          setFatores(res)
        }
      }).catch(() => {
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      })
    }
  }, [filtroNoContexto.des_periodo_analisado]);

  function exportarDados() {
    if (showLinha) {
      var filtro = { ...props.filtro, cod_produto: showLinha.cod_produto, num_nivel: 3, flg_granular: false }
      props.exportarDados(filtro)
      setModalBandeira(false)
    }
  }



  function title(item: any) {
    return ModeloOtimizacaoReceitaGranularidade.INTERFACE[item.num_nivel] + ": "
      + item[ModeloOtimizacaoReceitaGranularidade.DESCRICAO[item.num_nivel]]?.toString()
  }
  function mostrarBandeira(data: ModeloOtimizacaoReceita) {
    setModalBandeira(true)
    setShowLinha(data)
  }
  function mostrarGrafico(data: ModeloOtimizacaoReceita) {
    setModalGrafico(true)
    setShowLinha(data)
  }


  function calculoTotal(atr: string) {
    if (carregando)
      return undefined
    let total = 0;
    lista.forEach((item: any) => {
      if (item.num_nivel == 0) {
        total += ((item as any)[atr])
      }
    });
    return total;
  }
  function calculoPonderado(preco: string, volume: string) {

    if (carregando)
      return undefined;
    return calculoPonderadoAux(lista, preco, volume);
  }

  function calculMedia(preco: string) {

    if (carregando)
      return undefined;
    return calculMediaAux(lista, preco);

  }

  function carregandoValor(lista: any[], valor: number | undefined, moeda = "", className = "") {
    if (lista.length == 0) {
      return <th className={"text-end " + className} > - </th>
    }
    if (valor === undefined) {
      return <th className={"text-center " + className} > <Skeleton className="bg-body" /> </th>
    }


    // if (Number.isNaN(valor)) {
    //   return <th className={"text-center " + className}> <Spinner size="sm" /> </th>
    // }
    return <th title={formatarDecimal(valor, !!moeda)} className={"text-end " + ((valor || 0) < 0 ? "text-danger " : " ") + className}> {
      formatLargeNumber(valor, moeda)
    }
    </th>
  }


  return (



    <div className="table-responsive  tabela-otimizacao-receita">
      <div className="esconde-bordas" />

      <Table hover bordered striped className="table table-bordered" size="sm">
        <thead >
          <tr>
            <TableRow rowSpan={3} classNameTh=" fixed-column" info={TipoInfo.Granularidade + ' </br> ' + ModeloOtimizacaoReceitaGranularidade.INTERFACE.join(' </br> ')}>{t("pages.Produto")} {filtroNoContexto.flg_capitaes && t("pages.Capitão")}</TableRow>
            <TableRow rowSpan={2} classNameTh="grupo pequena" info={TipoInfo.PrecoLista}>{t("pages.Preço Lista")}</TableRow>
            <TableRow colSpan={3} classNameTh="grupo" >{t("pages.Pedidos Lançados")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}-1)</TableRow>
            {/* <TableRow rowSpan={2} classNameTh="grupo" info={TipoInfo.VendaIAS1Volume}>Volume IA V1 ({props.filtro.flg_ano_mes ? "M" : "S"}+1)</TableRow> */}
            <TableRow colSpan={3} classNameTh="grupo" info={TipoInfo.VendaIA}> {t("pages.IA V1")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
            <TableRow rowSpan={2} classNameTh="grupo" info={TipoInfo.PreçoPartidaV2}>{t("pages.Preço Partida V2")}</TableRow>

            <TableRow colSpan={3} classNameTh="grupo" info={TipoInfo.VendaV2CD}>{t("components.IA V2 Entrega Direta")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1) </TableRow>
            <TableRow colSpan={3} classNameTh="grupo" info={TipoInfo.VendaV2LojaaLoja}>{t("components.IA V2 Entrega CDGC")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
            {/* <TableRow colSpan={6} info={"Sazonalidade"}>Sazonalidade</TableRow> */}

            {props.filtro.flg_fatores && <>
              <TableRow colSpan={7} classNameTh="grupo" info={TipoInfo.FatoresDecisao}>{t("pages.Fatores")}</TableRow>
            </>}

          </tr>

          <tr>
            {/* Venda (S-1) */}
            <TableRow info={TipoInfo.VendaS1Volume}>{t("components.Volume")}</TableRow>
            <TableRow info={TipoInfo.VendaS1Preco}>{t("components.Preço")}</TableRow>
            <TableRow classNameTh="grupo" info={TipoInfo.VendaS1Faturamento}>{t("pages.Fat.")}</TableRow>

            {/* IA V1 (S+1) */}
            <TableRow info={TipoInfo.VendaS1Volume}>{t("components.Volume")}</TableRow>
            <TableRow info={TipoInfo.VendaS1Preco}>{t("components.Preço")}</TableRow>
            <TableRow classNameTh="grupo" info={TipoInfo.VendaIAS1Faturamento}>{t("pages.Poten. Fat.")}</TableRow>

            {/* IA V2 (S+1) */}
            <TableRow info={TipoInfo.VendaS1Volume}>{t("components.Volume")}</TableRow>
            <TableRow info={TipoInfo.VendaS1Preco}>{t("components.Preço")}</TableRow>
            <TableRow classNameTh="grupo" info={TipoInfo.VendaIAS1Faturamento}>{t("pages.Poten. Fat.")}</TableRow>

            {/* IA V2 (S+1) */}
            <TableRow info={TipoInfo.VendaS1Volume}>{t("components.Volume")}</TableRow>
            <TableRow info={TipoInfo.VendaS1Preco}>{t("components.Preço")}</TableRow>
            <TableRow classNameTh="grupo" info={TipoInfo.VendaIAS1Faturamento}>{t("pages.Poten. Fat.")}</TableRow>


            {props.filtro.flg_fatores && <>
              <TableRow classNameTh="pequena" info={TipoInfo.ArrobaBoi}>{t("pages.Arroba")} </TableRow>
              <TableRow classNameTh=" pequena" info="Dolar">{t("pages.Dólar")}</TableRow>
              <TableRow classNameTh=" pequena" info="Frango">{t("pages.Frango")}</TableRow>
              <TableRow classNameTh=" pequena" info="Milho">{t("pages.Milho")}</TableRow>
              <TableRow classNameTh=" pequena" info="Ovo">{t("pages.Ovo")}</TableRow>
              <TableRow classNameTh=" pequena" info="Soja">{t("pages.Soja")}</TableRow>
              <TableRow classNameTh="grupo pequena" info="Suino">{t("pages.Suíno")}</TableRow>

            </>}
            {/* KPIs */}
            {/* <TableRow classNameTh="pequena" info={TipoInfo.SazonalidadePreco}>Preço</TableRow>
            <TableRow classNameTh="pequena" info={TipoInfo.SazonalidadeDemanda}>Demanda</TableRow> */}
          </tr>

          <tr className=" somatorio">
            {/* <th className="fixed-column text-center fw-normal"> {ultimoHorario === undefined ?
              <Spinner size="sm" /> :
              ultimoHorario ?
                <>
                  {t("pages.Última atualização:")}
                  <b>{moment.utc(ultimoHorario).add(-3, 'hours').format(TipoFormatacao.FORMATO_DATA)}</b>
                </> : "Sem dados disponíveis"
            }</th> */}
            {/* Preço de Lista*/}
            {/* <th> {calculMedia("num_preco_lista")} </th> */}

            {carregandoValor(lista, calculMedia("num_preco_lista"), t("components.R$"), "grupo pequena")}
            {/*Venda S-1 Volume */}
            {carregandoValor(lista, calculoTotal("num_volume_venda_lag1"), "")}
            {/*Venda S-1 Preço */}
            {carregandoValor(lista, calculoPonderado("num_preco_venda_lag1", "num_volume_venda_lag1"), t("components.R$"))}
            {/*Venda S-1 Faturamento */}
            {carregandoValor(lista, transformaNumero2Casa(calculoTotal("num_volume_venda_lag1")) * transformaNumero2Casa(calculoPonderado("num_preco_venda_lag1", "num_volume_venda_lag1")), t("components.R$"), "grupo")}
            {/*IA V1 Volume */}
            {carregandoValor(lista, calculoTotal("num_volume_ia_v1"), "")}
            {/*IA V1 Preço */}
            {carregandoValor(lista, calculoPonderado("num_preco_ia_v1", "num_volume_ia_v1"), t("components.R$"))}
            {/*IA V1 Faturamento */}
            {carregandoValor(lista, transformaNumero2Casa(calculoTotal("num_volume_ia_v1")) * transformaNumero2Casa(calculoPonderado("num_preco_ia_v1", "num_volume_ia_v1")), t("components.R$"), "grupo")}
            {/*Preco Partida v1*/}
            {carregandoValor(lista, calculoPonderado("num_preco_partida", "num_volume_ia"), t("components.R$"), "grupo")}
            {/*IA V2 Direta Volume */}
            {carregandoValor(lista, calculoTotal("num_volume_ia_cd"), "")}
            {/*IA V2 Direta Preço */}
            {carregandoValor(lista, calculoPonderado("num_preco_ia_cd", "num_volume_ia_cd"), t("components.R$"))}
            {/*IA V2 Direta Faturamento */}
            {carregandoValor(lista, transformaNumero2Casa(calculoTotal("num_volume_ia_cd")) * transformaNumero2Casa(calculoPonderado("num_preco_ia_cd", "num_volume_ia_cd")), t("components.R$"), "grupo")}
            {/*IA V2 CDGC Volume */}
            {carregandoValor(lista, calculoTotal("num_volume_ia_loja"), "")}
            {/*IA V2 CDGC Preço */}
            {carregandoValor(lista, calculoPonderado("num_preco_ia_loja", "num_volume_ia_loja"), t("components.R$"))}
            {/*IA V2 CDGC Faturamento */}
            {carregandoValor(lista, calculoTotal("num_receita_projetada_ia_loja"), t("components.R$"))}


            {/* {carregandoValor(lista, transformaNumero2Casa(calculoTotal("num_volume_ia_loja")) * transformaNumero2Casa(calculoPonderado("num_preco_ia_loja", "num_volume_ia_loja")), t("components.R$"), "grupo")} */}

            {/* Fatores de Decisão */}

            {props.filtro.flg_fatores && <>
              <th className="pequena">
                <FatorDecisaoOtimizacao
                  minValueN={fatores.minn_num_fator_arroba}
                  maxValueN={fatores.maxn_num_fator_arroba}
                  minValueP={fatores.minp_num_fator_arroba}
                  maxValueP={fatores.maxp_num_fator_arroba}
                  valor={calculMedia("num_fator_arroba")} />
              </th>

              <th className="pequena">
                <FatorDecisaoOtimizacao
                  minValueN={fatores.minn_num_fator_dolar}
                  maxValueN={fatores.maxn_num_fator_dolar}
                  minValueP={fatores.minp_num_fator_dolar}
                  maxValueP={fatores.maxp_num_fator_dolar}
                  valor={calculMedia("num_fator_dolar")} />
              </th>

              <th className="pequena">
                <FatorDecisaoOtimizacao
                  minValueN={fatores.minn_num_fator_frango}
                  maxValueN={fatores.maxn_num_fator_frango}
                  minValueP={fatores.minp_num_fator_frango}
                  maxValueP={fatores.maxp_num_fator_frango}
                  valor={calculMedia("num_fator_frango")} />
              </th>

              <th className="pequena">
                <FatorDecisaoOtimizacao
                  minValueN={fatores.minn_num_fator_milho}
                  maxValueN={fatores.maxn_num_fator_milho}
                  minValueP={fatores.minp_num_fator_milho}
                  maxValueP={fatores.maxp_num_fator_milho}
                  valor={calculMedia("num_fator_milho")} />
              </th>

              <th className="pequena">
                <FatorDecisaoOtimizacao
                  minValueN={fatores.minn_num_fator_ovo}
                  maxValueN={fatores.maxn_num_fator_ovo}
                  minValueP={fatores.minp_num_fator_ovo}
                  maxValueP={fatores.maxp_num_fator_ovo}
                  valor={calculMedia("num_fator_ovo")} />
              </th>

              <th className="pequena">
                <FatorDecisaoOtimizacao
                  minValueN={fatores.minn_num_fator_soja}
                  maxValueN={fatores.maxn_num_fator_soja}
                  minValueP={fatores.minp_num_fator_soja}
                  maxValueP={fatores.maxp_num_fator_soja}
                  valor={calculMedia("num_fator_soja")} />
              </th>

              <th className="pequena">
                <FatorDecisaoOtimizacao
                  minValueN={fatores.minn_num_fator_suino}
                  maxValueN={fatores.maxn_num_fator_suino}
                  minValueP={fatores.minp_num_fator_suino}
                  maxValueP={fatores.maxp_num_fator_suino}
                  valor={calculMedia("num_fator_suino")} />
              </th>

            </>}


          </tr>
        </thead>
        <tbody>

          {(carregando) ? (

            Array.from({ length: 15 }).map(
              (_, index) => (
                <tr className="height-34" key={"skeleton-tr" + index}>
                  {Array.from({ length: props.filtro.flg_fatores ? 19 : 15 }).map((_, index) => (
                    <td key={"skeleton-td" + index}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              )
            )
          ) : (
            lista.length == 0 ?
              <tr >
                <td colSpan={21} className="text-center">
                {carregandoPrimeiraVez ? t("components.Clique em buscar para carregar os dados!") : t("pages.Nenhum registro encontrado!")}
                </td>
              </tr> :

              lista.map((item, index) => (
                !item.oculta &&
                <tr key={"tr-" + item.id + index} className={"font-size-" + (14 - item.num_nivel * 0)} title={title(item)}>
                  <TabelaTr linha={item} index={index} filtro={props.filtro} mostrarBandeira={mostrarBandeira} fatores={fatores} mostrarGrafico={() => { mostrarGrafico(item) }} />
                </tr>
              ))
          )}
          
          {/* <tr className=" border-0">
            <td colSpan={17} className="text-start border-0 bs-white w-100">
              {ultimoHorario === undefined ?
                <Spinner size="sm" /> :
                ultimoHorario ?
                <>
                {t("pages.ultima atualização do banco de dados:")}
                    <b>{moment.utc(ultimoHorario).add(-3, 'hours').format(TipoFormatacao.FORMATO_DATA)}</b>
                  </> : t("pages.Sem dados disponíveis")
              }
            </td>
          </tr> */}

        </tbody>
      </Table>
      <ModalBandeira
        filtro={props.filtro}
        fatores={fatores}
        show={showModalBandeira}
        exportarDados={exportarDados}
        onHide={() => setModalBandeira(false)} data={showLinha} />


    </div>


  );
}

