import React from "react";
import Plot from "react-plotly.js";
import { PropsGraphicKpis } from "./1_precoBoiGordoDolar";
import { agruparPorAnoECalcularMedia } from "..";
import { ModeloKpis } from "../../../core/models/kpis";
import { useTranslation } from "react-i18next";

interface Props {}

export default function PrecoDolarVsVendas(props: PropsGraphicKpis) {
  const [listaPreco, setListaPreco] = React.useState<ModeloKpis[]>([]);
  const [listaFamilia, setListaFamilia] = React.useState<ModeloKpis[]>([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (props.familia) {
      // var lista = props.listaKpis.filter(kpi => kpi.des_indicador === 'PERC_QUANTIDADE_COMPRADA_FEMEA' || kpi.des_indicador === 'PERC_QUANTIDADE_COMPRADA_MACHO' || kpi.des_indicador == "INDICE_TROCA_BOI_BEZERRO" );
      var lista = props.listaKpis.filter((kpi) => kpi.des_indicador === "DOLAR" || kpi.des_indicador === props.familia?.des_familia_subgrupo);

      lista = lista.sort((a, b) => a.dat_referencia.localeCompare(b.dat_referencia));

      var listaDolar = lista.filter((kpi) => kpi.des_indicador === "DOLAR" && kpi.flg_volume == false);
      var listaFamilia = lista.filter((kpi) => kpi.des_indicador === props.familia?.des_familia_subgrupo && kpi.flg_volume == true);
      var listaFamiliaPreco = lista.filter((kpi) => kpi.des_indicador === props.familia?.des_familia_subgrupo && kpi.flg_volume == false);
      // listaDolar = agruparPorAnoECalcularMedia(listaDolar)
      // listaFamilia = agruparPorAnoECalculaSoma(listaFamilia)

      if (!props.lg) {
        listaDolar = agruparPorAnoECalcularMedia(listaDolar);
        listaFamilia = agruparPorAnoECalcularMedia(listaFamilia);
        listaFamiliaPreco = agruparPorAnoECalcularMedia(listaFamiliaPreco);
      }

      var lista_periodo = [...listaDolar.map((x) => x.dat_referencia), ...listaFamilia.map((x) => x.dat_referencia)];
      //remove repetidos
      lista_periodo = lista_periodo.filter((item, index) => lista_periodo.indexOf(item) === index);
      //ordena
      lista_periodo.sort();
      //cria lista de objetos com periodo e valores
      var listaAux: ModeloKpis[] = [];
      lista_periodo.map((x) => {
        var obj = listaDolar.find((y) => y.dat_referencia == x);
        var obj2 = listaFamiliaPreco.find((y) => y.dat_referencia == x);
        if (obj && obj2) {
          var aux = new ModeloKpis();
          aux.dat_referencia = x;
          aux.num_indicador = obj2.num_indicador / obj.num_indicador;

          listaAux.push(aux);
        }
      });

      setListaPreco(listaAux);
      setListaFamilia(listaFamilia);
    }
  }, [props.listaKpis, props.familia]);

  // Configuração dos dados
  const data: Partial<Plotly.Data>[] = [
    // Gráfico de linha - Mantém ele como o último para aparecer na frente
    {
      x: listaPreco.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaPreco.map((x) => x.num_indicador),
      type: "scatter",
      mode: "lines+markers",
      name: t("pages.Dolar"),
      line: { color: "black ", width: 2 },
      marker: { symbol: "circle", size: 6 },
    },

    // Gráfico de barra - Mantém ele primeiro para aparecer atrás
    {
      x: listaFamilia.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaFamilia.map((x) => x.num_indicador),
      type: "bar",
      yaxis: "y2", // Usar eixo Y secundário
      name: props.familia?.des_familia_subgrupo,
      marker: { color: "#D32F2F", opacity: 0.8 }, // Opacidade para destacar a linha
    },
  ];

  const layout: Partial<Plotly.Layout> = {
    title: {
      text: t("pages.Preço") + `: ${props.familia?.des_familia_subgrupo}/` + t("pages.Dolar vs Vendas") + `: ${props.familia?.des_familia_subgrupo}`,
      font: {
        size: 12,
      },
    },
    paper_bgcolor: "rgba(0,0,0,0)", // Transparente
    plot_bgcolor: "rgba(0,0,0,0)", // Transparente,
    xaxis: {
      title: props.lg ? t("pages.Ano") : "",
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    yaxis: {
      title: props.lg ? t("pages.Preço") + `: ${props.familia?.des_familia_subgrupo}/` + t("pages.Dolar") + " " + t("pages.(R$)") : "",
      tickformat: ".0f", // Formato inteiro para o eixo principal
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    yaxis2: {
      title: {
        text: props.lg ? t("pages.Quantidade Vendidas") + `: ${props.familia?.des_familia_subgrupo} (KG)` : "",
      },

      overlaying: "y",
      side: "right",
      tickformat: ".2s", // Formato para números grandes (ex: 1,000 -> 1,000)
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    barmode: "overlay", // Sobrepor os elementos ao invés de empilhar
    legend: {
      x: 0.5,
      xanchor: "center",
      y: -0.2,
      orientation: "h",
      font: {
        size: 14,
      },
    },
    margin: {
      t: 60,
      b: props.lg ? 40 : 20,
      l: props.lg ? 50 : 40,
      r: props.lg ? 50 : 10,
    },
    showlegend: props.lg,
    autosize: true,
  };

  return <Plot data={data} layout={layout} useResizeHandler style={{ width: "100%", height: "100%" }} config={{ displayModeBar: props.lg }} />;
}
