export enum ConstanteGlobal {
  CANAL = "canal",
  REGIONAL = "gerencia",
  EMPRESA = "empresa",
  GERENCIA = "gerencia",
  CLUSTER = "cluster",
  DIRETORIA = "diretoria",
  ACABAMENTO = "acabamento",
  CLIENTE = "cliente",
  FAMILIA_SUBGRUPO = "familia_subgrupo",
  SUBGRUPO = "subgrupo",
  LINHA = "linha",
  PRODUTO = "produto",
  PLANTA_INDUSTRIAL = "planta_industrial",
  RECUPERACAO_CLIENTE = "recuperacao_cliente",
  PLANTA_INDUSTRIAL_EDICAO = "planta_industrial_edicao",
  PLANTA_INDUSTRIAL_PLANTAS = "planta_industrial_plantas",
  PLANTA = "planta",
  TEMPERATURA = "temperatura",
  TIPO_PRODUTO = "tipo_produto",
  MERCADO = "mercado",
  DIVISAO_COMERCIAL = "divisao_comercial",
  CENTRO_DISTRIBUICAO = "centro_distribuicao",
  CENTRO_DISTRIBUICAO_EDICAO = "centro_distribuicao_edicao",
  CENTRO_DISTRIBUICAO_IMPOSTO = "centro_distribuicao_imposto",
  CENARIO = "cenario",
  TERCEIRO = "terceiro",
  BANDEIRA = "bandeira",
  PERIODO_ANALISADO = "periodo_analisado",
  UF_DESTINO = "uf_destino",

  PRODUCT = "product",
  PRODUCT_DEPARTMENT = "product_department",
  PRODUCT_SECTION = "product_section",
  PRODUCT_CATEGORY = "product_category",
  PRODUCT_COMPETITOR = "product_competitor",
  PRODUCT_MATCHING = "product_matching",
  PRODUCT_MATCHING_MANUAL = "product_matching_manual",
  MATCHING_VALIDATIONS = "matching_validations",

  COMPETITOR = "competitor",

  CUSTOMER = "customer",
  CUSTOMER_SEGMENTATION = "customer_segmentation",
  BASE_PRICE_CATALOG = "base_price_catalog",
  BASE_PRICE_CATALOG_EDIT = "base_price_catalog_edit",
  BASE_PRICE_CATALOG_MINIMUM = "base_price_catalog_minimum",
  LOG_API = "log_api",

  USUARIO = "usuario",
  SAZONALIDADE = "sazonalidade",
  UF = "uf",
  CIDADE = "cidade",
  PRECO_BASE = "preco_base",
  PRECO_BASE_EDICAO = "preco_base_edicao",

  PURCHASE_ORDER = "purchase_order",
  BRANCH = "branch",
  MESORREGION = "mesorregion",
  PRODUCT_SUBCATEGORY = "product_subcategory",
  OTIMIZACAO_RECEITA = "otimizacao_receita",
  OTIMIZACAO_RECEITA_EDICAO = "otimizacao_receita_edicao",
  OTIMIZACAO_RECEITA_KPI = "otimizacao_receita_kpi",

  KPIS = "kpis",
}
