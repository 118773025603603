import React from "react";
import Plot from "react-plotly.js";
import { colors_default } from "../../../core/utils/clonarObjeto";
import { PropsGraphicKpis } from "./1_precoBoiGordoDolar";
import { agruparPorAnoECalcularMedia } from "..";
import { ModeloKpis } from "../../../core/models/kpis";
import { useTranslation } from "react-i18next";

interface Props {}
export default function IndiceTrocaBezerroBoi(props: PropsGraphicKpis) {
  const [listaMacho, setListaMacho] = React.useState<ModeloKpis[]>([]);
  const [listaFemea, setListaFemea] = React.useState<ModeloKpis[]>([]);
  const [listaTroca, setListaTroca] = React.useState<ModeloKpis[]>([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    var lista = props.listaKpis.filter(
      (kpi) =>
        kpi.des_indicador === "PERC_QUANTIDADE_COMPRADA_FEMEA" ||
        kpi.des_indicador === "PERC_QUANTIDADE_COMPRADA_MACHO" ||
        kpi.des_indicador == "INDICE_TROCA_BOI_BEZERRO"
    );
    lista = lista.sort((a, b) => a.dat_referencia.localeCompare(b.dat_referencia));

    var listaMacho = lista.filter((kpi) => kpi.des_indicador === "PERC_QUANTIDADE_COMPRADA_MACHO");
    var listaFemea = lista.filter((kpi) => kpi.des_indicador === "PERC_QUANTIDADE_COMPRADA_FEMEA");
    var listaTroca = lista.filter((kpi) => kpi.des_indicador === "INDICE_TROCA_BOI_BEZERRO");
    setListaMacho(agruparPorAnoECalcularMedia(listaMacho));
    setListaFemea(agruparPorAnoECalcularMedia(listaFemea));
    setListaTroca(agruparPorAnoECalcularMedia(listaTroca));
  }, [props.listaKpis]);

  // Configuração dos dados
  const data: Partial<Plotly.Data>[] = [
    {
      x: listaFemea.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaFemea.map((x) => x.num_indicador),
      type: "bar",
      name: t("pages.Proporção Abate Vaca"),
      marker: { color: "rgb(255, 105, 180)" },
    },
    {
      x: listaMacho.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaMacho.map((x) => x.num_indicador),
      type: "bar",
      name: t("pages.Proporção Abate Boi"),
      marker: { color: "rgb(0, 122, 204)" },
    },

    {
      x: listaTroca.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaTroca.map((x) => x.num_indicador),
      type: "scatter",
      mode: "lines+markers",
      name: t("pages.Índice Troca Boi Bezerro"),
      yaxis: "y2", // Usar eixo Y secundário
      line: { color: "#FFA500", width: 2, shape: "spline" },
      marker: { symbol: "circle", size: 6 },
    },
  ];

  // Configuração do layout
  const layout: Partial<Plotly.Layout> = {
    title: {
      text: t("pages.Índice Troca Boi Bezerro"),
      font: {
        size: 12,
      },
    },
    paper_bgcolor: "rgba(0,0,0,0)", // Transparente
    plot_bgcolor: "rgba(0,0,0,0)", // Transparente,
    xaxis: {
      title: props.lg ? t("pages.Ano") : "",
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    yaxis: {
      title: props.lg ? t("pages.Proporção Boi / Vaca (%)") : "",
      range: [0, 100], // Escala de 0 a 100 para proporção
      tickformat: ".0f",
      ticksuffix: "%",
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    yaxis2: {
      title: {
        text: props.lg ? t("pages.Índice Troca Boi Bezerro") : "",
      },
      titlefont: { color: "#FFA500" },
      tickfont: { color: "#FFA500" },
      overlaying: "y",
      side: "right",
      tickformat: ".1f",
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    barmode: "stack", // Habilita o empilhamento de barras
    legend: {
      x: 0.5,
      xanchor: "center", // Centraliza horizontalmente
      y: -0.2,
      orientation: "h", // Legenda horizontal
      font: {
        size: 14,
      },
    },
    margin: {
      t: 60, // Aumenta a margem superior para evitar corte no título
      b: props.lg ? 40 : 20, // Aumenta a margem inferior para a legenda
      l: props.lg ? 50 : 40,
      r: props.lg ? 50 : 25,
    },
    showlegend: props.lg,
  };

  return <Plot data={data} layout={layout} useResizeHandler style={{ width: "100%", height: "100%" }} config={{ displayModeBar: props.lg }} />;
}
