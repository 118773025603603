import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloOtimizacaoReceitaKpi } from "../models/otimizacaoReceitaKpi";

import { Repositorio } from "./repositorio";

export class RepositorioOtimizacaoKpiReceita extends Repositorio<ModeloOtimizacaoReceitaKpi> {
  constructor() {
    super(ConstanteGlobal.OTIMIZACAO_RECEITA_KPI);
  }



  
  getListaKpi(mensal: boolean) {
    return this.api.get(this.nomeRepositorio + "_get_all?mensal=" + mensal).then((res) => {
      return res.data;
    });

   
  }
}
