import React, { useState } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import TableRow from "../TableRow";
import Skeleton from "react-loading-skeleton";
import { TabelaTr } from "./tabelaTr";
import { ModeloCentroDistribuicaoGranularidade } from "../../core/models/centroDistribuicao";
import { FiltroCentroDistribuicao } from "../../core/repositories/centroDistrubuicaoRepositorio";
import './index.scss';
import { centroDistribuicaoContext } from "../../core/contexts/centroDistribuicaoContexto";
import { atualizaTamanhoTabela } from "../../core/utils/funcoes";
import { FaInfoCircle } from "react-icons/fa";
import { RepositorioCenario } from "../../core/repositories/cenarioRepositorio";
import { ModeloCenario } from "../../core/models/cenario";
import { useTranslation } from "react-i18next";

interface Props {
  filtro: FiltroCentroDistribuicao;
  aberto: boolean;
  carregandoPrimeiraVez: boolean;
  salvouEdicao(): void;
  setCarregandoPrimeiraVez(valor: boolean): void;
}

export function atualizarAlturaDaTabelaCentroDistribuicao() {
  setTimeout(() => {
    atualizaTamanhoTabela()
  }, 200);
}

export default function TabelaCentroDistribuicao(props: Props) {
  const { t } = useTranslation();
  const { lista, carregando, filtroNoContexto } = centroDistribuicaoContext();
  const [cenarioAtual, setCenarioAtual] = useState<ModeloCenario>()


  React.useEffect(() => {
    atualizarAlturaDaTabelaCentroDistribuicao();
  }, [props.aberto]);

  React.useEffect(() => {
    if (carregando && props.carregandoPrimeiraVez) {
      props.setCarregandoPrimeiraVez(false)
    }
    new RepositorioCenario().buscarCenarioAplicado(filtroNoContexto).then(res => {
      setCenarioAtual(res)
    })
  }, [carregando]);

  function title(item: any) {
    return t("pages." + ModeloCentroDistribuicaoGranularidade.INTERFACE[item.num_nivel]) + ": "
      + item[ModeloCentroDistribuicaoGranularidade.DESCRICAO[item.num_nivel]]?.toString()
  }

  return (
    <div className="tabela-centro-distribuicao">
      <div>
        <div id="tabela">
          <Table className="rounded" hover bordered striped responsive size="sm"  >
            <thead >
              <tr className="table-header">
                <TableRow classNameTh="width-200 granularidade">{t("components.Granularidade")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.Volume Estoque Inicial")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.CPV Estoque Inicial")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.Shelf")}</TableRow>
                <TableRow colSpan={2} classNameTh="width-160 px-0" className="fator_decisao_farol">
                  <Table className="mb-0">
                    <tbody>
                      <tr>
                        <th colSpan={2} className="text-center pb-0">
                          <label className="mr-2"> {t("components.Reposição")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)
                          
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="button-tooltip">
                                <div className="text-center">
                                  {t("components.Cenário Aplicado")}
                                </div>
                                <div className="text-start">
                                  {cenarioAtual ? (
                                    <>
                                      {t("components.Nome do Cenário")}: <b>{cenarioAtual.des_cenario}</b> <br />
                                      {t("components.Qtd. Produtos Editados")}: <b>{cenarioAtual.num_de_produtos_alterado_capitao}</b> <br />
                                      {t("components.Nome do Usuário")}: <b>{cenarioAtual.des_nome}</b> <br />
                                    </>
                                  ) : (
                                    <>{t("components.Nenhum cenário aplicado")}</>
                                  )}
                                </div>
                              </Tooltip>
                            }
                          >
                            <label className=" pointer ms-1">
                              <FaInfoCircle />
                            </label>
                          </OverlayTrigger>
                           </label>
                 
                        </th>
                      </tr>
                      <tr>
                        <TableRow classNameTh="width-80">{t("components.Volume")}</TableRow>
                        <TableRow classNameTh="width-80">{t("components.Custo")}</TableRow>
                      </tr>
                    </tbody>
                  </Table>
                </TableRow>

                <TableRow classNameTh="width-80">{t("components.Volume Projetado")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
                <TableRow classNameTh="width-80">{t("components.Custo Projetado")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
                <TableRow classNameTh="width-80">{t("components.GVV")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.Preço Sem Markup")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.Volume Vendido")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.Preço Praticado")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.Demanda Sugestão IA")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
                <TableRow classNameTh="width-80">{t("components.Preço Sugerido IA")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
                <TableRow classNameTh="width-80">{t("components.Margem Projetada")}</TableRow>
                <TableRow colSpan={4} classNameTh="width-220 px-0" className="fator_decisao_farol">
                  <Table className="mb-0">
                    <tbody>
                      <tr>
                        <th colSpan={4} className="text-center pb-0">
                          <label className="mr-2"> {t("components.Fatores de Decisão")} </label>
                        </th>
                      </tr>
                      <tr>
                      <TableRow classNameTh="width-55">{t("components.Saz.")}</TableRow>
                      <TableRow classNameTh="width-55">
                        <label dangerouslySetInnerHTML={{ __html: t("components.Tend.") }} />
                      </TableRow>
                      <TableRow classNameTh="width-55">{t("components.Elast.")}</TableRow>
                      <TableRow classNameTh="width-55">
                        <label dangerouslySetInnerHTML={{ __html: t("components.Giro") }} />
                      </TableRow>
                    </tr>
                    </tbody>
                  </Table>
                </TableRow>

                <TableRow classNameTh="width-80">{t("components.Ativar Elasticidade")}</TableRow>
                <TableRow classNameTh="width-80">{t("components.Volume Sugerido Mkt")}</TableRow>
                <TableRow classNameTh="width-80">
                  <label dangerouslySetInnerHTML={{ __html: t("components.Variação IA Vs Mkt #") }} />
                </TableRow>
                <TableRow classNameTh="width-80">{t("components.Preço Sugerido Mkt")}</TableRow>
                <TableRow classNameTh="width-80">
                  <label dangerouslySetInnerHTML={{ __html: t("components.Variação IA Vs Mkt $") }} />
                </TableRow>
                <TableRow classNameTh="width-80">{t("components.Preço Com Imposto")}</TableRow>

              </tr>
            </thead>
            {carregando ?
              <tbody>
                {Array.from({ length: 8 }).map(
                  (_, index) => (
                    <tr className="height-38" key={"skeleton-tr" + index}>
                      {Array.from({ length: 25 }).map((_, index) => (
                        <td key={"skeleton-td" + index}>
                          <Skeleton />
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </tbody> :
              <tbody>
                {(lista.length == 0) &&
                  <tr>
                    <td colSpan={25} className="text-center">
                      {props.carregandoPrimeiraVez ? t("components.Clique em buscar para carregar os dados!") : t("pages.Nenhum registro encontrado!")}
                    </td>
                  </tr>
                }
                {lista.map((item, index) => (
                  !item.oculta &&
                  <tr key={"tr-" + item.id + index} className={"font-size-" + (14 - item.num_nivel * 0)} title={title(item)}>
                    <TabelaTr linha={item} index={index} filtro={props.filtro} salvouEdicao={props.salvouEdicao} />
                  </tr>
                ))}
              </tbody>
            }
          </Table>
        </div>
      </div>
    </div>
  );
}
