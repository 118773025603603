import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { PropsGraphicKpis } from "./1_precoBoiGordoDolar";
import { agruparPorAnoECalcularMedia } from "..";
import { ModeloKpis } from "../../../core/models/kpis";
import LoadingAnimation from "../loadingAnimation";
import { useTranslation } from "react-i18next";

interface Props {}

export default function PrecoCorteMarketShare(props: PropsGraphicKpis) {
  const [geojsonData, setGeojsonData] = useState<any>(null);
  const [lista, setLista] = useState<ModeloKpis[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    let lista = props.listaKpis.filter((kpi) => kpi.des_indicador === "PERC_MARKETSHARE_UF");
    console.log(lista);
    // lista = agruparPorEstadoCalcularMedia(lista);
    // console.log(lista)

    lista = lista.sort((a, b) => a.ini_uf.localeCompare(b.ini_uf));

    setLista(lista);
  }, [props.listaKpis]);

  useEffect(() => {
    fetch("/brazil-states.json") // Caminho correto para o arquivo
      .then((response) => response.json())
      .then((data) => setGeojsonData(data))
      .catch((error) => console.error("Erro ao carregar o GeoJSON:", error));
  }, []);

  // Função para calcular os centroides dos estados
  const getCentroid = (geometry: any): { lat: number; lon: number } => {
    if (!geometry || !geometry.coordinates) return { lat: 0, lon: 0 };

    let latSum = 0;
    let lonSum = 0;
    let count = 0;

    // Suporta múltiplos níveis de coordenadas (ex.: Polygons e MultiPolygons)
    const flattenCoords = (coords: any[]) => {
      coords.forEach((coord) => {
        if (Array.isArray(coord[0])) {
          // Coordenadas aninhadas
          flattenCoords(coord);
        } else {
          // Coordenada simples [lon, lat]
          lonSum += coord[0];
          latSum += coord[1];
          count++;
        }
      });
    };

    flattenCoords(geometry.coordinates);
    return { lat: latSum / count, lon: lonSum / count };
  };

  // Gerar anotações para cada estado
  const annotations =
    geojsonData &&
    geojsonData.features
      .map((feature: any) => {
        const ini_uf = feature.properties.SIGLA; // Ajuste conforme o GeoJSON
        const estadoData = lista.find((item) => item.ini_uf === ini_uf);
        if (!estadoData) return null;

        const { lat, lon } = getCentroid(feature.geometry); // Calcula o centroide do estado

        return {
          x: lon, // Longitude
          y: lat, // Latitude
          text: `${(estadoData.num_indicador * 100).toFixed(0)}%`, // Texto a ser exibido
        };
      })
      .filter(Boolean);

  if (!geojsonData) {
    return (
      <div className="d-flex height-100p justify-content-center align-items-center ">
        <LoadingAnimation brasil={true} />
      </div>
    );
  }

  var data = [
    {
      type: "scattergeo",
      mode: "text",
      text: annotations.map((x: any) => x.text),
      lon: annotations.map((x: any) => x.x),
      lat: annotations.map((x: any) => x.y),
      marker: {
        size: 8,
        line: {
          width: 1,
        },
      },
    },

    {
      type: "choropleth",
      mode: "markers+text",
      locations: lista.map((x) => x.ini_uf),
      locationmode: "geojson-id",
      z: lista.map((x) => x.num_indicador * 100),
      geojson: geojsonData,
      hoverinfo: "location+z",
      zmin: 0,
      zmax: 100,
      colorbar: {
        title: "%",
        ticksuffix: "%",
        tickformat: ".0f",
        len: 0.8, // Comprimento da barra de cor
        thickness: 15, // Espessura da barra de cor
      },
      hovertemplate: "<b>%{location}</b><br>%{z:.1f}%", // Formato do hover
    },
  ];

  var layout = {
    title: {
      text: t("pages.% Market Share por UF"),
      font: {
        size: 12,
      },
    },
    geo: {
      center: { lat: -14.235, lon: -51.925 }, // Centraliza o mapa no Brasil
      lataxis: { range: [-35, 5] }, // Limites de latitude
      lonaxis: { range: [-75, -35] }, // Limites de longitude
      fitbounds: "locations",
      showcoastlines: false,
      showland: false,
      zoom: 3.5,
      visible: false,
    },
    margin: { t: 30, b: 5, l: 30, r: 30 },
  };

  return <Plot data={data as any} layout={layout} useResizeHandler style={{ width: "100%", height: "100%" }} config={{ displayModeBar: props.lg }} />;
}
