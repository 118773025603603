import React, { useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FaRegEye, FaRegEdit, FaTrash } from 'react-icons/fa';
import TableRow from '../../components/TableRow';
import { ModeloCenario } from '../../core/models/cenario';
import PaginacaoTabela from '../../components/PaginacaoTabela';
import { FiltroCenario, RepositorioCenario } from '../../core/repositories/cenarioRepositorio';
import SelecionarBusca from '../../components/SelecionarBusca';
import moment from "moment";
import Skeleton from 'react-loading-skeleton';
import { useAutenticação } from '../../core/contexts/auth';
import { useToast } from '../../core/contexts/toast';

import { formatarPeriodoData } from '../../core/utils/funcoes';
import { useModal } from '../../core/contexts/modalContexto';
import "./index.scss";
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean;
    telaCenario: string;
    flg_ano_mes: boolean;
    onHide: () => void;
    abrirCenario: (cenario: ModeloCenario) => void;
}

export const ModalCenarioTabela: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [filtroCenario, setFiltroCenario] = useState(new FiltroCenario());
    const [listaCenario, setListaCenario] = useState<(ModeloCenario[])>();
    const [listaUsuario, setListaUsuario] = useState<(ModeloCenario[])>([]);
    const [nomeCenario, setNomeCenario] = useState('');
    const [mostrarStatus, setMostrarStatus] = useState(false)

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const { mostrarModal } = useModal();
    const { mostrarToast } = useToast();
    const { usuario } = useAutenticação();

    React.useEffect(() => {
        if (props.show) {
            if (props.telaCenario == "Planta") {
                filtroCenario.flg_tela_planta_cd = true;
                setMostrarStatus(true)
                filtroCenario.des_status = "Em análise"
            } else {
                filtroCenario.flg_tela_planta_cd = false;
                setMostrarStatus(false)
                filtroCenario.des_status = undefined
            }
            filtroCenario.flg_ano_mes = props.flg_ano_mes
            new RepositorioCenario().carregarUsuario(filtroCenario.flg_tela_planta_cd).then((res) => {
                setListaUsuario(res)
            }).catch((error: any) => {
                mostrarToast({
                    titulo: t("pages.Erro!"),
                    mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
                    tipo: "error",
                });
            })
        }
    }, [props.show]);

    React.useEffect(() => {
        if (props.show) {
            buscarCenario();
        }
    }, [props.show, filtroCenario]);

    function handleChangeNomeCenario(event: React.ChangeEvent<HTMLInputElement>) {
        const nomeCenario = event.target.value
        setNomeCenario(nomeCenario);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        //para buscar somente quando o usuario para de digitar
        timerRef.current = setTimeout(() => {
            filtroCenario.des_cenario = nomeCenario
            setFiltroCenario({ ...filtroCenario })
            buscarCenario();
        }, 2000);
    };

    function buscarCenario() {
        setListaCenario(undefined)
        // filtroCenario.cod_usuario = usuario?.cod_usuario;
        new RepositorioCenario().getFilter(filtroCenario).then((res) => {
            if (res.length) {
                filtroCenario.num_total = res[0].num_total || 0;
            }
            res.map((x: ModeloCenario) => { x.flg_editar = (x.cod_usuario == usuario?.cod_usuario) });
            setListaCenario(res)
        }).catch((error) => {
            mostrarToast({
                titulo: t("pages.Erro!"),
                mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
                tipo: "error",
            })
        })
    }

    async function excluirCenario(cenario: ModeloCenario) {
        const subTitulo = `${t("components.Você tem certeza que deseja excluir o cenário")} "${cenario.des_cenario}" ${t("components.do usuário")} "${cenario.des_nome}" ?`
        const modalResultado = await mostrarModal({
            titulo: "Excluir Cenário",
            subTitulo: subTitulo,
            acao: "Excluir"
        })

        if (modalResultado) {
            new RepositorioCenario().excluirCenario(cenario).then((res: boolean) => {
                if (res) {
                    mostrarToast({
                        titulo: t("components.Sucesso!"),
                        mensagem: `"${cenario.des_cenario}" ${t("components.foi excluído!")}`,
                        tipo: "success",
                    });
                } else {
                    mostrarToast({
                        titulo: t("pages.Erro!"),
                        mensagem: t("components.Algo deu errado ao tentar excluir o cenário!"),
                        tipo: "error",
                    });
                }
            }).catch(() => {
                mostrarToast({
                    titulo: t("pages.Erro!"),
                    mensagem: t("components.Algo deu errado ao tentar excluir o cenário!"),
                    tipo: "error",
                });
            })
        }
    }

    // function atualizarFinalizado(valor: string) {
    //     filtroCenario.des_finalizado = valor
    //     buscarCenario();
    // }

    return (
        <Modal onHide={props.onHide} show={props.show} centered size="xl" className='modalCenarioTabela'>
            <Modal.Header closeButton className="pb-0">
                <Modal.Title className='font-size-18 text-primary'>{t("components.Cenários")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={mostrarStatus ? 4 : 6}>
                        <Form.Group controlId="cenarioId" >
                            <Form.Label className="font-size-14 line-height-24 relative mb-0 ">{t("components.Nome do cenário")}</Form.Label>
                            <Form.Control
                                type="text"
                                className='height-40'
                                placeholder={t("components.Digite aqui o nome do cenário")}
                                autoFocus
                                required
                                minLength={3}
                                value={nomeCenario}
                                onChange={handleChangeNomeCenario}
                                autoComplete='off'
                            />
                        </Form.Group>
                    </Col>
                    <Col md={mostrarStatus ? 4 : 6}>
                        <SelecionarBusca
                            titulo={t("components.Usuário")}
                            optionValue="cod_usuario"
                            optionLabel="des_nome"
                            multiple
                            value={filtroCenario.cod_usuario}
                            options={listaUsuario}
                            onChange={(value) => {
                                filtroCenario.cod_usuario = value
                                buscarCenario();
                            }}
                        />
                    </Col>
                    {mostrarStatus && (
                        <Col md={4}>
                            <SelecionarBusca
                                titulo={t("components.Status")}
                                optionValue="des_status"
                                optionLabel="des_status"
                                multiple={false}
                                value={filtroCenario.des_status}
                                options={[{ "des_status": "Em análise" },
                                { "des_status": "Finalizado" },
                                { "des_status": "Aplicado" }]}
                                onChange={(value) => {
                                    filtroCenario.des_status = value
                                    buscarCenario();
                                }}
                            />
                            {/* <Form.Group >
                                <div className="vertical-align ">
                                    <Form.Label className="font-size-14 line-height-24 relative mb-0">
                                        Finalizado
                                    </Form.Label>
                                </div>

                                <ToggleButtonGroup className="width-160 height-46" type="radio" name="finalizado" value={filtroCenario.des_finalizado} >
                                    <ToggleButton key="radio-btn-finalizado-0" id="radio-btn-finalizado-0" className="w-50" type="radio" variant="outline-primary" name="radio-finalizado" value="1" checked={filtroCenario.des_finalizado == "1"}
                                    onChange={(e: any) => {
                                        atualizarFinalizado(e.currentTarget.value)
                                    }}
                                    >
                                        Sim
                                    </ToggleButton>
                                    <ToggleButton key="radio-btn-finalizado-1" id="radio-btn-finalizado-1" className="w-50 text-capitalize" type="radio" variant="outline-primary" name="radio-finalizado" value="0" checked={filtroCenario.des_finalizado == "0"}
                                    onChange={(e: any) => {
                                        atualizarFinalizado(e.currentTarget.value)
                                    }}
                                    >
                                        Não
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </ Form.Group> */}
                        </Col>
                    )}
                </Row>

                <div className='min-height-320'>
                    <Table className="rounded mb-0" hover bordered striped responsive size="sm">
                        <thead >
                            <tr className="table-header">
                                <TableRow classNameTh='width-140'>{t("components.Nome do Cenário")}</TableRow>
                                <TableRow classNameTh='width-140'>{t("components.Usuário")}</TableRow>
                                <TableRow classNameTh='width-100'>{t("components.Periodo Analizado")}</TableRow>
                                <TableRow classNameTh='width-100'>{t("components.Data de Alteração")}</TableRow>
                                <TableRow classNameTh='width-65'>{t("components.Filtros")}</TableRow>
                                <TableRow classNameTh='width-100'>{t("components.Qtd. Produtos")}</TableRow>
                                {mostrarStatus && (
                                    <TableRow classNameTh='width-65'>{t("components.Status")}</TableRow>
                                )}
                                <TableRow classNameTh="width-65 text-center">{t("components.Ação")}</TableRow>
                            </tr>
                        </thead>
                        <tbody>
                            {listaCenario === undefined ? (
                                Array.from({ length: filtroCenario.num_quantidade_pagina }).map(
                                    (_, index) => (
                                        <tr className="height-40" key={"skeleton-tr" + index}>
                                            {Array.from({ length: props.telaCenario == "Planta" ? 8 : 7 }).map((_, index) => (
                                                <td key={"skeleton-td" + index}>
                                                    <Skeleton />
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                            ) : listaCenario.length == 0 ?
                                <tr>
                                    <td colSpan={8} className="text-center">
                                        {t("pages.Nenhum registro encontrado!")}
                                    </td>
                                </tr>
                                : listaCenario?.map((cenario, index) => {
                                    return <tr key={"modalCenarioTabela" + index} >
                                        <td  >{cenario.des_cenario}</td>
                                        <td> {cenario.des_nome}</td>
                                        <td> {formatarPeriodoData(cenario.num_semana_ano ? cenario.num_semana_ano.toString() : cenario.des_ano_mes, !!cenario.des_ano_mes)}  </td>
                                        <td> {moment(cenario.dth_alteracao?.toString()).format("DD/MM/YYYY : HH:mm")} </td>
                                        <td> {cenario.num_filtro}</td>
                                        <td> {cenario.num_de_produtos_alterado_relativo}</td>
                                        {mostrarStatus && (
                                            <td> {cenario.des_status}</td>
                                            // <td> {cenario.flg_finalizado ? "Sim": "Não"}</td>
                                        )}
                                        <td className="text-center">
                                            <Button className="px-1 border-0" variant="outline-primary" size="sm"
                                                onClick={() => {
                                                    props.abrirCenario(cenario)
                                                }}
                                            >
                                                <FaRegEye />
                                            </Button>
                                            <Button className="px-1 border-0" variant="outline-danger" size="sm"
                                                onClick={() => {
                                                    excluirCenario(cenario)
                                                    props.onHide()
                                                }}
                                                disabled={!cenario.flg_editar}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </Table>
                </div>
                <div>
                    <PaginacaoTabela
                        atualizarPagina={(value) => {
                            filtroCenario.num_pagina_atual = value;
                            setFiltroCenario({ ...filtroCenario })
                        }}
                        paginaAtual={filtroCenario.num_pagina_atual}
                        tamanhoPagina={filtroCenario.num_quantidade_pagina}
                        contador={filtroCenario.num_total}
                    />
                </div>

            </Modal.Body>
            <Modal.Footer className="pt-0">
                <Button variant="light" onClick={props.onHide}>
                    {t("components.Fechar")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
