import React, { useState } from "react";
import SelecionarBusca from "../../components/SelecionarBusca";
import { Row, Col, Form } from "react-bootstrap";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import { ModeloBasico } from "../../core/models/basico";
import { ModeloGerencia } from "../../core/models/gerencia";
import { ModeloLinha } from "../../core/models/linha";
import { ModeloProduto } from "../../core/models/produto";
import { FiltroGerencia, RepositorioGerencia } from "../../core/repositories/gerenciaRepositorio";
import { FiltroLinha, RepositorioLinha } from "../../core/repositories/linhaRepositorio";
import { FiltroProduto, RepositorioProduto } from "../../core/repositories/produtoRepositorio";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { useToast } from "../../core/contexts/toast";
import { ModeloCanal } from "../../core/models/canal";
import { ModeloDiretoria } from "../../core/models/diretoria";
import { RepositorioCanal } from "../../core/repositories/canalRepositorio";
import { FiltroDiretoria, RepositorioDiretoria } from "../../core/repositories/diretoriaRepositorio";
import SwitchItem from "../../components/SwitchItem";
import { ModeloCenario } from "../../core/models/cenario";

import { FiltroOtimizacaoReceita } from "../../core/repositories/otimizacaoReceitaRepositorio";
import { useTranslation } from "react-i18next";
import { TipoTela } from "../../core/config/tipoTela";
import { useAutenticação } from "../../core/contexts/auth";
import { otimizacaoReceitaContext } from "../../core/contexts/otimizacaoReceitaContexto";

interface Props {
  titulo: string;
  filtro: FiltroOtimizacaoReceita;
  permissaoEditar: boolean;
  atualizarFiltro: (filtro: FiltroOtimizacaoReceita) => void;
  filtrarDados: (filtro: FiltroOtimizacaoReceita) => void;
  carregarPeriodo: () => void;
}

export default function FiltroOR(props: Props) {
  const { mostrarToast } = useToast();
  const { t } = useTranslation();

  const { lista, atualizarFiltroContexto, filtroNoContexto } = otimizacaoReceitaContext();
  const { listaAcabamento, listaBandeira, listaCluster, listaDivisaoComercial, listaFamiliaSubgrupo, listaTemperatura, listaTipoProduto, listaUFDestino, listaPeriodoSemana, listaPeriodoMes, periodoCarregado, } = filtroContexto()

  const [listaCanal, setListaCanal] = useState<ModeloCanal[]>([]);
  const [listaDiretoria, setListaDiretoria] = useState<ModeloDiretoria[]>([]);
  const [listaGerencia, setListaGerencia] = useState<ModeloGerencia[]>([]);
  const [listaLinha, setListaLinha] = useState<ModeloLinha[]>([]);
  const [listaProduto, setListaProduto] = useState<ModeloProduto[]>([]);
  const [listaPeriodo, setListaPeriodo] = useState<ModeloBasico[]>([]);
  const { usuario } = useAutenticação();


  // React.useEffect(() => {
  //   carregarPeriodo()
  // }, [periodoCarregado, listaPeriodoMes, listaPeriodoSemana])

  React.useEffect(() => {
    if (props.filtro.des_periodo_analisado) {
      props.atualizarFiltro(props.filtro)
    }
  }, [props.filtro.des_periodo_analisado])

  React.useEffect(() => {
    try {
      new RepositorioCanal().getOtimizacaoReceitaAll().then((res) => {
        setListaCanal(res);
      });

      carregarDiretoria();
      carregarGerencia();
      carregarLinha();
      carregarProduto();
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }, []);

  function carregarGerencia() {
    props.filtro.cod_gerencia = ""
    const filtro = new FiltroGerencia();
    filtro.cod_canal = props.filtro.cod_canal;
    filtro.cod_diretoria = props.filtro.cod_diretoria;
    new RepositorioGerencia().getFilter(filtro).then((res) => {
      setListaGerencia(res);
    });
  }

  function carregarDiretoria() {
    props.filtro.cod_diretoria = ""
    const filtro = new FiltroDiretoria();
    filtro.cod_canal = props.filtro.cod_canal;
    new RepositorioDiretoria().getFilter(filtro).then((res) => {
      setListaDiretoria(res);
    });
  }

  function carregarProduto() {
    props.filtro.cod_produto = ""

    const filtroProduto = new FiltroProduto();
    filtroProduto.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo;
    filtroProduto.cod_linha = props.filtro.cod_linha;
    filtroProduto.flg_mi = true
    new RepositorioProduto().getFilter(filtroProduto).then((res) => {
      setListaProduto(res);
    });
  }

  function carregarLinha() {
    props.filtro.cod_linha = ""

    const filtroLinha = new FiltroLinha();
    filtroLinha.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo;

    new RepositorioLinha().getFilter(filtroLinha).then((res) => {
      setListaLinha(res);
    });
  }

  function atualizarAnoMes(valor: any) {
    props.filtro.flg_ano_mes = valor;
    props.atualizarFiltro(props.filtro);
    props.carregarPeriodo();
  }

  // function carregarPeriodo() {
  //   if (periodoCarregado && listaPeriodoMes && listaPeriodoSemana) {
  //     var aux = listaPeriodoSemana
  //     aux = [listaPeriodoSemana[0]]
  //     setListaPeriodo(aux);
  //     atualizarPeriodoPadrao(aux);
  //   }
  // }




  return (
    <div className="stick">
      <FiltroCabecalho
        titulo={props.titulo}
        filtro={props.filtro}
        atualizarFiltro={props.atualizarFiltro}
        atualizarAnoMes={atualizarAnoMes}
        lista={lista}
        ocultarTitulo={true}
        ocultarResultado={true}
        cenario={(usuario?.cod_usuario === "erielcioimamura-mtz" || usuario?.cod_usuario === "rafaeljunior-mtz") ? new ModeloCenario() : undefined}
        permissaoEditar={props.permissaoEditar}
        tipoTela={TipoTela.OTIMIZACAO_RECEITA}
      >
        <>
          <Row className="row-cols-2 row-cols-lg-6">
            {/* <Col>
              <SelecionarBusca
                titulo="Período Analisado"
                optionValue="codigo"
                optionLabel="descricao"
                multiple={false}
                className="flex-fill"
                value={props.filtro.des_periodo_analisado}
                options={listaPeriodo}
                onChange={(value) => {
                  props.filtro.des_periodo_analisado = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col> */}

            <Col>
              <SelecionarBusca
                titulo={t("pages.Canal de Venda")}
                optionLabel="des_canal"
                optionValue="cod_canal"
                className="flex-fill"
                multiple
                value={props.filtro.cod_canal}
                options={listaCanal}
                onChange={(value) => {
                  props.filtro.cod_canal = value;
                  carregarGerencia();
                  carregarDiretoria();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Diretoria")}
                optionLabel="des_diretoria"
                optionValue="cod_diretoria"
                className="flex-fill"
                multiple
                value={props.filtro.cod_diretoria}
                options={listaDiretoria}
                onChange={(value) => {
                  props.filtro.cod_diretoria = value
                  carregarGerencia();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Regional")}
                optionLabel="des_gerencia"
                optionValue="cod_gerencia"
                className="flex-fill"
                multiple
                value={props.filtro.cod_gerencia}
                options={listaGerencia}
                onChange={(value) => {
                  props.filtro.cod_gerencia = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Divisão Comercial")}
                optionLabel="des_divisao_comercial"
                optionValue="cod_divisao_comercial"
                className="flex-fill"
                multiple
                value={props.filtro.cod_divisao_comercial}
                options={listaDivisaoComercial}
                onChange={(value) => {
                  props.filtro.cod_divisao_comercial = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            {/* <Col>
              <SelecionarBusca
                titulo="Mercado"
                optionLabel="des_mercado"
                optionValue="cod_mercado"
                className="flex-fill"
                multiple
                value={props.filtro.cod_mercado}
                options={listaMercado}
                onChange={(value) => {
                  props.filtro.cod_mercado = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col> */}

            <Col>
              <SelecionarBusca
                titulo={t("pages.Tipo de Produto")}
                optionLabel="des_tipo_produto"
                optionValue="cod_tipo_produto"
                className="flex-fill"
                multiple
                value={props.filtro.cod_tipo_produto}
                options={listaTipoProduto}
                onChange={(value) => {
                  props.filtro.cod_tipo_produto = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Família Subgrupo")}
                optionLabel="des_familia_subgrupo"
                optionValue="cod_familia_subgrupo"
                multiple
                value={props.filtro.cod_familia_subgrupo}
                options={listaFamiliaSubgrupo}
                onChange={(value) => {
                  props.filtro.cod_familia_subgrupo = value
                  carregarProduto();
                  carregarLinha();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Linha")}
                optionLabel="des_linha"
                optionValue="cod_linha"
                multiple
                value={props.filtro.cod_linha}
                options={listaLinha}
                onChange={(value) => {
                  props.filtro.cod_linha = value
                  carregarProduto();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Produto")}
                optionLabel="des_produto"
                optionValue="cod_produto"
                panelClassName="min-width-600"
                multiple
                logicaFiltroCodigo
                value={props.filtro.cod_produto}
                options={listaProduto}
                onChange={(value) => {
                  props.filtro.cod_produto = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            {/* <Col>
              <SelecionarBusca
                titulo="Acabamento"
                optionLabel="des_acabamento"
                optionValue="cod_acabamento"
                multiple
                value={props.filtro.cod_acabamento}
                options={listaAcabamento}
                onChange={(value) => {
                  props.filtro.cod_acabamento = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo="Temperatura"
                optionLabel="des_temperatura"
                optionValue="cod_temperatura"
                multiple
                options={listaTemperatura}
                value={props.filtro.cod_temperatura}
                onChange={(value) => {
                  props.filtro.cod_temperatura = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col> */}

            <Col>
              <SelecionarBusca
                titulo={t("pages.Cluster")}
                optionLabel="des_cluster_label"
                optionValue="cod_cluster"
                multiple
                value={props.filtro.cod_cluster}
                options={listaCluster}
                onChange={(value) => {
                  props.filtro.cod_cluster = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Bandeira")}
                optionLabel="des_bandeira"
                optionValue="cod_bandeira"
                multiple
                value={props.filtro.cod_bandeira}
                options={listaBandeira}
                onChange={(value) => {
                  props.filtro.cod_bandeira = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.UF Destino")}
                optionLabel="ini_uf_destino"
                optionValue="ini_uf_destino"
                multiple
                value={props.filtro.ini_uf_destino}
                options={listaUFDestino}
                onChange={(value) => {
                  props.filtro.ini_uf_destino = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
            <Col>
              <div className="d-flex">


                <Form.Group className="mb-3 mr-3 SelectSearch me-2">
                  <Form.Label className="font-size-14">{t("pages.Capitães")}</Form.Label>
                  <br />
                  <SwitchItem
                    checked={props.filtro.flg_capitaes}
                    onChange={(checked) => {
                      props.filtro.flg_capitaes = checked;
                      props.atualizarFiltro(props.filtro)

                    }}
                  ></SwitchItem>
                </Form.Group>
                <Form.Group className="mb-3 mr-3 SelectSearch">
                  <Form.Label className="font-size-14">{t("pages.Fatores")}</Form.Label>
                  <br />
                  <SwitchItem
                    checked={props.filtro.flg_fatores}
                    onChange={(checked) => {
                      props.filtro.flg_fatores = checked;
                      filtroNoContexto.flg_fatores = checked;
                      atualizarFiltroContexto(filtroNoContexto)
                      props.atualizarFiltro(props.filtro)

                    }}
                  ></SwitchItem>
                </Form.Group>
              </div>

            </Col>

            <Col>



            </Col>
          </Row>
        </>
      </FiltroCabecalho>
    </div>
  );
}



