import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloCanal } from "../models/canal";
import { Repositorio } from "./repositorio";

export class FiltroCanal extends FiltroBasico<ModeloCanal> {}

export class RepositorioCanal extends Repositorio<ModeloCanal> {
  constructor() {
    super(ConstanteGlobal.CANAL);
  }
  public async getOtimizacaoReceitaAll(): Promise<ModeloCanal[]> {
    return this.api.get(`${this.nomeRepositorio}_todos_get_all`).then((res) => {
      return res.data;
    });
  }
}
