import * as React from "react";
import { Badge, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import "./index.scss";
import { otimizacaoReceitaContext } from "../../core/contexts/otimizacaoReceitaContexto";
import { formatarDecimal, formatarPorcentagem } from "../../core/utils/funcoes";
import { useTranslation } from "react-i18next";


interface Props {
}

export const IndexsKPI: React.FC<Props> = (props) => {

  const { indexsKPI, filtroNoContexto } = otimizacaoReceitaContext();
  const { t } = useTranslation();


  const data = [
    {
      name: t("pages.Arroba"),
      // obj:undefined,
      obj: indexsKPI.find(x => x.des_kpi === "arroba"),

    },
    {
      name: t("pages.Dólar"),
      obj: indexsKPI.find(x => x.des_kpi === "dolar"),

    },
    {
      name: t("pages.Frango"),
      obj: indexsKPI.find(x => x.des_kpi === "frango"),
    },
    {
      name: t("pages.Milho"),
      obj: indexsKPI.find(x => x.des_kpi === "milho"),
    },
    {
      name: t("pages.Ovo"),
      obj: indexsKPI.find(x => x.des_kpi === "ovo"),
    },
    {
      name: t("pages.Soja"),
      obj: indexsKPI.find(x => x.des_kpi === "soja"),
    },
    {
      name: t("pages.Suíno"),
      obj: indexsKPI.find(x => x.des_kpi === "suino"),
    },
  ];


  return (
    <div className="d-flex flex-wrap justify-content-end align-items-end ">
      {data.map((item) => (
        <OverlayTrigger key={item.name}
          overlay={
            <Tooltip id={"button-tooltip" + item.name} >
              <div className="text-start">
                <b className="font-size-14">{item.name}</b><br />
                <b>{filtroNoContexto.flg_ano_mes ? t("components.M") : t("components.S")}-2:</b> {formatarDecimal(item.obj?.num_valor_lag2, true).replace("R$", t("components.R$"))}<br />
                <b>{filtroNoContexto.flg_ano_mes ? t("components.M") : t("components.S")}-1:</b> {formatarDecimal(item.obj?.num_valor_lag1, true).replace("R$", t("components.R$"))}<br />
                <b>Δ {t("components.R$")}:</b> {formatarDecimal(item.obj?.num_diff_abs, true).replace("R$", t("components.R$"))}<br />
                <b>Δ:</b> {formatarPorcentagem(item.obj?.num_diff_perc)}<br />

              </div>
            </Tooltip>
          }>
          <Badge key={item.name} pill bg={"light"} className={"mx-1 height-20 position-relative " + (item.obj == undefined ? "padding-right-20" : (item.obj.num_diff_perc > 0 ? `text-success` : 'text-danger'))}>
            {item.name}: {item.obj == undefined ?
              <div className="centro" >
                <Spinner size="sm" className="height-10 width-10" />
              </div>
              : formatarPorcentagem(item.obj.num_diff_perc)}
          </Badge>
        </OverlayTrigger>

      ))}
    </div>
  );
};
