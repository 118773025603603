import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { atualizaTamanhoTabela, formatarAtualizarPeriodoPadrao } from "../../core/utils/funcoes";
import { useModal } from "../../core/contexts/modalContexto";
import { FiltroOtimizacaoReceita, RepositorioOtimizacaoReceita } from "../../core/repositories/otimizacaoReceitaRepositorio";
import TabelaOtimizacaoReceita from "../../components/TabelaOtimizacaoReceita";
import FiltroOR from "./filtro";
import { BiCloudDownload } from "react-icons/bi";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { NavLink } from "react-router-dom";
import "./index.scss";
import { IndexsKPI } from "./kpis";
import { ModeloBasico } from "../../core/models/basico";
import { useAutenticação } from "../../core/contexts/auth";
import { otimizacaoReceitaContext } from "../../core/contexts/otimizacaoReceitaContexto";
import { useTranslation } from "react-i18next";

var contador: number = 0;

export default function OtimizacaoReceita() {
  const bodyRef = useRef(null);

  const { lista, carregarLista, atualizarInterface, atualizarFiltroContexto, filtroNoContexto, setCarregando } = otimizacaoReceitaContext();

  const { periodoCarregado, listaPeriodoMes, listaPeriodoSemana } = filtroContexto();

  const { mostrarToast } = useToast();
  const { mostrarModal } = useModal();
  const { usuario } = useAutenticação();

  const [filtro, setFiltro] = useState(new FiltroOtimizacaoReceita());
  // const [listaResultadoIA, setListaResultadoIA] = useState<ModeloOtimizacaoReceitaResultado[]>([]);
  // const [listaFatorDefinicao, setListaFatorDefinicao] = useState<ModeloOtimizacaoReceitaResultado[]>([]);
  // const [listaTabela, setListaTabela] = useState(new ModeloOtimizacaoReceitaListaTabela());

  const [carregarBtnExportar, setCarregarBtnExportar] = useState(false);

  const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
  const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);
  const [listaPeriodo, setListaPeriodo] = useState<ModeloBasico[]>([]);
  const _otimizacaoReceita = new RepositorioOtimizacaoReceita();

  const { t } = useTranslation();

  React.useEffect(() => {
    // if (permissao) {
    //   const possuiPermissaoEditar = permissao.some(
    //     (item) => item.codValor === TipoPermissao.otimizacao_receita && item.codRecurso === TipoPermissao.EDITAR
    //   ) || window.location.hostname === 'localhost';
    //   const possuiPermissaoExcluir = permissao.some(
    //     (item) => item.codValor === TipoPermissao.otimizacao_receita && item.codRecurso === TipoPermissao.EXCLUIR
    //   ) || window.location.hostname === 'localhost';

    //   setPermissaoEditar(possuiPermissaoEditar)
    //   setPermissaoExcluir(possuiPermissaoExcluir)
    // }

    // Seu código a ser executado quando a altura da página mudar para atualizar o tamanho da tabela
    const observer = new MutationObserver(() => {
      atualizaTamanhoTabela();
    });
    if (bodyRef.current) {
      observer.observe(bodyRef.current, { attributes: true, childList: true, subtree: true });
    }
    return () => {
      observer.disconnect();
    };
  });

  React.useEffect(() => {
    carregarPeriodo();
  }, [periodoCarregado, listaPeriodoMes, listaPeriodoSemana]);
  // para toda vez que o usuario atualizar algum atributo da lista atualizar as qts no cenario

  function carregarPeriodo() {
    if (periodoCarregado && listaPeriodoMes && listaPeriodoSemana) {
      var aux = filtro.flg_ano_mes ? listaPeriodoMes : listaPeriodoSemana;
      setListaPeriodo(aux);
      atualizarPeriodoPadrao(aux);
    }
  }

  async function atualizarPeriodoPadrao(lista: ModeloBasico[], filtroAux = filtro) {
    setTimeout(() => {
      atualizarFiltro(formatarAtualizarPeriodoPadrao(filtroAux, lista));
    }, 500);
  }

  async function carregarDados(filtro: FiltroOtimizacaoReceita) {
    try {
      // if (usuario) {
      //   await new RepositorioOtimizacaoReceitaEdicao().delete(usuario.cod_usuario)
      // }
      const _contador = contador++;
      if (_contador === contador - 1) {
        await carregarLista(filtro);

        atualizarInterface();
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }

  function atualizarFiltro(novoFiltro: FiltroOtimizacaoReceita) {
    setFiltro({ ...novoFiltro });
  }

  async function filtrarDados(novoFiltro: FiltroOtimizacaoReceita) {
    setCarregando(true);
    // limparLista();
    novoFiltro.cod_usuario = usuario?.cod_usuario;
    novoFiltro.flg_carregar_painel = true;
    novoFiltro.flg_aberto = false;

    setFiltro({ ...novoFiltro });
    atualizarFiltroContexto({ ...novoFiltro });

    carregarDados(novoFiltro);
    // carregarPainel(novoFiltro);
  }

  // function exportarEmpresa(filtro = filtroNoContexto) {
  //   setCarregarBtnExportar(true);

  //   _otimizacaoReceita.baixarCsv(filtro).then((req) => {
  //     var blob = new Blob([req.data], {
  //       type: req.headers["content-type"],
  //     });
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = `otimizacao_receita_${new Date().toISOString()}.xlsx`;
  //     link.click();

  //     setCarregarBtnExportar(false);
  //   }).catch(() => {
  //     setCarregarBtnExportar(false);
  //     mostrarToast({

  //       mensagem: TipoMensagem.ERRO_TENTE_NOVAMENTE,
  //       tipo: "error",
  //     });
  //   });
  // }

  function exportarCSV(filtro = filtroNoContexto) {
    setCarregarBtnExportar(true);

    _otimizacaoReceita
      .baixarCsv(filtro)
      .then((req) => {
        var blob = new Blob([req.data], {
          type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${t("components.Otimização de Receita")}_${filtro.num_nivel == 3 ? "empresa_" : ""}${new Date().toISOString()}.csv`;
        link.click();

        setCarregarBtnExportar(false);
      })
      .catch(() => {
        setCarregarBtnExportar(false);
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }
  function exportarCSV2(filtro = filtroNoContexto) {
    setCarregarBtnExportar(true);

    _otimizacaoReceita
      .baixarCsv2(filtro)
      .then((req) => {
        const decoder = new TextDecoder("utf-8");
        const csvText = decoder.decode(req.data);
        const blob = new Blob(["\uFEFF" + csvText], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${t("components.Otimização de Receita")}_2_${new Date().toISOString()}.csv`;
        link.click();

        setCarregarBtnExportar(false);
      })
      .catch(() => {
        setCarregarBtnExportar(false);
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }
  // function exportarSkuBandeira() {
  //   setCarregarBtnExportar(true);

  //   _otimizacaoReceita.baixarSkuBandeira(filtroNoContexto).then((req) => {
  //     var blob = new Blob([req.data], {
  //       type: req.headers["content-type"],
  //     });
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = `otimizacao_receita_sku_bandeira_${new Date().toISOString()}.xlsx`;
  //     link.click();

  //     setCarregarBtnExportar(false);
  //   }).catch(() => {
  //     setCarregarBtnExportar(false);
  //     mostrarToast({

  //       mensagem: TipoMensagem.ERRO_TENTE_NOVAMENTE,
  //       tipo: "error",
  //     });
  //   });
  // }

  function limparFiltro() {
    const _filtro = new FiltroOtimizacaoReceita();
    _filtro.flg_aberto = filtro.flg_aberto;
    _filtro.flg_mostrar_resultado = filtro.flg_mostrar_resultado;
    if (listaPeriodoSemana && listaPeriodoSemana.length) {
      _filtro.des_periodo_analisado = listaPeriodoSemana[0].codigo;
    }
    atualizarFiltro(_filtro);
    atualizarPeriodoPadrao(listaPeriodo, _filtro);
  }

  return (
    <div className="otimizacao-receita" ref={bodyRef}>
      <FiltroOR
        titulo={t("components.Otimização de Receita")}
        filtro={filtro}
        atualizarFiltro={atualizarFiltro}
        permissaoEditar={permissaoEditar}
        filtrarDados={filtrarDados}
        // listaTabela={listaTabela}
        // setListaTabela={setListaTabela}
        carregarPeriodo={carregarPeriodo}
      />

      <div className="mesma-linha my-1">
        <div>
          <Button className="me-1" variant="outline-primary" size="sm" onClick={limparFiltro}>
            {t("pages.Limpar")}
          </Button>

          <Button
            disabled={lista.length == 0 || carregarBtnExportar}
            className="me-1"
            onClick={() => {
              exportarCSV();
            }}
            size="sm"
            variant="outline-dark"
          >
            {carregarBtnExportar ? <Spinner animation="border" size="sm" className="me-1" /> : <BiCloudDownload className="me-1" />}

            {t("pages.Exportar")}
          </Button>
        </div>
        <div className="d-flex">
          {/* <BotaoOtimizacaoReceita filtro={filtro} atualizarFiltro={() => { atualizarFiltro(filtro) }} /> */}
          <IndexsKPI />
          <NavLink className="btn btn-sm ms-2 btn-outline-dark" to="/MlPricing/kpis" target="_blank">
            KPIs
          </NavLink>

          {/* <Button as={NavLink} className="ms-1" variant="outline-primary" size="sm" to="/MlPricing/kpis" >
            KPIs
          </Button> */}
          <Button
            className="ms-1"
            variant="primary"
            size="sm"
            onClick={() => {
              filtrarDados(filtro);
            }}
          >
            {t("pages.Buscar")}
          </Button>
        </div>
      </div>

      <div className="table-100" id="tabela">
        <TabelaOtimizacaoReceita
          filtro={filtroNoContexto}
          filtrarDados={filtrarDados}
          exportarDados={(filtroAux) => exportarCSV({ ...filtroAux, flg_granular: true })}
        />
      </div>
    </div>
  );
}
