import React, { useState } from "react";
import SelecionarBusca from "../../components/SelecionarBusca";
import { Row, Col } from "react-bootstrap";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import { ModeloGerencia } from "../../core/models/gerencia";
import { ModeloLinha } from "../../core/models/linha";
import { ModeloProduto } from "../../core/models/produto";
import { FiltroGerencia, RepositorioGerencia } from "../../core/repositories/gerenciaRepositorio";
import { FiltroLinha, RepositorioLinha } from "../../core/repositories/linhaRepositorio";
import { FiltroProduto, RepositorioProduto } from "../../core/repositories/produtoRepositorio";
import { filtroContexto } from "../../core/contexts/filtroContexto";

import { useToast } from "../../core/contexts/toast";
import { FiltroSazonalidade } from "../../core/repositories/sazonalidadeRepositorio";
import { sazonalidadeContexto } from "../../core/contexts/sazonalidadeContexto";
import { useTranslation } from "react-i18next";
import { TipoTela } from "../../core/config/tipoTela";

interface Props {
  titulo: string;
  filtro: FiltroSazonalidade;
  permissaoEditar: boolean;
  atualizarFiltro: (filtro: FiltroSazonalidade) => void;
  filtrarDados: (filtro: FiltroSazonalidade) => void;
}

export default function FiltroPI(props: Props) {
  const { t } = useTranslation();
  const { mostrarToast } = useToast();

  const { lista } = sazonalidadeContexto();
  const { listaAcabamento, listaFamiliaSubgrupo, listaMercado, listaTemperatura, listaTipoProduto } = filtroContexto()

  const [listaGerencia, setListaGerencia] = useState<ModeloGerencia[]>([]);
  const [listaLinha, setListaLinha] = useState<ModeloLinha[]>([]);
  const [listaProduto, setListaProduto] = useState<ModeloProduto[]>([]);

  React.useEffect(() => {
    try {
      carregarGerencia();
      carregarLinha();
      carregarProduto();
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }, []);

  function carregarGerencia() {
    props.filtro.cod_gerencia = ""
    const filtro = new FiltroGerencia();
    filtro.cod_canal = "1";
    new RepositorioGerencia().getFilter(filtro).then((res) => {
      setListaGerencia(res);
    });
  }

  function carregarProduto() {
    props.filtro.cod_produto_capitao = ""
    const filtroProduto = new FiltroProduto();
    filtroProduto.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo_produto_capitao;
    filtroProduto.cod_linha = props.filtro.cod_linha_produto_capitao;
    new RepositorioProduto().getFilter(filtroProduto).then((res) => {
      setListaProduto(res);
    });
  }

  function carregarLinha() {
    props.filtro.cod_linha_produto_capitao = ""
    const filtroLinha = new FiltroLinha();
    filtroLinha.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo_produto_capitao;

    new RepositorioLinha().getFilter(filtroLinha).then((res) => {
      setListaLinha(res);
    });
  }

  return (
    <div className="stick">
      <FiltroCabecalho
        titulo={props.titulo}
        filtro={props.filtro}
        atualizarFiltro={props.atualizarFiltro}
        lista={lista}
        tipoTela={TipoTela.SAZONALIDADE}
      >
        <>
          <Row className="row-cols-2 row-cols-lg-7">

            <Col>
              <SelecionarBusca
                titulo={t("pages.Família Subgrupo")}
                optionLabel="des_familia_subgrupo"
                optionValue="cod_familia_subgrupo"
                multiple
                value={props.filtro.cod_familia_subgrupo_produto_capitao}
                options={listaFamiliaSubgrupo}
                onChange={(value) => {
                  props.filtro.cod_familia_subgrupo_produto_capitao = value
                  carregarProduto();
                  carregarLinha();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Linha")}
                optionLabel="des_linha"
                optionValue="cod_linha"
                multiple
                value={props.filtro.cod_linha_produto_capitao}
                options={listaLinha}
                onChange={(value) => {
                  props.filtro.cod_linha_produto_capitao = value
                  carregarProduto();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Produto")}
                optionLabel="des_produto"
                optionValue="cod_produto"
                panelClassName="min-width-600"
                multiple
                logicaFiltroCodigo
                value={props.filtro.cod_produto_capitao}
                options={listaProduto}
                onChange={(value) => {
                  props.filtro.cod_produto_capitao = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Regional")}
                optionLabel="des_gerencia"
                optionValue="cod_gerencia"
                className="flex-fill"
                multiple
                value={props.filtro.cod_gerencia}
                options={listaGerencia}
                onChange={(value) => {
                  props.filtro.cod_gerencia = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>


             <Col>
              <SelecionarBusca
                titulo={t("pages.Mercado")}
                optionLabel="des_mercado"
                optionValue="cod_mercado"
                className="flex-fill"
                multiple
                value={props.filtro.cod_mercado}
                options={listaMercado}
                onChange={(value) => {
                  props.filtro.cod_mercado = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

           
          </Row>
        </>
      </FiltroCabecalho>
    </div>
  );
}


