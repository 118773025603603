export enum TipoInfo {
    Granularidade = "Os níveis dos dados estão sendo apresentados na seguinte ordem: ",
    PlanoProducao = "Produção que consta no S&OP no nível SKU (em toneladas).",
    PlanoProducaoM0 = "Produção que consta no S&OP do mês atual no nível SKU (em toneladas).",
    PlanoProducaoM1 = "Produção que consta no S&OP do mês seguinte no nível SKU (em toneladas).",
    PlanoProducaoS0 = "Quantidade de produção planejada para a semana atual.",
    PlanoProducaoVariacao = "Variação do S&OP (M.1 x M.0)",
    Variacao = "Diferença percentual entre dois períodos.",
    SaldoEstoqueInicial = "Quantidade de produtos em estoque no início do período analisado.",
    VolumeVendido = "Volume de pedidos referentes aos contratos lançados no mês vigente (em toneladas).</br> Não consta a modalidade de frete Aéreo (FCA).",
    SaldoAVender = "Saldo calculado com base no plano de produção e no estoque (em toneladas).",
    PrecoPraticado = "Preço CFR dos contratos lançados no mês vigente (Dolarizado).</br> Não consta a modalidade de frete Aéreo (FCA).",
    VolumeCarteira = "Volume dos contratos em carteira liberada (em toneladas).</br> Não consta a modalidade de frete Aéreo (FCA).",
    PrecoCarteira = "Preço CFR dos contratos em carteira (Dolarizado).</br> Não consta a modalidade de frete Aéreo (FCA).",
    PrecoCIF = "Custo total de uma mercadoria incluindo o custo do produto, o seguro e o frete até o porto de destino.",
    DemandaSugestaoIA = "Previsão de demanda calculada por um modelo de Inteligência Artificial.",
    DemandaSugestaoIAM1 = "Previsão de demanda para o próximo mês gerada por um modelo de Inteligência Artificial.",
    PrecoSugeridoIA = "Preço sugerido por um modelo de Inteligência Artificial.",
    PrecoSugeridoIAM1 = "Preço de venda sugerido para o próximo mês gerado por um modelo de Inteligência Artificial.",
    FatoresDecisao = "As métricas utilizadas pelo modelo para estimar a previsão de demanda e determinar os preços.",
    Sazonalidade = "Sazonalidade de Preços: descreve padrões recorrentes ou cíclicos que se manifestam em intervalos regulares, como variações mensais ou sazonais.",
    Tendencia = "Tendência de Preços: refere-se ao comportamento de longo prazo da série de dados, indicando a direção predominante ao longo do tempo, como crescimento, queda ou estabilidade.",
    Elasticidade = "Sensibilidade elástica: refere-se à medida da variação da quantidade demandada de um produto em função das alterações em seu preço.",
    Giro = "Taxa de rotação de estoque, indicando a velocidade com que os produtos são vendidos e substituídos.",
    AtivarElasticidade = "Opção para ativar ou desativar o uso da elasticidade de preço nas análises.",
    VolumeSugeridoFriboiM1 = "Quantidade de produção sugerida para o próximo mês pela Friboi.",
    VariacaoIAVsFriboi = "Diferença percentual entre os valores sugeridos pela Inteligência Artificial e os valores sugeridos pela Friboi.",
    PrecoSugeridoFriboiM1 = "Preço de venda sugerido para o próximo mês pela Friboi.",
    VariacaoPrecoIAVsFriboi = "Diferença percentual entre os preços sugeridos pela Inteligência Artificial e os preços sugeridos pela Friboi.",
    PrecoSugeridoFriboiS1 = "Preço de venda sugerido para a próxima semana pela Friboi.",
    Carteira= "Todos os contratos em carteira liberada.",
    Venda = "Contratos lançados no mês vigente ",
  
    CestaProduto = "Cesta de Produto",
    Canibalizacao = "Canibalização",
    PrevisaoDemanda = "Previsão de Demanda",
    CicloPecuario = "Ciclo Pecuário",
    PrecificacaoGado = "Preço do Gado",
  
    SazonalidadePreco = "Sazonalidade de Preço",
    SazonalidadeTendencia = "Sazonalidade de Tendência",  
    SazonalidadeDemanda = "Sazonalidade de Demanda",
    ArrobaBoi = "Arroba do Boi",
    PrecoDesafio = "Preço Desafio",
    PrecoTipoEntrega = "Tipo de Entrega",
    ElasticidadePreco = "Elasticidade de Preço",
    CestaProdutos = "Cesta de Produtos",
    CanibalizacaoProdutos = "Canibalização de Produtos",
    PrevisaoDemandaProdutos = "Previsão de Demanda de Produtos",
    CicloPecuarioProdutos = "Ciclo Pecuário de Produtos",
    PrecificacaoGadoProdutos = "Preço do Gado de Produtos",
    ElasticidadePrecoProdutos = "Elasticidade de Preço de Produtos",
    CestaProdutosFamilia = "Cesta de Produtos por Família",
  
    PrecoLista = "Preço de régua de um produto, definido antes de qualquer desconto, negociação ou ajuste.",
    VendaS1Volume= "Peso de líquido estimado referentes aos pedidos lançados na semana anterior (em Kg)",
    VendaS1Preco = "Preço praticado referentes aos pedidos lançados na semana anterior.",
    VendaS1Faturamento = "Total das receitas projetadas pelas vendas de produtos em semana anterior.  Faturamento = Preço praticado x Volume vendido",
    VendaIAS1Volume = "Demanda calculada pelo modelo de inteligencia artificial para próxima semana",
    VendaIAS1Preco = "Preço sugerido pelo modelo de inteligencia artificial para próxima semana",
    VendaIAS1Faturamento = "Total das receitas projetadas pelas vendas de produtos na próxima semana.  Potencial de Faturamento = Volume x Preço calculado pelo modelo",
    VendaIA = "Modelo de IA versão 1 para Planta Industrial e CD",
    VendaV2CD = "Modelo de IA versão 2, projetado para precificar entrega direta",
    VendaV2LojaaLoja = "Modelo de IA versão 2, projetado para precificar o CDGC (Centro de distribuição grandes contas)",
    PreçoPartidaV2 = "Preço inicial utilizado no cálculo do preço IA V2 Entrega Direta e CDGC"
  
  }