import * as React from "react";
import { Badge, Button, Form, Modal, OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useToast } from "../../core/contexts/toast";
import { AG_GRID_LOCALE_BR } from "../../core/config/AgGridReact";
import moment from "moment";
import "./index.scss";
import { ModeloOtimizacaoReceita, ModeloOtimizacaoReceitaFatoresDecisao } from "../../core/models/otimizacaoReceita";
import { FiltroOtimizacaoReceita, RepositorioOtimizacaoReceita } from "../../core/repositories/otimizacaoReceitaRepositorio";
import { formatLargeNumber, formatarDecimal, formatarPorcentagem } from "../../core/utils/funcoes";
import { TipoFormatacao } from "../../core/config/tipoFormatacao";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import tabela from '../../assets/animation/table.json';

import { Player } from "@lottiefiles/react-lottie-player";
import { BiInfoCircle } from "react-icons/bi";
import FatorDecisaoOtimizacao from "../FatorDecisaoOtimizacao";
import { IndexsKPI } from "../../pages/OtimizacaoReceita/kpis";
import SwitchItem from "../SwitchItem";
import { useAutenticação } from "../../core/contexts/auth";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";

interface Props {
  show: boolean;
  data?: ModeloOtimizacaoReceita;
  filtro: FiltroOtimizacaoReceita;
  fatores: ModeloOtimizacaoReceitaFatoresDecisao;
  onHide: () => void;
  exportarDados: () => void;
}

export const ModalBandeira: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(true);
  const gridRef = React.useRef<AgGridReact<ModeloOtimizacaoReceita>>(null);
  const { usuario } = useAutenticação()
  const [rowData, setRowData] = React.useState<ModeloOtimizacaoReceita[]>([]);
  const { mostrarToast } = useToast();
  // const [key, setKey] = React.useState('home');
  const [selecionados, setSelecionados] = React.useState<string[]>([]);
  const [showValores, setShowValores] = React.useState(false);
  const { t ,i18n} = useTranslation();

  React.useEffect(() => {
    setRowData([]);

    setLoading(true);
    if (props.show && props.data) {
      showLoadingOverlay();
      // setRowData(Array.from({ length: props.data.num_quantidade }, () => new ModeloOtimizacaoReceita()));

      var filtro = { ...props.filtro, cod_produto: props.data.cod_produto, num_nivel: 3 }
      new RepositorioOtimizacaoReceita().getFilter(filtro, "_post").then((res) => {
        res.map(x => {
          x.num_fator_decisao_sazonalidade_preco = x.num_fator_decisao_sazonalidade_preco * 100
          x.num_fator_decisao_sazonalidade_demanda = x.num_fator_decisao_sazonalidade_demanda * 100
          // x.num_fator_decisao_arroba_boi = x.num_fator_decisao_arroba_boi * 100
          // x.num_fator_decisao_preco_desafio = x.num_fator_decisao_preco_desafio * 100
          // x.num_fator_decisao_preco_tipo_entrega = x.num_fator_decisao_preco_tipo_entrega * 100
          x.num_faturamento_venda_lag1 = x.num_volume_venda_lag1 * x.num_preco_venda_lag1
          // x.num_volume_ia_v1 = parseFloat(x.num_volume_ia_v1.toFixed())
          // x.num_preco_ia_v1 = parseFloat(x.num_preco_ia_v1.toFixed())
          x.num_potencial_v1 = x.num_volume_ia_v1 * x.num_preco_ia_v1
          x.num_potencial_ia = x.num_volume_ia_cd * x.num_preco_ia_cd
          x.num_potencial_ia_loja = x.num_volume_ia_loja * x.num_preco_ia_loja
        })
        // ordenar por faturamento
        res.sort((a, b) => b.num_faturamento_venda_lag1 - a.num_faturamento_venda_lag1)

        setSelecionados(res.slice(0, 10).map(x => x.cod_bandeira || ""))
        setRowData(res ? res : []);
        setLoading(false);
      }).catch((error) => {
        console.error(error)
        setRowData([]);
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      }).finally(() => {
        setLoading(false);
      });

    }
  }, [props.show, props.data]);

  React.useEffect(() => {
    console.log("Row data: ", rowData)
  }, [rowData])

  const CustomSkeleton = (props: { value: any; money?: boolean; date?: boolean; porcetagem?: boolean, attr?: string, linha?: ModeloOtimizacaoReceita }) => {
    return (
      <>

        {loading ? (
          <Skeleton className="width-100" />
        ) :
          (<>
            {localStorage.getItem("editando") && <label onClick={() => { atualizarValor(props.attr || "", props.linha || new ModeloOtimizacaoReceita()) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}
            {
              props.value ? props.date ? (
                moment(props.value).format(TipoFormatacao.FORMATO_DATA)
              ) : typeof props.value === "number" ? (
                props.porcetagem ?
                  formatarPorcentagem(props.value / 100, 2)
                  : formatLargeNumber(props.value, props.money ? t("components.R$") : "")
              ) : (
                props.value
              ) : "-"}

          </>)}

      </>
    );
  };

  async function atualizarValor(atributo: string, linha: ModeloOtimizacaoReceita) {
    console.log("atributo", atributo)
    console.log("linha", linha)

    const valor = prompt("Por favor, insira um valor " + atributo + ":");
    if (valor) {
      const _centro = new RepositorioOtimizacaoReceita();
      _centro.atualizarValor(atributo, valor, linha).then((atualizadas) => {
        if (atualizadas) {
          var encontra = rowData.find(x => x.cod_bandeira === linha.cod_bandeira && x.init_uf === linha.init_uf);
          if (encontra) (encontra as any)[atributo] = valor;
          setRowData([...rowData]);
          // rowData.find(x => x.cod_bandeira === linha.cod_bandeira)?.[atributo] = valor;
        }
        mostrarToast({
          titulo: "Sucesso",
          mensagem: `${atualizadas} linha(s) atualizada(s)`,
          tipo: "success",
        });
      }).catch(err => {
        mostrarToast({
          titulo: "Erro",
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
    }
  }

  const CustomFatores = (data: { value: any; type?: string, attr: string, linha: ModeloOtimizacaoReceita }) => {
    return (
      <>
        {loading ? (
          <Skeleton className="width-100" />
        ) :
          <>
            {localStorage.getItem("editando") && <label onClick={() => { atualizarValor(data.attr, data.linha) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}
            {
              data.value ? typeof data.value === "number" ? (
                <FatorDecisaoOtimizacao
                  showValores={showValores}
                  minValueN={(props.fatores as any)["minn_" + data.type]}
                  maxValueN={(props.fatores as any)["maxn_" + data.type]}
                  minValueP={(props.fatores as any)["minp_" + data.type]}
                  maxValueP={(props.fatores as any)["maxp_" + data.type]}
                  valor={data.value} />
              ) : (
                data.value
              ) : "-"
            }
          </>

        }


      </>
    );
  };


  function columnAux(headerName: string, field: string, money = false, porcetagem = false, minWidth = 90, line = false) {
    return {
      headerName,
      field,
      minWidth: minWidth,
      flex: 1,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      cellClass: (line ? "line2" : ""),
      headerClass: 'custom-header ' + (line ? "line" : ""),
      resizable: false,
      sortable: true,
      // suppressRemoveSort: true,
      cellStyle: { 'white-space': 'normal', padding: '2px 4px', textAlign: "end" },
      autoHeight: true,
      cellRenderer: (params: any) => <CustomSkeleton value={params.value} money={money} porcetagem={porcetagem} attr={field} linha={params.data} />,
    } as ColDef
  }
  function columnFatores(headerName: string, field: string, money = false, porcetagem = false, minWidth = 90, line = false) {
    return {
      headerName,
      field,
      minWidth: minWidth,
      flex: 1,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      cellClass: (line ? "line2" : ""),
      headerClass: 'text-center ' + (line ? "line" : ""),
      resizable: false,
      sortable: true,
      // suppressRemoveSort: true,
      cellStyle: { 'white-space': 'normal', padding: '2px 4px', textAlign: "center" },
      autoHeight: true,
      cellRenderer: (params: any) => <CustomFatores value={params.value} type={field} attr={field} linha={params.data} />,
    } as ColDef
  }
  function columnTexto(headerName: string, field: string, line = false, minWidth = 100) {
    var aux = columnAux(headerName, field, false, false, minWidth, line);
    aux.filter = "agTextColumnFilter";
    // aux.cellStyle.textAlign = "center"
    // aux.headerClass += ` ${css}`
    aux.headerClass = `agTextColumnFilter ` + (line ? "line" : "")
    aux.cellClass = line ? "line2" : ""
    aux.pinned = 'left';
    aux.cellStyle = { 'white-space': 'normal', padding: '2px 4px', textAlign: "start" },
      aux.suppressSizeToFit = true;
    aux.width = minWidth;
    return aux;
  }
  function listaColunas(data: any) {
    var colunas = [{
      texto: t("pages.Arroba"),
      valor: data.num_fator_arroba,
      atributo: "num_fator_arroba"
    },
    {
      texto: t("pages.Dólar"),
      valor: data.num_fator_dolar,
      atributo: "num_fator_dolar"
    }
      , {
      texto: t("pages.Frango"),
      valor: data.num_fator_frango,
      atributo: "num_fator_frango"
    }, {
      texto: t("pages.Milho"),
      valor: data.num_fator_milho,
      atributo: "num_fator_milho"
    }, {
      texto: t("pages.Ovo"),
      valor: data.num_fator_ovo,
      atributo: "num_fator_ovo"
    }, {
      texto: t("pages.Soja"),
      valor: data.num_fator_soja,
      atributo: "num_fator_soja"
    }, {
      texto: t("pages.Suíno"),
      valor: data.num_fator_suino,
      atributo: "num_fator_suino"
    }]
    //ordena pelo valor
    return colunas.sort((a, b) => b.valor - a.valor)
  }


  function columnPreco(headerName: string, field: string, line = false, minWidth = 100) {
    var aux = {
      headerName,
      field,
      minWidth: minWidth,
      flex: 1,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      cellClass: (line ? "line2" : ""),
      headerClass: 'custom-header ' + (line ? "line" : ""),
      resizable: false,
      sortable: true,
      // suppressRemoveSort: true,
      cellStyle: { 'white-space': 'normal', padding: '2px 4px', textAlign: "end" },
      autoHeight: true,
      // cellRenderer: (params: any) => <CustomSkeleton value={params.value} money={false} porcetagem={false} />,

      cellRenderer: (params: any) => <div>

        {localStorage.getItem("editando") && <label onClick={() => { atualizarValor(field, params.data) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}

        {params.value ?
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show width-200">
                <h6>{t("components.Fatores de Decisão")}</h6>

                <Table size="sm" variant="dark" className=" mb-0 pb-0">
                  <thead >
                    <tr className="table-header">
                      <th className="width-80">{t("components.Fator")}</th>
                      <th className="width-80 text-end">{t("components.Valor R$")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaColunas(params.data).map(x => {
                      return (
                        <tr key={'index' + x.texto}>
                          <td>{x.texto}</td>
                          <td className="text-end">
                            <div className="d-flex">
                              <FatorDecisaoOtimizacao
                                minValueN={(props.fatores as any)["minn_" + x.atributo]}
                                maxValueN={(props.fatores as any)["maxn_" + x.atributo]}
                                minValueP={(props.fatores as any)["minp_" + x.atributo]}
                                maxValueP={(props.fatores as any)["maxp_" + x.atributo]}
                                valor={x.valor} />
                              <FatorDecisaoOtimizacao
                                showValores={true}
                                minValueN={(props.fatores as any)["minn_" + x.atributo]}
                                maxValueN={(props.fatores as any)["maxn_" + x.atributo]}
                                minValueP={(props.fatores as any)["minp_" + x.atributo]}
                                maxValueP={(props.fatores as any)["maxp_" + x.atributo]}
                                valor={x.valor} />
                            </div>
                          </td>

                        </tr>
                      )
                    })}

                  </tbody>
                </Table>
                {field == 'num_preco_ia_cd' && <>{params.data.flg_cd_ajustado ? "O cliente geralmente não opta pela modalidade de entrega direta. O preço é estimado com base em análises estatísticas." : ''}   </>}
                {field == 'num_preco_ia_loja' && <> {params.data.flg_loja_ajustado ? "O cliente geralmente não opta pela modalidade de entrega CDGC. O preço é estimado com base em análises estatísticas." : ''}  </>}

                {((usuario?.cod_usuario === "erielcioimamura-mtz" || usuario?.cod_usuario === "rafaeljunior-mtz") && (params.data as any).num_fator_loja_a_loja) &&
                  <>
                    ({(params.data as any).num_fator_loja_a_loja}) <br />
                  </>
                }
                {(usuario?.cod_usuario === "erielcioimamura-mtz" || usuario?.cod_usuario === "rafaeljunior-mtz") &&
                  <>
                    Price Regressor: {(params.data as any).ia_modelo_tipo1} | {formatarPorcentagem((params.data as any).ia_modelo_tipo1 / params.data.num_qts_ia_modelo_tipo, 1)}<br />
                    Random Effect: {(params.data as any).ia_modelo_tipo2} | {formatarPorcentagem((params.data as any).ia_modelo_tipo2 / params.data.num_qts_ia_modelo_tipo, 1)}
                  </>
                }
              </Tooltip>
            }
          >
            <div className="d-flex flex-column align-items-center">
              <i className="bi bi-info-circle text-muted small"></i> {/* Ícone de informação */}
              {params.value && <BiInfoCircle className="position-absolute right-3 top-3 font-size-10" />}

              {loading ? (
                <Skeleton className="width-100" />
              ) :
                <>
                  {
                    params.value ? (
                      params.date ? (
                        moment(params.value).format(TipoFormatacao.FORMATO_DATA)
                      ) : typeof params.value === "number" ? (
                        params.porcetagem ? (
                          formatarPorcentagem(params.value / 100, 2)
                        ) : (
                          formatLargeNumber(params.value, true ? t("components.R$") : "")
                        )
                      ) : (
                        params.value
                      )
                    ) : (
                      "-"
                    )
                  }
                </>
              }
            </div>
          </OverlayTrigger> : <>-</>
        }

      </div>
    } as ColDef

    return aux;
  }

  function columnDefs() {
    var aux = [];


    var cellStyle = { 'white-space': 'normal', padding: '2px 4px' }
    var ckechbox = columnTexto(t("pages.Bandeira"), "des_bandeira", false, 250)
    // ckechbox.checkboxSelection = true
    aux.push(ckechbox)
    aux.push(columnTexto(t("pages.UF"), "init_uf", true, 70));
    aux.push(columnAux(t("pages.Preço Lista"), "num_preco_lista", true, false, 100, true));
    aux.push({
      headerName: `${t("pages.Pedidos Lançados")} (${t("components.S")}-1)`,
      cellStyle,
      headerClass: "line",
      children: [
        columnAux(t("components.Volume"), "num_volume_venda_lag1", false, false, 93),
        columnAux(t("components.Preço"), "num_preco_venda_lag1", true, false, 93),
        columnAux(t("pages.Fat."), "num_faturamento_venda_lag1", true, false, 93, true),
      ]
    })

    // aux.push(columnAux(`Volume IA V1 (${props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)`, "num_volume_ia_v1", false, false, 93, true)),
    aux.push({
      headerName: `${t("pages.IA V1")} (${props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)`,
      cellStyle,
      headerClass: "line",
      children: [
        columnAux(t("components.Volume"), "num_volume_ia_v1", false, false, 93, false),
        columnAux(t("components.Preço"), "num_preco_ia_v1", true, false, 93, false),
        columnAux(t("pages.Poten. Fat."), "num_potencial_v1", true, false, 93, true),
      ]
    })
    aux.push(columnAux(t("pages.Preço Partida V2"), "num_preco_partida", true, false, 100, true));
    aux.push({
      headerName: `${t("components.IA V2 Entrega Direta")} (${props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)`,
      cellStyle,
      headerClass: "line",
      children: [
        columnAux(t("components.Volume"), "num_volume_ia_cd", false, false, 93, false),
        columnPreco(t("components.Preço"), "num_preco_ia_cd", false, 93),
        columnAux(t("pages.Fat."), "num_potencial_ia", true, false, 93, true),
      ]
    })

    aux.push({
      headerName: `${t("components.IA V2 Entrega CDGC")} (${props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)`,
      cellStyle,
      headerClass: "line",
      children: [
        columnAux(t("components.Volume"), "num_volume_ia_loja", false, false, 93, false),
        columnPreco(t("components.Preço"), "num_preco_ia_loja", false, 93),
        columnAux(t("pages.Fat."), "num_potencial_ia_loja", true, false, 93, true),
      ]
    })

    if (props.filtro.flg_fatores) {
      aux.push({
        headerName: t("pages.Fatores"),
        cellStyle,
        headerClass: "line",
        children: [
          columnFatores(t("pages.Arroba"), "num_fator_arroba", true, false, 85, false),
          columnFatores(t("pages.Dólar"), "num_fator_dolar", true, false, 85, false),
          columnFatores(t("pages.Frango"), "num_fator_frango", true, false, 85, false),
          columnFatores(t("pages.Milho"), "num_fator_milho", true, false, 85, false),
          columnFatores(t("pages.Ovo"), "num_fator_ovo", true, false, 85, false),
          columnFatores(t("pages.Soja"), "num_fator_soja", true, false, 85, false),
          columnFatores(t("pages.Suíno"), "num_fator_suino", true, false, 85, true),
        ]
      })

    }
    var aux2 = columnPreco(t("components.Preço IA"), "num_preco_ia", false, 93)
    aux2.hide = !showValores
    aux.push(aux2)
    aux2 = columnAux(t("components.Fator LojaLoja"), "num_fator_loja_a_loja", false, false, 93)
    aux2.hide = !showValores
    aux.push(aux2)

    return aux
  }

  const gridOptions = {
    defaultColDef: {
      resizable: true, // se quiser ajustar automaticamente o tamanho
    },
    suppressMovableColumns: true
  };

  // function configuracao(linha?: any) {
  //   //transformar a props.filtro em texto  e colocar na area de transferencia 

  //   const selectedRows = gridRef.current?.api.getSelectedRows();
  //   var filtroAux = { ...props.filtro, cod_produto: props.data?.cod_produto } as any
  //   delete filtroAux.sort
  //   delete filtroAux.num_pagina_atual
  //   delete filtroAux.num_quantidade_pagina
  //   delete filtroAux.flg_aberto
  //   delete filtroAux.flg_capitaes
  //   delete filtroAux.editados
  //   delete filtroAux.des_periodo_analisado
  //   delete filtroAux.flg_mostrar_resultado
  //   delete filtroAux.cod_usuario
  //   delete filtroAux.num_nivel
  //   delete filtroAux.flg_carregar_painel
  //   delete filtroAux.flg_fatores
  //   // delete filtroAux.des_periodo_analisado
  //   // remover todos os atributos que estão com o valor null
  //   Object.keys(filtroAux).forEach(key => !filtroAux[key] && delete filtroAux[key]);

  //   if (linha) {
  //     filtroAux.cod_bandeira = linha.cod_bandeira
  //     filtroAux.ini_uf = linha.ini_uf_terc
  //   }

  //   var label = ""
  //   Object.keys(filtroAux).map(x => {
  //     label += `AND ${x == "ini_uf" ? "ini_uf_terc" : x} == '${filtroAux[x]}'\n`
  //   })

  //   const filtro = JSON.stringify(filtroAux, null, 2);
  //   navigator.clipboard.writeText(filtro + "\n" + label);
  //   mostrarToast({ titulo: ``, mensagem: "Filtro copiado para a área de transferência", tipo: "success" });
  // }

  function showLoadingOverlay() {
    if (props.show && gridRef.current && gridRef.current.api) {
      gridRef.current.componentDidUpdate = () => {
        if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.showLoadingOverlay();
        } else if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.hideOverlay();
        }
      };
    }
  }



  return (
    <Modal centered aria-labelledby="contained-modal-title-vcenter " className="custom-modal-size" size="xl" {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          {t("pages.Produto")}: {props.data?.des_produto}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-1">
          <  IndexsKPI />
        </div>


        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => { setKey(k || ""); carregarGraficos(k) }}
        >
          <Tab eventKey="home" title="Tabela" > */}
        <div className="ag-theme-quartz w-100 py-0" style={{ height: 500 }}>
          {loading ?

            <div className='d-flex height-100p justify-content-center align-items-center'>
              <Player autoplay loop src={tabela} className='height-450' />
            </div>
            :
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              gridId="ModalBandeiraGrafico"
              columnDefs={columnDefs()}
              gridOptions={gridOptions}
              embedFullWidthRows={true}
              pagination={true}
              paginationPageSize={20}
              headerHeight={20}
              rowHeight={20}
              // rowSelection="multiple"
              domLayout='normal'
              localeText={ i18n.language !="en"? AG_GRID_LOCALE_BR:undefined}
              onGridReady={(params) => {
                const selectedIds = selecionados || [];
                params.api.forEachNode((node) => {
                  if (selectedIds.includes(node.data.cod_bandeira + "")) {
                    node.setSelected(true);
                  }
                });
              }}
              // onCellClicked={(params) => {

              //   if (params.colDef.field === "des_bandeira") {
              //     configuracao(params.data)
              //   }

              // }}
              // enableCellTextSelection={true}
              // rowMultiSelectWithClick={true}
              overlayLoadingTemplate={'<span class="">' + t("components.Carregando") + ' ...</span>'}
            // defaultColDef={{
            //   sortable: true,
            // }}
            // domLayout="autoHeight" // Garante que o grid se ajustará
            />
          }
        </div>
        {/* </Tab>
          <Tab eventKey="graphic" title="Gráfico">
            <BrandeiraGrafico bandeiras={selecionados} cod_produto={props.data?.cod_produto} filtro={props.filtro} />
          </Tab>

        </Tabs> */}

        {/* <div> */}
        {/* <b> Sazonalidade do Preço</b>: {props.data?.num_fator_decisao_sazonalidade_preco ? formatarPorcentagem(props.data?.num_fator_decisao_sazonalidade_preco) : "-"} | */}
        {/* <b> Sazonalidade da Demanda</b>: {props.data?.num_fator_decisao_sazonalidade_demanda ? formatarPorcentagem(props.data?.num_fator_decisao_sazonalidade_demanda) : "-"} | */}
        {/* <b> Arroba Boi</b>: {props.data?.num_fator_decisao_arroba_boi ? formatarPorcentagem(props.data?.num_fator_decisao_arroba_boi) : "-"} |
          <b> Preço Desafio</b>: {props.data?.num_fator_decisao_preco_desafio ? formatarPorcentagem(props.data?.num_fator_decisao_preco_desafio) : "-"} |
          <b> Tipo Entrega</b>: {props.data?.num_fator_decisao_preco_tipo_entrega ? formatarPorcentagem(props.data?.num_fator_decisao_preco_tipo_entrega) : "-"} */}
        {/* </div> */}

      </Modal.Body>
      <Modal.Footer className="pt-0">
        {/* {(window.location.hostname === 'localhost') &&

          <Form.Group className="mb-3 mr-3 SelectSearch">
            <Form.Label className="font-size-14">Fatores Valores</Form.Label>
            <br />
            <SwitchItem
              checked={showValores}
              onChange={(checked) => {
                setShowValores(checked)


              }}
            ></SwitchItem>
          </Form.Group>
        } */}



        <Button variant="primary" onClick={props.exportarDados}>
          {t("pages.Exportar")}
        </Button>

        <Button variant="light" onClick={props.onHide}>
          {t("pages.Fechar")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
