import * as React from "react";
import { formatarDecimal, formatarPorcentagem } from "../../core/utils/funcoes";
import "./index.scss";
import { Badge, Spinner } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface Props {
  valor: number | undefined | null;
  className?: string;
  minValueP?: number;
  maxValueP?: number;
  minValueN?: number;
  maxValueN?: number;
  showValores?: boolean;
}

const FatorDecisaoOtimizacao: React.FC<Props> = (props) => {
  var { valor, className, minValueP, maxValueP, minValueN, maxValueN } = props;

  const { t } = useTranslation();

  function calculaIntervalo(minValue: number, maxValue: number, valor: number) {


    var min = minValue;
    var max = maxValue;
    const percentage = ((valor - min) / (max - min)) * 100;

    return percentage
  }
  const calculateClass = () => {
    if (valor === undefined || valor == null || minValueP === undefined || maxValueP === undefined || minValueN === undefined || maxValueN === undefined) {
      return "v--0";
    }
    else {
      var percentage = 0;
      var numero = Math.round(valor * 10000) / 10000;
      minValueP = Math.round(minValueP * 10000) / 10000;
      if (numero == 0 || numero == null) {
        return "v--0";
      } else {
        percentage = minValueP <= numero ? calculaIntervalo(minValueP, maxValueP, numero) : calculaIntervalo(minValueN, maxValueN, numero);
        // percentage  = minValueP <= numero? calculaIntervalo(minValueN, maxValueN, numero/100) : calculaIntervalo(minValueP, maxValueP, numero/100);
        var roundedPercentage = Math.round(percentage / 25) * 25;
        if (roundedPercentage > 100)
          roundedPercentage = 100
        if (roundedPercentage < 0)
          roundedPercentage = 0
        return minValueP > numero ? `v-${100 - roundedPercentage}` : `v${roundedPercentage}`;
      }
    }
  };

  const renderedClass = (className || "") + " " + calculateClass();

  return (
    <div className={renderedClass}>
      {/* <Badge pill bg="light" text="dark" title={formatarDecimal(valor, true, "BRL", 6)}>
        {valor === undefined ? <Spinner size="sm" /> : formatarDecimal(valor, true)}
      </Badge> */}
      <div className={"seta"} title={formatarDecimal(valor, true, "BRL", 6).replace("R$", t("components.R$"))}>
        {(valor === undefined) ? <Spinner size="sm" /> :


          props.showValores ? formatarDecimal(valor, true, "BRL", 2).replace("R$", t("components.R$")) :
            Number.isNaN(valor) ? "-" :
              ((valor as number) > 0 ? <FaArrowUp /> : <FaArrowDown />)
        }

      </div>
    </div>


  );
};

export default FatorDecisaoOtimizacao;
