import { ModeloBase } from "./modeloBase";

export const ModeloOtimizacaoReceitaGranularidade = {
  "CODIGO": [
    "cod_familia_subgrupo",
    "cod_produto",
    "cod_bandeira",
  ],
  "DESCRICAO": [
    "des_familia_subgrupo",
    "des_produto",
    "des_bandeira",
  ],
  "INTERFACE": ["Família Subgrupo", "Produto", "Bandeira", "UF"],
  "ORDEM": [
    "familia_subgrupo.num_ordem_descricao",
    "produto.num_ordem_descricao",
    "des_bandeira",
  ],
}

export class ModeloOtimizacaoReceita extends ModeloBase {


  id: string = "";
  flg_carregando_volume: boolean = false;
  flg_carregando_preco: boolean = false;
  edit: boolean = false;
  editado_usuario: "" | "preco" | "volume" = "";
  flg_aberto: boolean = false;
  oculta: boolean = false;
  flg_elasticidade?: boolean;
  num_nivel = 0;
  filhos = 0;

  cod_bandeira: string = "";

  cod_divisao_comercial: string = "";
  des_divisao_comercial: string = "";

  cod_familia: string = "";
  des_familia: string = "";
  cod_produto: string = "";
  des_produto: string = "";
  init_uf: string = "";
  des_bandeira: string = "";
  cod_gerencia: string = "";
  des_gerencia: string = "";
  cod_terceiro: string = "";
  des_terceiro: string = "";

  num_volume_venda_lag1: number = 0;
  num_preco_venda_lag1: number = 0;
  num_preco_lista: number = 0;
  num_quantidade_total: number = 0;

  num_volume_ia_v1: number = 0;
  num_preco_ia_v1: number = 0;
  num_preco_partida: number = 0;
  num_volume_ia_cd: number = 0;
  num_preco_ia: number = 0;
  num_preco_ia_cd: number = 0;
  num_volume_ia_loja: number = 0;
  num_preco_ia_loja: number = 0;


  num_fator_decisao_sazonalidade_preco: number = 0;
  num_fator_decisao_sazonalidade_demanda: number = 0;
  // num_fator_decisao_arroba_boi: number = 0;
  // num_fator_decisao_preco_desafio: number = 0;
  // num_fator_decisao_preco_tipo_entrega: number = 0;

  num_volume_venda: number = 0;
  num_preco_venda: number = 0;
  // num_volume_venda_w2: number = 0;
  // num_preco_venda_w2: number = 0;
  num_potencial_v1: number = 0;
  num_potencial_ia: number = 0;
  num_potencial_ia_loja: number = 0;
  num_quantidade: number = 0;

  editado = false;
  num_total: number = 0;


  num_faturamento_venda_lag1: number = 0;


  num_fator_arroba: number = 0;
  num_fator_dolar: number = 0;
  num_fator_frango: number = 0;
  num_fator_milho: number = 0;
  num_fator_ovo: number = 0;
  num_fator_soja: number = 0;
  num_fator_suino: number = 0;

  num_fator_arroba_loja: number = 0;
  num_fator_dolar_loja: number = 0;
  num_fator_frango_loja: number = 0;
  num_fator_milho_loja: number = 0;
  num_fator_ovo_loja: number = 0;
  num_fator_soja_loja: number = 0;
  num_fator_suino_loja: number = 0;

  num_qts_ia_modelo_tipo: number = 0;

  // plantas?: ModeloOtimizacaoReceitaPlantas[];


}

// 'num_coeficiente_dolar', 'num_coeficiente_frango', 'num_coeficiente_suino', 'num_coeficiente_arroba'




export class ModeloOtimizacaoReceitaFatoresDecisao {
  // Fazer para todos os atributos  

  minp_num_fator_arroba?: number
  maxp_num_fator_arroba?: number
  minn_num_fator_arroba?: number
  maxn_num_fator_arroba?: number

  minp_num_fator_dolar?: number
  maxp_num_fator_dolar?: number
  minn_num_fator_dolar?: number
  maxn_num_fator_dolar?: number

  minp_num_fator_frango?: number
  maxp_num_fator_frango?: number
  minn_num_fator_frango?: number
  maxn_num_fator_frango?: number

  minp_num_fator_milho?: number
  maxp_num_fator_milho?: number
  minn_num_fator_milho?: number
  maxn_num_fator_milho?: number

  minp_num_fator_ovo?: number
  maxp_num_fator_ovo?: number
  minn_num_fator_ovo?: number
  maxn_num_fator_ovo?: number

  minp_num_fator_soja?: number
  maxp_num_fator_soja?: number
  minn_num_fator_soja?: number
  maxn_num_fator_soja?: number

  minp_num_fator_suino?: number
  maxp_num_fator_suino?: number
  minn_num_fator_suino?: number
  maxn_num_fator_suino?: number

}

export class ModeloOtimizacaoReceitaGrafico {
  des_produto?: string;
  num_volume?: number;
  dth_data?: string;
  flg_capitao?: boolean;

  cod_semana?: string;
  des_bandeira?: string;
  num_preco?: number;

}


// export class ModeloOtimizacaoReceitaIndexsKpis {

//   num_diff_arroba?: number;
//   num_diff_frango?: number;
//   num_diff_suino?: number;
//   num_diff_milho?: number;
//   num_diff_soja?: number;
//   num_diff_ovo?: number;
//   num_diff_dolar?: number;



// }
