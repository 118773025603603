import React, { useState } from "react";
import { Table } from "react-bootstrap";
import TableRow from "../TableRow";
import Skeleton from "react-loading-skeleton";
import { TabelaTr } from "./tabelaTr";
import { plantaIndustrialContext } from "../../core/contexts/plantaIndustrialContexto";
import { ModeloPlantaIndustrial, ModeloPlantaIndustrialGranularidade } from "../../core/models/plantaIndustrial";
import { FiltroPlantaIndustrial } from "../../core/repositories/plantaIndustrialRepositorio";
import './index.scss';
import { converterParaFloat } from "../../core/utils/funcoes";

import { useTranslation } from "react-i18next";

interface Props {
  filtro: FiltroPlantaIndustrial;
  aberto: boolean;
  salvouEdicao(): void;
}


export default function TabelaPlantaIndustrial(props: Props) {
  const { t } = useTranslation();
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = useState(true);

  const { lista, plantas, atualizarPlantas, carregando } = plantaIndustrialContext();

  React.useEffect(() => {
    if (carregando && carregandoPrimeiraVez) {
      setCarregandoPrimeiraVez(false)
    }
  }, [carregando]);

  function title(item: any) {
    return t("pages." + ModeloPlantaIndustrialGranularidade.INTERFACE[item.num_nivel]) + ": "
      + item[ModeloPlantaIndustrialGranularidade.DESCRICAO[item.num_nivel]]?.toString()
  }

  function volumeExcessivo(linha: ModeloPlantaIndustrial) {
    if (linha.num_volume_mkt_novo === undefined || linha.num_plano_producao_proximo_periodo === undefined || linha.num_saldo_estoque_inicial === undefined || linha.num_volume_carteira === undefined) {
      return false
    } else {
      var volumeMkt = converterParaFloat(linha.num_volume_mkt_novo || linha.num_volume_ia)
      var volumeEstoque = converterParaFloat(linha.num_plano_producao_proximo_periodo) + converterParaFloat(linha.num_saldo_estoque_inicial) - converterParaFloat(linha.num_volume_carteira)
      const flg = volumeMkt > volumeEstoque
      return flg;
    }
  }


  return (
    <div className="tabela-planta-industrial">

      <div id="tabela">
        <Table className="rounded " hover bordered striped responsive size="sm"  >
          <thead >
            <tr className="table-header">
              <TableRow classNameTh="width-200 granularidade">{t("components.Granularidade")}</TableRow>
              <TableRow classNameTh="width-80">{t("components.Plano de Produção")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+0)</TableRow>
              <TableRow classNameTh="width-80">{t("components.Plano de Produção")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>

              <TableRow classNameTh="width-80">{t("components.Variação")}</TableRow>
              <TableRow classNameTh="width-80">{t("components.Saldo Estoque Inicial")}</TableRow>
              <TableRow classNameTh="width-80">{t("components.Volume Vendido")}</TableRow>
              <TableRow classNameTh="width-80">{t("components.Preço Praticado")}</TableRow>
              <TableRow classNameTh="width-80">{t("components.Volume Carteira")}</TableRow>
              <TableRow classNameTh="width-80">{t("components.Preço Carteira")}</TableRow>

              <TableRow classNameTh="width-80">{t("components.Demanda Sugestão IA")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
              <TableRow classNameTh="width-80">{t("components.Preço Sugerido IA")} ({props.filtro.flg_ano_mes ? t("components.M") : t("components.S")}+1)</TableRow>
              <TableRow colSpan={4} classNameTh="width-220 max-width-220 px-0" className="fator_decisao_farol">
                <Table className="mb-0">
                  <tbody>
                    <tr>
                      <th colSpan={4} className="text-center">
                        <label className="mr-2">{t("components.Fatores de Decisão")}</label>
                      </th>
                    </tr>
                    <tr>
                      <TableRow classNameTh="width-55">{t("components.Saz.")}</TableRow>
                      <TableRow classNameTh="width-55">
                        <label dangerouslySetInnerHTML={{ __html: t("components.Tend.") }} />
                      </TableRow>
                      <TableRow classNameTh="width-55">{t("components.Elast.")}</TableRow>
                      <TableRow classNameTh="width-55">
                        <label dangerouslySetInnerHTML={{ __html: t("components.Giro") }} />
                      </TableRow>
                    </tr>
                  </tbody>
                </Table>
              </TableRow>
              <TableRow classNameTh="width-80">{t("components.Ativar Elasticidade")}</TableRow>
              <TableRow classNameTh="width-80">{t("components.Volume Sugerido Mkt")}</TableRow>
              <TableRow classNameTh="width-80">
                <label dangerouslySetInnerHTML={{ __html: t("components.Variação IA Vs Mkt #") }} />
              </TableRow>
              <TableRow classNameTh="width-80">{t("components.Preço Sugerido Mkt")}</TableRow>
              <TableRow classNameTh="width-80">
                <label dangerouslySetInnerHTML={{ __html: t("components.Variação IA Vs Mkt $") }} />
              </TableRow>

              {
                plantas.filter(x => x.selecionada).map((item, index) => {
                  return <TableRow
                    key={index}
                    classNameTh="width-70"
                    fecharPlantas={() => {
                      item.selecionada = false;
                      atualizarPlantas([...plantas])
                    }}>{item.ini_empresa} </TableRow>
                })
              }
            </tr>
          </thead>
          {carregando ? (
            <tbody>
              {Array.from({ length: 8 }).map(
                (_, index) => (
                  <tr className="height-38" key={"skeleton-tr" + index}>
                    {Array.from({ length: 20 + plantas.filter(x => x.selecionada).length }).map((_, index) => (
                      <td key={"skeleton-td" + index}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          ) : (
            <tbody>
              {(lista.length == 0) &&
                <tr >
                  <td colSpan={23} className="text-center">
                    {carregandoPrimeiraVez ? t("components.Clique em buscar para carregar os dados!") : t("pages.Nenhum registro encontrado!")}
                  </td>
                </tr>
              }

              {lista.map((item, index) => (
                !item.oculta &&
                <tr key={"tr-" + item.id + index} className={"font-size-" + (14 - item.num_nivel * 0) + (volumeExcessivo(item) ? " bg-primary-100" : "")} title={title(item)}>
                  {/* // <tr key={"tr-" + item.id + index} className={"font-size-" + (14 - item.num_nivel * 0)} title={title(item)}> */}
                  <TabelaTr linha={item} index={index} plantas={plantas} filtro={props.filtro} salvouEdicao={props.salvouEdicao} />
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
}
