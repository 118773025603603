import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'react-bootstrap';
import { plantaIndustrialContext } from '../../core/contexts/plantaIndustrialContexto';
import { FiltroPlantaIndustrial } from '../../core/repositories/plantaIndustrialRepositorio';
import { useToast } from '../../core/contexts/toast';

import './index.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    filtro: FiltroPlantaIndustrial;
    atualizarFiltro: () => void;
}

export const BotaoPlantaIndustrial: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { mostrarToast } = useToast();

    const [valorSelecionado, setValorSelecionado] = React.useState<any[]>()
    const [filtrar, setFiltrar] = React.useState<any[]>();
    const [listaOrdenada, setListaOrdenada] = React.useState<any[]>([]);

    const { plantas, atualizarPlantas, carregarPlantas } = plantaIndustrialContext();




    React.useEffect(() => {
        if (valorSelecionado && valorSelecionado.length != plantas.filter(x => x.selecionada).map(x => x.cod_empresa).length) {
            setValorSelecionado(plantas.filter(x => x.selecionada).map(x => x.cod_empresa))
        }
        setListaOrdenada(plantas)
    }, [plantas])

    React.useEffect(() => {
        if (valorSelecionado == null) {
            mudarValor(false);
        }

    }, [valorSelecionado])


    function mudarValor(fechar: any) {
        listaOrdenada.map(x => { x.selecionada = false; return x })
        if (valorSelecionado) {
            listaOrdenada.filter(x => valorSelecionado.filter((y: any) => y == x.cod_empresa).length).map(x => { x.selecionada = true; return x })
        }
        setFiltrar(valorSelecionado)
        atualizarPlantas([...listaOrdenada])
        props.atualizarFiltro()
        carregarPlantas(props.filtro).catch(() => {
            mostrarToast({
                titulo: t("pages.Erro!"),
                mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
                tipo: "error",
            });
        })
        // setTimeout(() => {
        //     atualizaLista();
        // }, 500);
        if (fechar) {
            document.getElementById("TabelaPlantaIndustrial")?.click()
        }

    }
    function atualizaLista() {
        // Mova os itens selecionados para o início da lista
        if (valorSelecionado) {
            const selectedItemsFirst = valorSelecionado.map((selectedItem) =>
                plantas.find((item) => item.cod_empresa === selectedItem)
            );
            const unselectedItems = plantas.filter(
                (item) => !valorSelecionado.includes(item.cod_empresa)
            );
            setListaOrdenada([...selectedItemsFirst, ...unselectedItems]);
        }

    };

    function panelFooterTemplate() {
        return (
            <div className="pe-1 pb-1 text-end">
                <Button variant="primary" onClick={mudarValor} size="sm">{t("components.Aplicar")} </Button>
            </div>
        );
    }



    return (
        <MultiSelect
            id="TabelaPlantaIndustrial"
            className="width-180 heigth-36 p-0 BotaoPlantaIndustrial "
            placeholder={t("components.Editar colunas")}
            optionLabel="ini_empresa"
            optionValue="cod_empresa"
            value={valorSelecionado}
            options={listaOrdenada}
            onChange={(e => setValorSelecionado(e.value))}
            onHide={() => {
                if (filtrar != valorSelecionado) {
                    setValorSelecionado(filtrar)
                }
                atualizaLista()
            }}
            filter
            dropdownIcon={valorSelecionado?.length ? <span className="p-dropdown-icon">{valorSelecionado?.length}</span> : ""}
            maxSelectedLabels={2}
            selectedItemsLabel={valorSelecionado?.length == 1 ? t("components.Selecionado") : t("components.Selecionados")}
            emptyFilterMessage={t("components.Nenhum resultado encontrado.")}
            filterPlaceholder={t("components.Digite aqui para filtrar")}
            virtualScrollerOptions={{ itemSize: 44 }}
            showClear
            panelFooterTemplate={panelFooterTemplate}
        />
    );
};
