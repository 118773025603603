import React, { useState } from "react";
import { Table } from "react-bootstrap";
import TableRow from "../TableRow";
import Skeleton from "react-loading-skeleton";
import { TabelaTr } from "./tabelaTr";
import { sazonalidadeContexto } from "../../core/contexts/sazonalidadeContexto";
import { FiltroSazonalidade } from "../../core/repositories/sazonalidadeRepositorio";
import { ModeloSazonalidadeGranularidade } from "../../core/models/sazonalidade";
import './index.scss';
import moment from "moment";

import { useTranslation } from "react-i18next";

interface Props {
  filtro: FiltroSazonalidade;
  aberto: boolean;
}

export default function TabelaSazonalidade(props: Props) {
  const { t } = useTranslation();
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = useState(true);

  const { lista, carregando } = sazonalidadeContexto();

  React.useEffect(() => {
    if (carregando && carregandoPrimeiraVez) {
      setCarregandoPrimeiraVez(false)
    }
  }, [carregando]);

  function title(item: any) {
    return ModeloSazonalidadeGranularidade.INTERFACE[item.num_nivel] + ": "
      + item[ModeloSazonalidadeGranularidade.DESCRICAO[item.num_nivel]]?.toString()
  }

  return (
    <div className="tabela-sazonalidade">

      <div id="tabela">
        <Table className="rounded " hover bordered responsive size="sm"  >
          <thead >
            <tr className="table-header">
              <TableRow classNameTh="width-200 granularidade"> {t("components.Granularidade")} </TableRow>
              {window.location.hostname === 'localhost' &&
                <TableRow classNameTh="width-85 ">{t("components.Demanda Sugestão IA")} </TableRow>
              }
              {Array.from({ length: 13 }).map((_, index) => (
                <TableRow key={"row-tr" + index} classNameTh="width-80 text-capitalize">
                  {index > 0 && <>{moment().add(index, "month").format("MMM/yyy")}<br /></>}
                  M + {index}
                </TableRow>
              ))}
            </tr>
          </thead>
          {carregando ? (
            <tbody>
              {Array.from({ length: 25 }).map(
                (_, index) => (
                  <tr className="height-38" key={"skeleton-tr" + index}>
                    {Array.from({ length: window.location.hostname === 'localhost' ? 15 : 14 }).map((_, index) => (
                      <td key={"skeleton-td" + index}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          ) : (
            <tbody>
              {(lista.length == 0) &&
                <tr >
                  <td colSpan={14} className="text-center">
                    {carregandoPrimeiraVez ? t("components.Clique em buscar para carregar os dados!")  : t("pages.Nenhum registro encontrado!")}
                  </td>
                </tr>
              }

              {lista.map((item, index) => (
                !item.oculta &&
                <tr key={"tr-" + item.id + index} className={"font-size-" + (14 - item.num_nivel * 0)} title={title(item)}>
                  <TabelaTr linha={item} index={index} filtro={props.filtro} />
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
}
