import React from "react";
import { ModeloKpis } from "../../../core/models/kpis";
import { agruparPorAnoECalcularMedia } from "..";
import { ModeloFamiliaSubgrupo } from "../../../core/models/familiaSubgrupo";
import Plot from "react-plotly.js";
import { useTranslation } from "react-i18next";

export interface PropsGraphicKpis {
  lg: boolean;
  listaKpis: ModeloKpis[];
  familia?: ModeloFamiliaSubgrupo;
}

export default function PrecoBoiGordoDolar(props: PropsGraphicKpis) {
  const [listaBoiGordo, setListaBoiGordo] = React.useState<ModeloKpis[]>([]);
  const [listaDolar, setListaDolar] = React.useState<ModeloKpis[]>([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    var lista = props.listaKpis.filter((kpi) => kpi.des_indicador === "PRECO_BOI_GORDO" || kpi.des_indicador === "DOLAR");
    lista = lista.sort((a, b) => a.dat_referencia.localeCompare(b.dat_referencia));
    // lista.map(x => x.dat_referencia = moment(x.dat_referencia, 'YYYY-MM').format('MM-YYYY'));

    var listaBoiGordo = lista.filter((kpi) => kpi.des_indicador === "PRECO_BOI_GORDO");
    var listaDolar = lista.filter((kpi) => kpi.des_indicador === "DOLAR");

    if (props.lg) {
      setListaBoiGordo(listaBoiGordo);
      setListaDolar(listaDolar);
    } else {
      setListaBoiGordo(agruparPorAnoECalcularMedia(listaBoiGordo));
      setListaDolar(agruparPorAnoECalcularMedia(listaDolar));
    }
  }, [props.listaKpis]);

  // Datas formatadas

  const data: Partial<Plotly.Data>[] = [
    {
      x: listaBoiGordo.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaBoiGordo.map((x) => x.num_indicador),
      type: "scatter",
      mode: "lines+markers",
      name: t("pages.Preço do Boi Gordo"),
      line: {
        color: "#8B4513",
        width: 3, // Largura da linha
        shape: "spline", // Linha curva (suavizada)
      },
    },
    {
      x: listaDolar.map((x) => x.dat_referencia), // Usa as datas formatadas
      y: listaDolar.map((x) => x.num_indicador),
      type: "scatter",
      mode: "lines+markers",
      name: t("pages.Dólar"),
      line: {
        color: "#4CAF50",
        width: 3, // Largura da linha
        shape: "spline", // Linha curva (suavizada)
      },
      yaxis: "y2", // Define o uso do eixo Y secundário
    },
  ];

  const layout: Partial<Plotly.Layout> = {
    title: {
      text: t("pages.Preço do Boi Gordo e Cotação do Dólar"),
      font: {
        size: 12,
      },
    },
    paper_bgcolor: "rgba(0,0,0,0)", // Transparente
    plot_bgcolor: "rgba(0,0,0,0)", // Transparente,
    xaxis: {
      title: props.lg ? t("pages.Data") : "", // Oculta ou exibe o título do eixo X dependendo de lg
      zeroline: false, // Remove linha do zero
      tickangle: 0,
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    yaxis: {
      title: props.lg ? t("pages.Preço do Boi Gordo (R$)") : "", // Oculta ou exibe o título do eixo Y dependendo de lg
      titlefont: { color: "#8B4513" },
      tickformat: ".2f",
      tickprefix: " " + t("components.R$"),
      tickfont: { size: props.lg ? 12 : 9, color: "#8B4513" },
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    yaxis2: {
      title: {
        text: props.lg ? t("pages.Dólar") : "", // Oculta ou exibe o título do eixo Y2 (Dólar) dependendo de lg
      }, // Oculta ou exibe o título do eixo Y2 (Dólar) dependendo de lg
      titlefont: { color: "#4CAF50" },
      overlaying: "y", // Indica que este eixo se sobrepõe ao eixo Y principal
      side: "right", // Posição à direita do gráfico
      tickformat: ".2f",
      tickprefix: "$",
      tickfont: { size: props.lg ? 12 : 9, color: "#4CAF50" },
      gridcolor: "#E0E0E0", // Cor da grade
      gridwidth: 1, // Espessura da grade
    },
    margin: {
      t: props.lg ? 40 : 30,
      b: props.lg ? 40 : 20,
      l: props.lg ? 90 : 50,
      r: props.lg ? 90 : 50,
    },
    showlegend: props.lg,
    legend: {
      x: 0.5,
      xanchor: "center", // Centraliza horizontalmente
      y: -0.2,
      orientation: "h", // Legenda horizontal
      font: {
        size: 14,
      },
    },
  };

  return (
    <div className="text-center div-chart">
      <Plot data={data} layout={layout} useResizeHandler style={{ width: "100%", height: "100%" }} config={{ displayModeBar: props.lg }} />
      {/* {props.lg &&
      <label>
        Fonte: <a href="https://www.cepea.esalq.usp.br/br/indicador/boi-gordo.aspx" target="_blank" rel="noreferrer">CEPEA</a>
      </label>
    } */}
    </div>
  );
}
