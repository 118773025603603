import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico } from "../models/basico";
import { ModeloKpis } from "../models/kpis";
import { Repositorio } from "./repositorio";

export class FiltroKpis extends FiltroBasico<ModeloKpis> {
  cod_linha?: string;
  cod_acabamento?: string;
  cod_familia_subgrupo?: string;
  cod_temperatura?: string;
  flg_mi = false;
}

export class RepositorioKpis extends Repositorio<ModeloKpis> {
  constructor() {
    super(ConstanteGlobal.KPIS);
  }
}
