import { ModeloBase } from "./modeloBase";

export class ModeloKpis extends ModeloBase {
  cod_familia: number = 0;
  dat_referencia: string = "";
  des_indicador: string = "";
  ini_uf: string = "";
  num_indicador: number = 0;
  flg_volume: boolean = false;
}
