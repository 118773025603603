import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";

import { atualizaTamanhoTabela } from "../../core/utils/funcoes";
import "./index.scss";
import { BsArrowsFullscreen } from "react-icons/bs";
import PrecoBoiGordoDolar from "./modal/1_precoBoiGordoDolar";
import PrecoBoiGordoBezerro from "./modal/2_precoBoiGordoBezerro";
import PrecoCorteMarketShare from "./modal/4_precoCorteMarketShare";
import PrecoCortePrecoFrango from "./modal/5_precoCortePrecoFrango";
import PrecoCortePrecoSuino from "./modal/7_precoCortePrecoSuino";
import CorrelacaoCortePrecoSuino from "./modal/8_correlacaoCortePrecoSuino";
import CorrelacaoCortePrecoFrango from "./modal/6_correlacaoCortePrecoFrango";
import IndiceTrocaBezerroBoi from "./modal/3_indiceTrocaBezerroBoi";
import { ModeloKpis } from "../../core/models/kpis";
import { RepositorioKpis } from "../../core/repositories/KpisRepositorio";
import { useToast } from "../../core/contexts/toast";
import SelecionarBusca from "../../components/SelecionarBusca";
import { ModeloFamiliaSubgrupo } from "../../core/models/familiaSubgrupo";
import { FaEdit } from "react-icons/fa";
import LoadingAnimation from "./loadingAnimation";
import PrecoFrangoVsVendas from "./modal/10_precoFrangoVSVendas";
import PrecoSuinoVsVendas from "./modal/11_precoSuinoVSVendas";
import { useModalSelect } from "../../core/contexts/modalSelectContext";
import PrecoCortePrecoMilho from "./modal/12_precoCortePrecoMilho";
import CorrelacaoCortePrecoMilho from "./modal/13_correlacaoCortePrecoMilho";
import PrecoMilhoVsVendas from "./modal/14_precoMilhoVSVendas";
import PrecoCortePrecoOvo from "./modal/15_precoCortePrecoOvo";
import CorrelacaoCortePrecoOvo from "./modal/16_correlacaoCortePrecoOvo";
import PrecoOvoVsVendas from "./modal/17_precoOvoVSVendas";
import PrecoCortePrecoSoja from "./modal/18_precoCortePrecoSoja";
import CorrelacaoCortePrecoSoja from "./modal/19_correlacaoCortePrecoSoja";
import PrecoSojaVsVendas from "./modal/20_precoSojaVSVendas";
import PrecoCortePrecoDolar from "./modal/21_precoCortePrecoDolar";
import CorrelacaoCortePrecoDolar from "./modal/22_correlacaoCortePrecoDolar";
import PrecoDolarVsVendas from "./modal/23_precoDolarVSVendas";
import PrecoCortePrecoArroba from "./modal/24_precoCortePrecoArroba";
import CorrelacaoCortePrecoArroba from "./modal/25_correlacaoCortePrecoArroba";
import PrecoArrobaVsVendas from "./modal/26_precoArrobaVSVendas";
import { useTranslation } from "react-i18next";

export default function Kpis() {
  const bodyRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [showModalFamilia, setShowModalFamilia] = useState(false);
  const [activeChart, setActiveChart] = useState<JSX.Element | null>(null);
  const [listaKpis, setListaKpis] = useState<ModeloKpis[]>([]);
  const [carregando, setCarregando] = useState(true);
  const [listaFamiliaSubgrupo, setListaFamiliaSubgrupo] = useState<ModeloFamiliaSubgrupo[]>([]);
  const [familiaSubgrupo, setFamiliaSubgrupo] = useState<number[]>([]);
  const [listaSubgrupoSelecionado, setListaSubgrupoSelecionado] = useState<ModeloFamiliaSubgrupo[]>([]);
  const [mapa, setMapa] = useState(true);
  const [key, setKey] = React.useState("arroba");

  const { mostrarToast } = useToast();
  const { mostrarModal } = useModalSelect();

  const { t, i18n } = useTranslation();

  const cod_familia_subgrupo_init = 1;
  const des_familia_subgrupo_init = i18n.language == "en" ? "Filet Mignon" : "File Mignon";

  React.useEffect(() => {
    console.log("Kpis");
    atualizaTamanhoTabela();

    new RepositorioKpis()
      .getAll()
      .then((data) => {
        var unicosFamilia = obterKpisUnicos(data);
        unicosFamilia.sort((a, b) => a.des_indicador.localeCompare(b.des_indicador));
        console.log("unicosFamilia", unicosFamilia);

        setListaFamiliaSubgrupo(
          unicosFamilia.map((x) => {
            var aux = new ModeloFamiliaSubgrupo();
            aux.cod_familia_subgrupo = x.cod_familia;
            aux.des_familia_subgrupo = x.des_indicador;
            return aux;
          })
        );
        setListaKpis(data);
        setCarregando(false);
        lista.map((chart, index) => {
          listaSubgrupoSelecionado.push({
            cod_familia_subgrupo: cod_familia_subgrupo_init,
            des_familia_subgrupo: des_familia_subgrupo_init,
          });
        });
        console.log("Lista Sub: ", listaSubgrupoSelecionado);
        setListaSubgrupoSelecionado([...listaSubgrupoSelecionado]);
      })
      .catch((error) => {
        console.error("Erro ao carregar os Kpis:", error);
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
  }, []);

  function obterKpisUnicos(lista: ModeloKpis[]): ModeloKpis[] {
    const unicos: Record<string, boolean> = {}; // Para rastrear combinações únicas
    lista = lista.filter((x) => x.cod_familia);
    return lista.filter((item) => {
      const chave = `${item.cod_familia || ""}-${item.des_indicador}`; // Cria uma chave única
      if (unicos[chave]) {
        return false; // Já existe, exclui o item
      }
      unicos[chave] = true; // Marca como existente
      return true; // Inclui o item na lista
    });
  }

  const handleOpenModal = (element: JSX.Element) => {
    console.log("Abrir");
    setActiveChart(element);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalFamilia(false);
    setActiveChart(null);
  };

  const lista = [
    {
      element: (param: boolean) => <PrecoBoiGordoDolar lg={param} listaKpis={listaKpis} />,
      line: true,
      familia: false,
    },

    {
      element: (param: boolean) => <PrecoBoiGordoBezerro lg={param} listaKpis={listaKpis} />,

      familia: false,
    },
    {
      element: (param: boolean) => <IndiceTrocaBezerroBoi lg={param} listaKpis={listaKpis} />,

      familia: false,
    },
    {
      element: (param: boolean) => <PrecoCorteMarketShare lg={param} listaKpis={listaKpis} />,

      mapa: true,
      familia: false,
    },
    {
      element: (param: boolean) => (
        <PrecoCortePrecoFrango lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[4] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <CorrelacaoCortePrecoFrango lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[5] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoCortePrecoSuino lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[6] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <CorrelacaoCortePrecoSuino lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[7] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoFrangoVsVendas lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[8] : undefined} />
      ),
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoSuinoVsVendas lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[9] : undefined} />
      ),
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoCortePrecoMilho lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[10] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <CorrelacaoCortePrecoMilho lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[11] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoMilhoVsVendas lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[12] : undefined} />
      ),
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoCortePrecoOvo lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[13] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <CorrelacaoCortePrecoOvo lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[14] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoOvoVsVendas lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[15] : undefined} />
      ),
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoCortePrecoSoja lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[16] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <CorrelacaoCortePrecoSoja lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[17] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoSojaVsVendas lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[18] : undefined} />
      ),
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoCortePrecoDolar lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[19] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <CorrelacaoCortePrecoDolar lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[20] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoDolarVsVendas lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[21] : undefined} />
      ),
      familia: true,
    },

    {
      element: (param: boolean) => (
        <PrecoCortePrecoArroba lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[22] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <CorrelacaoCortePrecoArroba lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[23] : undefined} />
      ),
      line: true,
      familia: true,
    },
    {
      element: (param: boolean) => (
        <PrecoArrobaVsVendas lg={param} listaKpis={listaKpis} familia={listaSubgrupoSelecionado.length > 0 ? listaSubgrupoSelecionado[24] : undefined} />
      ),
      familia: true,
    },
  ];

  const cardGrafico = (index: number) => {
    return (
      <>
        <Button
          onClick={() => {
            setMapa(!!lista[index].mapa);
            handleOpenModal(lista[index].element(true));
          }}
          variant="light"
          className="position-absolute top-0 end-0 m-2 p-1 z-index-50"
        >
          <BsArrowsFullscreen size={20} />
        </Button>
        <Card.Body>
          <div className="chart-container">
            {carregando ? <LoadingAnimation brasil={lista[index].mapa} line={lista[index].line} /> : lista[index].element(false)}
            {/* Renderiza o gráfico ou elemento */}

            {/* Renderiza a descrição e o botão de edição, se existir `familia` */}
          </div>
        </Card.Body>
      </>
    );
  };

  async function handleSelectModal(indexInput: number[]) {
    const selectedData = await mostrarModal({
      titulo: t("pages.Selecione uma família"),
      multiple: false,
      itens: listaFamiliaSubgrupo.map((x) => ({
        id: x.cod_familia_subgrupo,
        description: x.des_familia_subgrupo,
        total_records: 0,
      })),
      selecionados: indexInput.length > 0 && indexInput.length > 0 ? [listaSubgrupoSelecionado[indexInput[0]].cod_familia_subgrupo + ""] : [],
    });

    if (selectedData.length) {
      var aux = listaFamiliaSubgrupo.find((x) => x.cod_familia_subgrupo == selectedData[0].id);
      if (aux && indexInput.length > 0) {
        indexInput.map((index) => {
          listaSubgrupoSelecionado[index].cod_familia_subgrupo = aux?.cod_familia_subgrupo || 0;
          listaSubgrupoSelecionado[index].des_familia_subgrupo = aux?.des_familia_subgrupo?.toString() || "";
        });
        setListaSubgrupoSelecionado([...listaSubgrupoSelecionado]);
        setListaKpis([...listaKpis]);
        setShowModalFamilia(false);
      }
    }
  }

  const buttonFamilia = (index: number[]) => {
    return (
      <div className="familia-section ">
        <span className="btn-edit">
          {listaSubgrupoSelecionado.length > 0 && (
            <Button
              variant="outline-primary"
              className="ms-2 p-1 position-relative"
              onClick={() => {
                setFamiliaSubgrupo([...index]);
                // setShowModalFamilia(true);
                handleSelectModal(index);
              }}
            >
              {listaSubgrupoSelecionado[index[0]].des_familia_subgrupo} <FaEdit className="position-absolute top-0 end-0 text-black" size={12} />
            </Button>
          )}
        </span>
      </div>
    );
  };

  return (
    <div id="tabela" className="mx-2 Kpis">
      <div className="table-responsive">
        <h1 className="text-center mb-2">KPIs</h1>
        <Row>
          <Col md={9} className="mb-2">
            <Card className="card-pai ">
              <Card.Body as={Row}>
                <Card className="grafico grafico-transparente" as={Col}>
                  {cardGrafico(0)}
                </Card>
                <Card className="grafico" as={Col}>
                  {cardGrafico(1)}
                </Card>
                <Card className="grafico grafico-transparente" as={Col}>
                  {cardGrafico(2)}
                </Card>
              </Card.Body>
            </Card>
          </Col>
          <Col key={3 + " carre"} md={3} className="mb-2">
            <Card className="grafico mapa">{cardGrafico(3)}</Card>
          </Col>

          <Col md={12} className="mb-2">
            <Tabs
              id="controlled-"
              activeKey={key}
              onSelect={(k) => {
                setKey(k || "");
              }}
            >
              <Tab eventKey="arroba" title={t("pages.Arroba")}>
                <div className="d-flex ">
                  <Card className="card-pai w-100">
                    {buttonFamilia([22, 23, 24])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col} md={4}>
                        {cardGrafico(22)}
                      </Card>
                      <Card className="grafico" as={Col} md={4}>
                        {cardGrafico(23)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col} md={4}>
                        {cardGrafico(24)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>

              <Tab eventKey="dolar" title={t("pages.Dolar")}>
                <div className="d-flex ">
                  <Card className="card-pai">
                    {buttonFamilia([19, 20, 21])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(19)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(20)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(21)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>

              <Tab eventKey="frango" title={t("pages.Frango")}>
                <div className="d-flex">
                  <Card className="card-pai">
                    {buttonFamilia([4, 5, 8])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(4)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(5)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(8)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>
              <Tab eventKey="suinos" title={t("pages.Suino")}>
                <div className="d-flex ">
                  <Card className="card-pai">
                    {buttonFamilia([6, 7, 9])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(6)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(7)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(9)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>

              <Tab eventKey="milho" title={t("pages.Milho")}>
                <div className="d-flex ">
                  <Card className="card-pai">
                    {buttonFamilia([10, 11, 12])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(10)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(11)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(12)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>

              <Tab eventKey="ovo" title={t("pages.Ovo")}>
                <div className="d-flex ">
                  <Card className="card-pai">
                    {buttonFamilia([13, 14, 15])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(13)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(14)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(15)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>

              <Tab eventKey="soja" title={t("pages.Soja")}>
                <div className="d-flex ">
                  <Card className="card-pai">
                    {buttonFamilia([16, 17, 18])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(16)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(17)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(18)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>
            </Tabs>

            {/* <Tabs id="controlled-" activeKey={key} onSelect={(k) => { setKey(k || ""); }}          >
              <Tab eventKey="frango" title="Frango" >
                <div className="d-flex">

                  <Card className="card-pai">
                    {buttonFamilia([4, 5])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(4)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(5)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(8)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>
              <Tab eventKey="suinos" title="Suino">
                <div className="d-flex">


                  <Card className="card-pai">
                    {buttonFamilia([6, 7])}
                    <Card.Body as={Row}>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(6)}
                      </Card>
                      <Card className="grafico" as={Col}>
                        {cardGrafico(7)}
                      </Card>
                      <Card className="grafico grafico-transparente" as={Col}>
                        {cardGrafico(9)}
                      </Card>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>
            </Tabs> */}
          </Col>

          <Col md={6} className="mb-2"></Col>
          {/* <Col md={4} className="mb-2 offset-md-3">
            <Card className="card-pai">
              {buttonFamilia([8, 9])}
              <Card.Body as={Row}>
                <Card className="grafico grafico-transparente" as={Col}>
                  {cardGrafico(8)}
                </Card>
                <Card className="grafico" as={Col}>
                  {cardGrafico(9)}
                </Card>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </div>
      {/* Modal para exibir gráfico ampliado */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered className={mapa ? "mapaContainer" : ""}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{activeChart}</Modal.Body>
      </Modal>

      <Modal show={showModalFamilia} onHide={handleCloseModal} size="sm" centered>
        <Modal.Header closeButton>
          {t("pages.Família Subgrupo")}: {familiaSubgrupo.length > 0 ? listaSubgrupoSelecionado[familiaSubgrupo[0]].des_familia_subgrupo : ""}
        </Modal.Header>
        <Modal.Body>
          <SelecionarBusca
            id="Família Subgrupo"
            titulo={t("pages.Família Subgrupo")}
            optionLabel="des_familia_subgrupo"
            optionValue="cod_familia_subgrupo"
            value={familiaSubgrupo.length > 0 ? listaSubgrupoSelecionado[familiaSubgrupo[0]].cod_familia_subgrupo || undefined : undefined}
            multiple={false}
            options={listaFamiliaSubgrupo}
            onChange={(value) => {
              var aux = listaFamiliaSubgrupo.find((x) => x.cod_familia_subgrupo?.toString() == value);
              if (aux && familiaSubgrupo.length > 0) {
                familiaSubgrupo.map((index) => {
                  listaSubgrupoSelecionado[index].cod_familia_subgrupo = aux?.cod_familia_subgrupo || 0;
                  listaSubgrupoSelecionado[index].des_familia_subgrupo = aux?.des_familia_subgrupo?.toString() || "";
                });
                setListaSubgrupoSelecionado([...listaSubgrupoSelecionado]);
                setListaKpis([...listaKpis]);
                setShowModalFamilia(false);
              }
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

// Função para agrupar por ano e calcular a média
export function agruparPorAnoECalcularMedia(lista: ModeloKpis[]): ModeloKpis[] {
  // Agrupa os dados por ano
  const agrupados: Record<string, { total: number; count: number }> = {};

  lista.forEach((item) => {
    const ano = item.dat_referencia.split("-")[0]; // Extrai o ano de dat_referencia

    if (!agrupados[ano]) {
      agrupados[ano] = { total: 0, count: 0 }; // Inicializa o grupo se ainda não existir
    }

    agrupados[ano].total += item.num_indicador; // Soma o valor do indicador
    agrupados[ano].count += 1; // Conta o número de itens no grupo
  });

  const resultado: ModeloKpis[] = Object.keys(agrupados).map((ano) => {
    const { total, count } = agrupados[ano];
    return {
      dat_referencia: `${ano}`, // Define a data como o início do ano
      num_indicador: total / count, // Calcula a média
      des_indicador: lista.length ? lista[0].des_indicador : "", // Descrição personalizada
    } as ModeloKpis; // Garante que é uma instância de ModeloKpis
  });

  return resultado;
}

// Função para agrupar por ano e calcular a média
export function agruparPorAnoECalculaSoma(lista: ModeloKpis[]): ModeloKpis[] {
  // Agrupa os dados por ano
  const agrupados: Record<string, { total: number; count: number }> = {};

  lista.forEach((item) => {
    const ano = item.dat_referencia.split("-")[0]; // Extrai o ano de dat_referencia

    if (!agrupados[ano]) {
      agrupados[ano] = { total: 0, count: 0 }; // Inicializa o grupo se ainda não existir
    }

    agrupados[ano].total += item.num_indicador; // Soma o valor do indicador
    agrupados[ano].count += 1; // Conta o número de itens no grupo
  });

  const resultado: ModeloKpis[] = Object.keys(agrupados).map((ano) => {
    const { total, count } = agrupados[ano];
    return {
      dat_referencia: `${ano}`, // Define a data como o início do ano
      num_indicador: total, // Calcula a média
      des_indicador: lista.length ? lista[0].des_indicador : "", // Descrição personalizada
    } as ModeloKpis; // Garante que é uma instância de ModeloKpis
  });

  return resultado;
}
