import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { useAutenticação } from "../../core/contexts/auth";
import { ModeloUsuario } from "../../core/models/usuario";
import { RepositorioUsuario } from "../../core/repositories/usuarioRepositorio";
import { useTranslation } from "react-i18next";


interface Props {
  // product_id: number;
  // distribution_center_id: number;
  user?: ModeloUsuario;
  show: boolean;
  title?: string;
  onHide: () => void;
  onCarregar: () => void;
}

export const ModalGerenciadorUsuario: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(``);
  const { usuario } = useAutenticação();


  const _user = new RepositorioUsuario();


  async function handleSubmit(event: any) {
    setValidated(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    try {

      const userModel = new ModeloUsuario();
      userModel.des_nome = form[0].value;
      userModel.des_email = form[1].value;
      if (usuario) {
        userModel.cod_usuario_criador = usuario.cod_usuario;
      }
      await _user.salvar(userModel)
      props.onHide();
      props.onCarregar();

    } catch (error: any) {
      setLoading(false);
      if (error.toJSON().status == 400) {
        setError(t("pages.E-mail já registrado!"));
      } else
        if (error && typeof error == "string") {
          setError(error);
        } else {
          setError( t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!") );
        }
    }

    setValidated(false);
    setLoading(false);

  }

  return (
    <>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center" >
            {props.user ? t("pages.Editar Usuário") : t("pages.Adicionar Usuário")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form id="myform" validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>{t("pages.Nome Completo")} </Form.Label>
              <Form.Control
                defaultValue={props.user?.des_nome}
                required
                type="text"
                placeholder="Digite seu nome"
              />
              <Form.Control.Feedback type="invalid">
                {t("pages.Obrigatório!")}
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mt-4" controlId="email">
              <Form.Label>E-mail</Form.Label>

              <Form.Control
                defaultValue={props.user?.des_email}
                required
                disabled={!!props.user}
                type="email"
                placeholder={t("Digite seu e-mail")}
              />
              <Form.Control.Feedback type="invalid">
                {t("pages.Obrigatório!")}
              </Form.Control.Feedback>
            </Form.Group>

            {error && (
              <div className="error text-danger mb-2 font-size-12">
                <label> {error}</label>
              </div>
            )}
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide()}>
            {t("pages.Fechar")}
          </Button>
          <Button disabled={loading} type="submit" form="myform"  >
            {loading ? (
              <Spinner size="sm" animation="border" role="status"></Spinner>
            ) :
              props.user ?
                "Atualizar" : "Salvar"
            }
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};
