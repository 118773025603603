import React, { useRef, useState } from "react";
import SelecionarBusca from "../../components/SelecionarBusca";
import { Row, Col, Button } from "react-bootstrap";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import { ModeloBasico } from "../../core/models/basico";
import { ModeloEmpresa } from "../../core/models/empresa";
import { ModeloDiretoria } from "../../core/models/diretoria";
import { ModeloLinha } from "../../core/models/linha";
import { ModeloProduto } from "../../core/models/produto";
import { ModeloCenario } from "../../core/models/cenario";
import { ModeloCentroDistribuicaoListaTabela } from "../../core/models/centroDistribuicao";
import { RepositorioEmpresa } from "../../core/repositories/empresaRepositorio";
import { RepositorioDiretoria } from "../../core/repositories/diretoriaRepositorio";
import { FiltroLinha, RepositorioLinha } from "../../core/repositories/linhaRepositorio";
import { FiltroProduto, RepositorioProduto } from "../../core/repositories/produtoRepositorio";
import { FiltroCentroDistribuicao } from "../../core/repositories/centroDistrubuicaoRepositorio";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { verificarSemanaAtual } from "../../core/utils/funcoes";

import { useToast } from "../../core/contexts/toast";
import { centroDistribuicaoContext } from "../../core/contexts/centroDistribuicaoContexto";
import { useTranslation } from "react-i18next";
import { TipoTela } from "../../core/config/tipoTela";

interface Props {
  titulo: string;
  filtro: FiltroCentroDistribuicao;
  cenario: ModeloCenario;
  listaTabela: ModeloCentroDistribuicaoListaTabela;
  listaPeriodo: ModeloBasico[];
  atualizarFiltro: (filtro: FiltroCentroDistribuicao) => void;
  filtrarDados: (filtro: FiltroCentroDistribuicao) => void;
  setListaTabela: (listaTabela: ModeloCentroDistribuicaoListaTabela) => void;
  setListaPeriodo: (lista: ModeloBasico[]) => void;
  mostrarCenarioNome: (mostrar: boolean) => void;
  carregarPeriodo: () => void;
}

export default function FiltroCD(props: Props) {

  const { t } = useTranslation();
  
  const { mostrarToast } = useToast();

  const { lista } = centroDistribuicaoContext();
  const { listaAcabamento, listaBandeira, listaCluster, listaDivisaoComercial, listaFamiliaSubgrupo, listaMercado, listaTemperatura, listaTipoProduto, listaUFDestino, listaPeriodoMes, listaPeriodoSemana } = filtroContexto()

  const [listaEmpresa, setListaEmpresa] = useState<ModeloEmpresa[]>([]);
  const [listaDiretoria, setListaDiretoria] = useState<ModeloDiretoria[]>([]);
  const [listaLinha, setListaLinha] = useState<ModeloLinha[]>([]);
  const [listaProduto, setListaProduto] = useState<ModeloProduto[]>([]);

  const [habilitarCheckboxPeriodo, setHabilitarCheckboxPeriodo] = useState(false)
  const [checkboxPeriodo, setCheckboxPeriodo] = useState(false)



  React.useEffect(() => {
    if (props.filtro.des_periodo_analisado || props.filtro.flg_semanal_mensal === undefined) {
      const flg_atual = verificarSemanaAtual(props.filtro.des_periodo_analisado)

      // Forçar não checado
      if (habilitarCheckboxPeriodo == false && flg_atual == true) {
        props.filtro.flg_semanal_mensal = "" // N Aplicar
        setCheckboxPeriodo(false)
      }
      // É semana atual
      else if (flg_atual)
        if (checkboxPeriodo)
          props.filtro.flg_semanal_mensal = "0" // False
        else
          props.filtro.flg_semanal_mensal = "" // N Aplicar
      // Não é Semana Atual (para outras semanas e periodos mensais)
      else
        props.filtro.flg_semanal_mensal = "" // N Aplicar

      props.atualizarFiltro(props.filtro)
      setHabilitarCheckboxPeriodo(flg_atual)
    }

  }, [props.filtro.des_periodo_analisado])



  React.useEffect(() => {
    try {
      new RepositorioEmpresa().getAll().then((res) => {
        setListaEmpresa(res);
      })
      new RepositorioDiretoria().getAll().then((res) => {
        setListaDiretoria(res);
      });

      carregarLinha();
      carregarProduto();
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }, []);

  function carregarProduto() {
    props.filtro.cod_produto = ""
    const filtroProduto = new FiltroProduto();
    filtroProduto.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo_produto_capitao;
    filtroProduto.cod_linha = props.filtro.cod_linha_produto_capitao;
    new RepositorioProduto().getFilter(filtroProduto).then((res) => {
      setListaProduto(res);
    });
  }

  function carregarLinha() {
    props.filtro.cod_linha_produto_capitao = ""

    const filtroLinha = new FiltroLinha();
    filtroLinha.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo_produto_capitao;

    new RepositorioLinha().getFilter(filtroLinha).then((res) => {
      setListaLinha(res);
    });
  }

  function atualizarAnoMes(valor: any) {
    props.filtro.flg_ano_mes = valor;
    props.atualizarFiltro(props.filtro);
    props.carregarPeriodo();
  }



  return (
    <div className="stick">
      <FiltroCabecalho
        titulo={props.titulo}
        filtro={props.filtro}
        atualizarFiltro={props.atualizarFiltro}
        atualizarAnoMes={atualizarAnoMes}
        cenario={props.cenario}
        lista={lista}
        mostrarCenarioNome={props.mostrarCenarioNome}
        tipoTela={TipoTela.CENTRO_DISTRIBUICAO}
      >
        <>
          <Row className="row-cols-2 row-cols-lg-7">
            <Col>
              {habilitarCheckboxPeriodo ?
                <SelecionarBusca
                  titulo={t("pages.Período Analisado")}
                  optionValue="codigo"
                  optionLabel="descricao"
                  multiple={false}
                  value={props.filtro.des_periodo_analisado}
                  options={props.listaPeriodo}
                  onChange={(value) => {
                    props.filtro.des_periodo_analisado = value;
                    props.atualizarFiltro(props.filtro)
                  }}
                  checkbox={checkboxPeriodo}
                  alterarCheckbox={(valor: boolean) => {
                    if (valor)
                      props.filtro.flg_semanal_mensal = "0"
                    else
                      props.filtro.flg_semanal_mensal = ""
                    props.atualizarFiltro(props.filtro)
                    setCheckboxPeriodo(valor)
                  }}
                /> :
                <SelecionarBusca
                  id ="Período Analisado"
                  titulo={t("pages.Período Analisado")}

                  optionValue="codigo"
                  optionLabel="descricao"
                  multiple={false}
                  value={props.filtro.des_periodo_analisado}
                  options={props.listaPeriodo}
                  onChange={(value) => {
                    props.filtro.des_periodo_analisado = value;
                    props.atualizarFiltro(props.filtro)
                  }}
                />
              }
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Empresa")} 
                optionLabel="ini_empresa"
                optionValue="cod_empresa"
                multiple
                value={props.filtro.cod_empresa}
                options={listaEmpresa}
                onChange={(value) => {
                  props.filtro.cod_empresa = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Diretoria")} 
                optionLabel="des_diretoria"
                optionValue="cod_diretoria"
                multiple
                value={props.filtro.cod_diretoria}
                options={listaDiretoria}
                onChange={(value) => {
                  props.filtro.cod_diretoria = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Divisão Comercial")}
                optionLabel="des_divisao_comercial"
                optionValue="cod_divisao_comercial"
                multiple
                value={props.filtro.cod_divisao_comercial}
                options={listaDivisaoComercial}
                onChange={(value) => {
                  props.filtro.cod_divisao_comercial = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Mercado")}
                optionLabel="des_mercado"
                optionValue="cod_mercado"
                multiple
                value={props.filtro.cod_mercado}
                options={listaMercado}
                onChange={(value) => {
                  props.filtro.cod_mercado = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Tipo de Produto")}
                optionLabel="des_tipo_produto"
                optionValue="cod_tipo_produto"
                multiple
                value={props.filtro.cod_tipo_produto}
                options={listaTipoProduto}
                onChange={(value) => {
                  props.filtro.cod_tipo_produto = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Família Subgrupo")}
                optionLabel="des_familia_subgrupo"
                optionValue="cod_familia_subgrupo"
                multiple
                value={props.filtro.cod_familia_subgrupo_produto_capitao}
                options={listaFamiliaSubgrupo}
                onChange={(value) => {
                  props.filtro.cod_familia_subgrupo_produto_capitao = value
                  carregarProduto();
                  carregarLinha();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Linha")}
                optionLabel="des_linha"
                optionValue="cod_linha"
                multiple
                value={props.filtro.cod_linha_produto_capitao}
                options={listaLinha}
                onChange={(value) => {
                  props.filtro.cod_linha_produto_capitao = value
                  carregarProduto();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Produto")}
                optionLabel="des_produto"
                optionValue="cod_produto"
                panelClassName="min-width-600"
                multiple
                logicaFiltroCodigo
                value={props.filtro.cod_produto}
                options={listaProduto}
                onChange={(value) => {
                  props.filtro.cod_produto = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
{/* 
            <Col>
              <SelecionarBusca
                titulo={t("pages.Acabamento")}
                optionLabel="des_acabamento"
                optionValue="cod_acabamento"
                multiple
                value={props.filtro.cod_acabamento}
                options={listaAcabamento}
                onChange={(value) => {
                  props.filtro.cod_acabamento = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Temperatura")}
                optionLabel="des_temperatura"
                optionValue="cod_temperatura"
                multiple
                options={listaTemperatura}
                value={props.filtro.cod_temperatura}
                onChange={(value) => {
                  props.filtro.cod_temperatura = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col> */}

            <Col>
              <SelecionarBusca
                titulo={t("pages.Cluster")}
                optionLabel="des_cluster_label"
                optionValue="cod_cluster"
                multiple
                value={props.filtro.cod_cluster}
                options={listaCluster}
                onChange={(value) => {
                  props.filtro.cod_cluster = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Bandeira")}
                optionLabel="des_bandeira"
                optionValue="cod_bandeira"
                multiple
                value={props.filtro.cod_bandeira}
                options={listaBandeira}
                onChange={(value) => {
                  props.filtro.cod_bandeira = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.UF Destino")}
                optionLabel="ini_uf_destino"
                optionValue="ini_uf_destino"
                multiple
                value={props.filtro.ini_uf_destino}
                options={listaUFDestino}
                onChange={(value) => {
                  props.filtro.ini_uf_destino = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
          </Row>
        </>
      </FiltroCabecalho>
    </div>
  );
}


