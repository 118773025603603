import * as React from "react";
import moment from "moment";
import { Button, Table } from "react-bootstrap";
import TableRow from "../../components/TableRow";
import { useState } from "react";
import { FaEdit, FaUndoAlt, FaTrash } from "react-icons/fa";
import { ModalGerenciadorUsuario } from "./modal";
import { ModeloUsuario } from "../../core/models/usuario";
import { FiltroUsuario, RepositorioUsuario } from "../../core/repositories/usuarioRepositorio";
import { TipoFormatacao } from "../../core/config/tipoFormatacao";
import PaginacaoTabela from "../../components/PaginacaoTabela";
import Skeleton from "react-loading-skeleton";

import { useModal } from "../../core/contexts/modalContexto";
import { useToast } from "../../core/contexts/toast";
import { useTranslation } from "react-i18next";

interface Props {
    filtro: FiltroUsuario;
    lista: ModeloUsuario[];
    carregando: boolean;
    atualizarOrdenacao: (filtro: FiltroUsuario) => void;
    atualizarLista: (data: ModeloUsuario[]) => void;
}

const TabelaGerenciadorUsuario: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { mostrarModal } = useModal();
    const { mostrarToast } = useToast();

    const [selectUser, setSelecetUser] = useState<ModeloUsuario>();
    const [modalShowEdit, setModalShowEdit] = useState(false);

    const _user = new RepositorioUsuario();

    function mudarOrdenacao(value: string, prop: string) {
        const aux: any = {};
        aux[prop] = value;
        props.filtro.sort = aux;
        props.atualizarOrdenacao(props.filtro);
    }
    async function mudarStatus(selectUser: ModeloUsuario) {
        const modalResultadoConfirmacao = await mostrarModal({
            titulo: selectUser?.flg_ativo ? t("pages.Remover") : t("pages.Restaurar"),
            subTitulo: t("pages.Você tem certeza que quer ") + (selectUser?.flg_ativo ? t("pages.remover") : t("pages.restaurar")) + selectUser?.des_nome + t("pages.do sistema?"),
            acao: t("pages.Continuar")
        })

        if (selectUser && modalResultadoConfirmacao) {
            _user.mudarStatus(selectUser.des_email, !selectUser.flg_ativo).then((data) => {

                selectUser.flg_ativo = !selectUser.flg_ativo;
                props.atualizarLista(props.lista.filter(x => x.des_email != selectUser.des_email));
                mostrarToast({
                    titulo: t("pages.Sucesso!"),
                    mensagem: t("pages.Status alterado com sucesso!"),
                    tipo: "success",
                });
            }).catch(() => {
                mostrarToast({
                    titulo: t("pages.Erro!"),
                    mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
                    tipo: "error",
                });
            })
        }
    }

    return (
        <>
            <Table bordered hover responsive borderless size="sm">
                <thead>
                    <tr>
                        <TableRow
                            ordem={props.filtro.sort.des_nome}
                            ordenarLinha={(ev: any) => mudarOrdenacao(ev, "des_nome")}
                        >
                            {t("pages.Nome")}
                        </TableRow>

                        <TableRow
                            ordem={props.filtro.sort.des_email}
                            ordenarLinha={(ev: any) => mudarOrdenacao(ev, "email")}
                        >
                            {t("pages.E-mail")}
                        </TableRow>

                        <TableRow
                            ordem={props.filtro.sort.des_nome_criador}
                            ordenarLinha={(ev: any) => mudarOrdenacao(ev, "des_nome_criador")}
                        >
                            {t("pages.Criador Nome")}
                        </TableRow>

                        <TableRow>{t("pages.Status")}</TableRow>

                        <TableRow
                            ordem={props.filtro.sort.dth_insercao}
                            ordenarLinha={(ev: any) => mudarOrdenacao(ev, "dth_insercao")}
                        >
                            {t("pages.Data de Criação")}
                        </TableRow>
                        <TableRow>
                            {t("pages.Ação")}
                        </TableRow>
                    </tr>
                </thead>
                <tbody>
                    {props.carregando ? (
                        Array.from({ length: 10 }).map(
                            (_, index) => (
                                <tr className="height-38" key={"skeleton-tr" + index}>
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td key={"skeleton-td" + index}>
                                            <Skeleton />
                                        </td>
                                    ))}
                                </tr>
                            )
                        )
                    ) :
                        (props.lista.length === 0) ?
                            <tr className="table-row-header text-center">
                                <td colSpan={6}>{t("pages.Nenhum cenário aplicado")}</td>
                            </tr>
                            : props.lista.map((_, index) => (
                                <tr className="text-center" key={index}>
                                    {/* Full Name */}
                                    <td className="text-capitalize">{_.des_nome}</td>
                                    {/* Email */}
                                    <td>{_.des_email}</td>
                                    {/*  Name Creator */}
                                    <td className="text-capitalize">{_.des_nome_criador ? _.des_nome_criador.split(' ')[0] : "-"}</td>
                                    {/* Active */}
                                    <td>{_.flg_ativo ? t("pages.Ativo") : t("pages.Inativo")}</td>
                                    {/* Creation Date */}
                                    <td>
                                        {moment(_.dth_insercao).format(TipoFormatacao.FORMATO_DATA)}
                                    </td>
                                    {/* Action */}
                                    <td>
                                        {_.flg_ativo &&
                                            <Button className="border-0" variant="outline-primary" size="sm" onClick={() => {
                                                setSelecetUser(_);
                                                setModalShowEdit(true);
                                            }}>
                                                <FaEdit />
                                            </Button>
                                        }
                                        <Button className="border-0" variant={_.flg_ativo ? "outline-danger" : "outline-success"} size="sm" onClick={() => {
                                            setSelecetUser(_)
                                            mudarStatus(_);

                                        }}>
                                            {_.flg_ativo ? < FaTrash /> : <FaUndoAlt />}
                                        </Button>
                                    </td>
                                </tr>
                            ))
                    }
                </tbody>
            </Table>

            <div>

                <PaginacaoTabela
                    atualizarPagina={(value) => {
                        props.filtro.num_pagina_atual = value;
                        props.atualizarOrdenacao(props.filtro);
                    }}
                    paginaAtual={props.filtro.num_pagina_atual}
                    tamanhoPagina={props.filtro.num_quantidade_pagina}
                    contador={props.filtro.num_total}
                />

            </div>

            <ModalGerenciadorUsuario
                show={modalShowEdit}
                user={selectUser}
                onCarregar={() => {
                    props.atualizarOrdenacao(props.filtro);
                }}
                onHide={() => {
                    setModalShowEdit(false)
                }} />
        </>
    );
};

export default TabelaGerenciadorUsuario;
