import loadingAnimation from "../../assets/animation/charts.json";
import brasil from "../../assets/animation/brasil.json";
import line from "../../assets/animation/line.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import React from "react";

interface Props {
  brasil?: boolean;
  line?: boolean;
}
export default function LoadingAnimation(props: Props) {
  return (
    <div className="d-block h-100 w-100">
      <div className="d-flex height-100p justify-content-center align-items-center w-100">
        <Player autoplay loop src={props.brasil ? brasil : props.line ? line : loadingAnimation} className="height-150" />;
      </div>
    </div>
  );
}
