import * as React from "react";
import { Button, Collapse, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BiUpArrowAlt } from "react-icons/bi";
import { ModeloCenario } from "../../core/models/cenario";
import { formatarPeriodoData, gerarTituloCenario } from "../../core/utils/funcoes";
import "./index.scss";
import { ModeloBase } from "../../core/models/modeloBase";
import { useTranslation } from "react-i18next";
import { TipoTela } from "../../core/config/tipoTela";

interface Props {
  titulo: JSX.Element | string;
  cenario?: ModeloCenario;
  permissaoEditar?: boolean;
  ocultarTitulo?: boolean;
  ocultarResultado?: boolean;
  filtro: any;
  lista?: ModeloBase[];
  atualizarFiltro: (filtro: any) => void;
  atualizarAnoMes?: (bool: boolean) => void;
  mostrarCenarioNome?: (mostrar: boolean) => void;
  // setFiltroAberto: (valor: boolean) => void;
  tipoTela:TipoTela;
  children?: JSX.Element;
}

export const FiltroCabecalho: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [anoMesRadio, setValorRadio] = React.useState("0");
  const [tituloCenario, setTituloCenario] = React.useState("Novo")

  React.useEffect(() => {
    props.atualizarAnoMes && props.atualizarAnoMes(anoMesRadio == "1")
  }, [anoMesRadio])

  React.useEffect(() => {
    if ((anoMesRadio == "0" && props.filtro.flg_ano_mes) || (anoMesRadio == "1" && !props.filtro.flg_ano_mes)) {
      setValorRadio(props.filtro.flg_ano_mes ? "1" : "0")
    }
  }, [props.filtro.flg_ano_mes])

  React.useEffect(() => {
    const tituloString = typeof props.titulo === 'string' ? props.titulo : props.titulo.toString();
    
    setTituloCenario(gerarTituloCenario(props.filtro, tituloString))
  }, [props.filtro.des_periodo_analisado])

  function atualizaAberto() {
    props.filtro.flg_aberto = !props.filtro.flg_aberto;
    props.atualizarFiltro(props.filtro)

  }

  return (
    <div className="filtro-cabecalho">
      <div className="h-100  d-flex justify-content-between align-items-center mb-2">
        <h2 className="mb-0" onClick={atualizaAberto}>
          {props.titulo}

          <Button className={"text-primary p-0 m-0 " + (props.filtro.flg_aberto ? " btn-abrir-rotacao" : " btn-fechar-rotate")} variant="btn-link" size="sm">
            <BiUpArrowAlt className="font-size-26" />
          </Button>
        </h2>
        {(props.cenario && !props.ocultarTitulo) &&

          <div className="d-flex justify-content-between align-items-center">
            {props.permissaoEditar ? (
              <h2 className="mb-0"> {props.cenario?.des_cenario || tituloCenario} {props.cenario?.flg_alterado && "*"} </h2>
            ) : (
              <h2 className="mb-0"> {props.cenario?.des_cenario || tituloCenario} </h2>
            )}
            {props.permissaoEditar &&
              <Button
                className={"text-primary  p-0 m-0 ms-1 border-0 "}
                variant="btn-link" size="sm"
                disabled={!props.cenario?.flg_editar}
                onClick={() => props.mostrarCenarioNome && props.mostrarCenarioNome(true)} >
                <FaRegEdit className="font-size-26" />
              </Button>
            }
          </div>
        }

        <div className="d-flex justify-content-between align-items-center">
          <div>
            {
              (props.cenario && !props.ocultarResultado) &&
              <Button aria-controls="example-collapse-text" aria-expanded={props.filtro.flg_mostrar_resultado} variant={(props.filtro.flg_mostrar_resultado ? "outline-primary" : "primary")}
                className="mx-1"
                disabled={!props.lista || props.lista.length == 0}
                onClick={() => {
                  props.filtro.flg_mostrar_resultado = !props.filtro.flg_mostrar_resultado;
                  props?.atualizarFiltro(props.filtro)
                }}>
                {t("components.Resultado")}
              </Button>
            }

          </div>
          {
            props.cenario &&
            <ToggleButtonGroup className="width-250" type="radio" name="periodo-analisado" value={anoMesRadio} >
              <ToggleButton key="radio-btn-0" id="radio-btn-0" className="w-50" type="radio" variant="outline-primary" name="radio" value="0" checked={anoMesRadio == "0"}
                onChange={(e: any) => {
                  setValorRadio(e.currentTarget.value)
                  props.filtro.des_periodo_analisado = null;
                }}
                disabled={props.cenario?.cod_cenario != -1 || props.filtro.des_periodo_analisado == null}
              >
                {anoMesRadio === "0" && props.filtro.des_periodo_analisado != "" ? (
                  props.filtro.des_periodo_analisado === undefined ?
                  <Spinner size="sm" /> : formatarPeriodoData(props.filtro.des_periodo_analisado, false)) : t("components.Semana/Ano")
                }
              </ToggleButton>
              <ToggleButton key="radio-btn-1" id="radio-btn-1" className="w-50 text-capitalize" type="radio" variant="outline-primary" name="radio" value="1" checked={anoMesRadio == "1"}
                onChange={(e: any) => {
                  setValorRadio(e.currentTarget.value)
                  props.filtro.des_periodo_analisado = null;
                }}
                disabled={props.cenario?.cod_cenario != -1 || props.filtro.des_periodo_analisado == null}
              >
                {anoMesRadio === "1" && props.filtro.des_periodo_analisado != "" ? (
                  props.filtro.des_periodo_analisado === undefined ?
                  <Spinner size="sm" /> : formatarPeriodoData(props.filtro.des_periodo_analisado, true)) : t("components.Mês/Ano")
                }
              </ToggleButton>
            </ToggleButtonGroup>
          }

        </div>
      </div>

      <Collapse className="overflow-hidden" in={props.filtro.flg_aberto}>
        <div>{props.children}</div>
      </Collapse>
    </div>
  );
};
