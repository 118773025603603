import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico } from "../models/basico";
import { ModeloCenario } from "../models/cenario";
import { ModeloOtimizacaoReceita, ModeloOtimizacaoReceitaFatoresDecisao, ModeloOtimizacaoReceitaGrafico } from "../models/otimizacaoReceita";

import { Repositorio } from "./repositorio";

export class FiltroOtimizacaoReceita extends FiltroBasico<ModeloOtimizacaoReceita> {
  flg_aberto = true;
  flg_capitaes = false
  editados = false;
  flg_ano_mes = false;
  flg_mostrar_resultado = false;
  num_nivel = 0;
  flg_carregar_painel = false;
  flg_fatores = true;
  cod_acabamento?: string;
  cod_canal?: string;
  cod_cluster?: string;
  cod_diretoria?: string;
  cod_familia_subgrupo?: string;
  cod_linha?: string;
  des_periodo_analisado?: string;
  des_periodo_analisado_atual?: string;
  cod_produto?: string;
  cod_gerencia?: string;
  cod_produto_capitao?: string;
  cod_temperatura?: string;
  cod_tipo_produto?: string;
  // cod_mercado?: string;
  cod_divisao_comercial?: string;
  ini_uf_destino?: string;
  cod_bandeira?: string;
  cod_usuario?: string;
  // flg_ano_mes: boolean = false;
  num_quantidade_pagina = 15;
  flg_granular = false;


}

export class RepositorioOtimizacaoReceita extends Repositorio<ModeloOtimizacaoReceita> {
  constructor() {
    super(ConstanteGlobal.OTIMIZACAO_RECEITA);
  }

  atualizarValor(des_atributo: string, des_valor: string, modelo: ModeloOtimizacaoReceita) {
    return this.api.post(this.nomeRepositorio + "_atualizar_valor", { des_atributo, des_valor, ...modelo }).then((res) => {
      return res.data;
    });
  }
  getIndexsKPI(): Promise<any> {
    return this.api.get(this.nomeRepositorio + "_indexs_kpi").then((res) => {
      return res.data;
    });
  }
  getModeloFatores(mensal: boolean, cod_mes: string): Promise<ModeloOtimizacaoReceitaFatoresDecisao> {
    return this.api.get(this.nomeRepositorio + "_modelo_fatores?mensal=" + mensal + "&cod_mes=" + cod_mes).then((res) => {
      return res.data[0];
    });
  }
  getGrafico(filtro: FiltroOtimizacaoReceita): Promise<ModeloOtimizacaoReceitaGrafico[]> {
    return this.api.post(this.nomeRepositorio + "_grafico_post", filtro).then((res) => {
      return res.data;
    });
  }
  download() {
    const filtro = {}
    return this.api.post(this.nomeRepositorio + "_download", filtro, {
      responseType: "arraybuffer",
    });
  }

  call() {
    return this.api.post(this.nomeRepositorio + "/call").then((res) => {
      return res.data;
    });
  }

  getModeloUltimoHorario(mensal: boolean) {
    return this.api.get(this.nomeRepositorio + "_modelo_ultimo_horario?mensal=" + mensal).then((res) => {
      return res.data;
    });
  }


  salvarOtimizacaoReceitaEdicao(cenario: ModeloCenario): Promise<number> {
    return this.api.post(this.nomeRepositorio + '_salvar_todos_cenario_post', cenario).then((res) => {
      return res.data
    })
  }

  baixarTodos(filtro: any) {
    return this.api.post(this.nomeRepositorio + "_baixar_tudo", filtro, {
      responseType: "arraybuffer",
    });
  }
  baixarCsv(filtro: any) {
    return this.api.post(this.nomeRepositorio + "_baixar", { ...filtro, flg_csv: true }, {
      responseType: "arraybuffer",
    });
  }
  baixarCsv2(filtro: any) {
    return this.api.post(this.nomeRepositorio + "_baixar2", { ...filtro, flg_csv: true }, {
      responseType: "arraybuffer",
    });
  }

}
